import { useContext } from 'react';

import { UserListContext } from '@/Pages/Administrator/Context/user-list-context';

export const useUserContext = () => {
  const context = useContext(UserListContext);
  if (!context)
    throw new Error('useUserContext must be used within a UserListProvider');
  return context;
};
