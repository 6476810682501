import AdministratorWrapper from '@/Pages/Administrator/Wrapper';

const AdministratorRoutes = [
  {
    path: '/administrator',
    element: <AdministratorWrapper />,
  },
];

export default AdministratorRoutes;
