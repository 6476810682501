import { useMutation } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';

interface SubmitUserProps {
  redHubId: number;
  email: string;
  name: string;
  whatsAppNumber: string;
  avatar: string;
  reason: string;
}

const submitUser = async ({
  redHubId,
  email,
  name,
  whatsAppNumber,
  avatar,
  reason,
}: SubmitUserProps) => {
  const url = `/administrators/user-lists/submit`;
  try {
    await goFetcher.post(url, {
      redhub_id: redHubId,
      email,
      name,
      whatsapp_number: whatsAppNumber,
      avatar,
      reason,
    });
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'An error occurred');
  }
};

const useSubmitUser = () => {
  return useMutation<any, Error, SubmitUserProps>({
    mutationFn: submitUser,
  });
};

export { useSubmitUser };
