import Table from '@/Components/Table';
import { useRequestUserContext } from '@/Hooks/Administrator';

import RejectConfirmationModal from './RejectConfirmationModal';
import TableRowRequestList from './TableRow';

const COLUMNS = [
  { title: 'Requested Email' },
  { title: 'Requested Name' },
  { title: 'Request Date' },
  { title: 'Project Name' },
  { title: 'Reason' },
  { title: '' },
];

const TableRequestList = () => {
  const {
    records,
    hasNextPage,
    isFetchingNextPage,
    updateStatus,
    isLoading,
    setCheckAll,
    setTargetRef,
    handleUpdateStatus,
  } = useRequestUserContext();

  const isReject = updateStatus.status === 'rejected';

  const selectedUser =
    updateStatus.id.length === 1 ? updateStatus.id[0] : undefined;

  if (isLoading) return null;

  return (
    <div className="border max-h-[calc(100vh-300px)] w-full overflow-auto scrollbar-hide md:max-w-sm">
      {records.length > 0 && !isLoading ? (
        <>
          <Table columns={COLUMNS} hasCheckbox onCheckboxChange={setCheckAll}>
            {records.map((record) => (
              <TableRowRequestList key={record.id} row={record} />
            ))}
          </Table>
          {hasNextPage && (
            <div
              ref={(el) => {
                if (el) {
                  setTargetRef(el);
                }
              }}
              className="p-24 text-center"
            >
              {isFetchingNextPage ? (
                <p className="font-medium">Loading more data...</p>
              ) : null}
            </div>
          )}
        </>
      ) : (
        <div className="flex flex-col items-center justify-center">
          <img
            alt="Empty-amico 1.png"
            className="size-400"
            src="assets/img/Empty-amico 1.png"
          />
          <div className="flex flex-col items-center justify-items-center gap-20">
            <h2 className="text-32 font-semibold leading-[36px] text-black-redx md:text-24">
              Data not found
            </h2>
            <p className="text-center text-16 font-normal leading-20 text-grey-redx">
              There’s currently no information available here.
            </p>
          </div>
        </div>
      )}
      <RejectConfirmationModal
        isOpen={isReject}
        onClose={() => handleUpdateStatus([], '')}
        selectedUser={records.find((record) => record.id === selectedUser)}
      />
    </div>
  );
};

export default TableRequestList;
