import type { TargetAudienceItem } from '@/Types/TargetAudience';

export const jsonToMarkdown = (data: TargetAudienceItem[]) => {
  let markdown = '';
  data.forEach((item) => {
    if (item.title && item.content) {
      markdown += `**${item.title}**: ${item.content}\n`;
    } else {
      markdown += `${item.content}\n`;
    }
  });

  return markdown;
};

export const markdownToJson = (markdown: string) => {
  const lines = markdown.split('\n');
  const parsedContent: TargetAudienceItem[] = [];

  lines.forEach((line) => {
    // Pastikan baris memiliki karakter ":"
    if (line.includes(':')) {
      const regex = /(\*\*.+?\*\*):/; // Regex untuk menangkap title
      const match = regex.exec(line);

      if (match) {
        let title = match[1].trim(); // Ambil title
        const content = line.replace(regex, '').trim(); // Sisanya jadi content

        // Bersihkan title dari kelebihan asterisks
        title = title.replace(/\*+/g, '').trim(); // Hilangkan semua '*'
        title = `**${title}**`; // Tambahkan hanya 2 asterisks di awal dan akhir

        if (title && content) {
          parsedContent.push({
            title,
            content,
          });
        }
      }
    } else if (line.trim().length > 0) {
      // Jika baris tidak memiliki ":" tetapi ada teks
      parsedContent.push({
        title: '',
        content: line.trim(),
      });
    }
  });

  return parsedContent;
};

export const sanitizeContent = (content: string): string => {
  if (!content || typeof content !== 'string') {
    return ''; // Kembalikan string kosong jika input tidak valid
  }

  return content
    .replace(/[#*~[\]()\-+=`><]/g, '') // Hapus simbol Markdown
    .replace(/[^a-zA-Z\s.]/g, '') // Hapus karakter non-alfabet kecuali spasi dan titik
    .replace(/\s+/g, ' ') // Ganti banyak spasi dengan satu spasi
    .toLowerCase() // Konversi ke huruf kecil
    .trim(); // Hilangkan spasi tambahan di awal/akhir
};

export const createDifferenceChecker = <T>(
  keysToCompare: (keyof T)[],
  sanitizeContent: (value: string) => string,
) => {
  return (current: T, previous: T): boolean => {
    for (const key of keysToCompare) {
      const currentItems = current[key] as unknown as {
        title: string;
        content: string;
      }[];
      const previousItems = previous[key] as unknown as {
        title: string;
        content: string;
      }[];

      // Pastikan currentItems dan previousItems adalah array sebelum mengecek length
      if (!Array.isArray(currentItems) || !Array.isArray(previousItems)) {
        return true;
      }

      if (currentItems.length !== previousItems.length) {
        return true;
      }

      for (let i = 0; i < currentItems.length; i++) {
        const currentTarget = currentItems[i];
        const previousTarget = previousItems[i];

        if (
          sanitizeContent(currentTarget.title) !==
            sanitizeContent(previousTarget.title) ||
          sanitizeContent(currentTarget.content) !==
            sanitizeContent(previousTarget.content)
        ) {
          return true; // ada perbedaan
        }
      }
    }

    return false; // Tidak ada perbedaan
  };
};
