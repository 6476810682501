import PrimaryButton from '@/Components/PrimaryButton';

interface ErrorGenerateProps {
  onGenerate?: () => void;
}
const ErrorGenerate = ({ onGenerate }: ErrorGenerateProps) => {
  return (
    <div className="flex min-h-[50vh] flex-row items-center justify-center gap-55 md:flex-col ">
      <div className="my-6">
        <img
          alt="Confused Character"
          className="w-48 mx-auto h-auto max-w-250"
          src="/assets/img/ainstein-notfound.png"
        />
      </div>
      <div className="max-w-xl">
        <div className="mb-24 flex flex-col gap-16">
          <h1 className="text-24 font-semibold">
            Oops! Something went wrong...
          </h1>
          <p className=" text-gray-600">
            We couldn’t generate a response this time. Please try again by
            clicking &apos;Regenerate&apos;.
          </p>
        </div>

        <PrimaryButton
          className="!w-fit border-1 border-blue-redx px-30 py-10 text-18 font-semibold !text-blue-redx md:w-full"
          onClick={onGenerate}
        >
          Regenerate
        </PrimaryButton>
      </div>
    </div>
  );
};

export default ErrorGenerate;
