import { useQueryClient } from '@tanstack/react-query';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import ErrorGenerate from '@/Components/ErrorGenerate';
import GenerateLoading from '@/Components/GenerateLoading';
import ChangingImpactModal from '@/Components/Modals/ChangingImpactModal';
import RegenerateInfoModal from '@/Components/Modals/RegenerateInfoModal';
import ViewInfoModal from '@/Components/Modals/ViewInfoModal';
import NotDataFound from '@/Components/NotDataFound';
import ActionSection from '@/Components/Projects/ActionSection';
import RegenerateButton from '@/Components/Projects/RegenerateButton';
import SliderContainer from '@/Components/Projects/SliderContainer';
import SourcesList from '@/Components/Projects/SourcesList';
import {
  saveInformation,
  saveRouteNext,
  saveSubInformation,
  setEnableHistoryStatus,
} from '@/Context/Actions/project-actions';
import { ProjectContext } from '@/Context/ProjectContext';
import type { RouteOption } from '@/Context/Reducer/project-reducer';
import {
  checkAndUpdateIsEdited,
  useArchetypes,
  useCtaAction,
  useDifferentArchetypes,
  useSectionData,
  useUpdateArchetype,
} from '@/Hooks/AudienceArchetype';
import { useGenerate } from '@/Hooks/useGenerate';
import { useHistoryStatus } from '@/Hooks/useHistoryStatus';
import { useProgress } from '@/Hooks/useProgress';
import useRolesBadge from '@/Hooks/useRolesBadge';
import { useRoutePrev } from '@/Hooks/useSaveRoutePrev';
import type { ArchetypeData, SectionData } from '@/Types/AudienceArchetype';
import type { Schema } from '@/Types/AudienceArchetype/schema';
import type { SectionList } from '@/Types/tabs';
import { progressPortion } from '@/Utils/dispatcher';
import { triggerGTMEvent } from '@/Utils/gtm';

import type { CardHandles } from './Components/Card';
import Card from './Components/Card';
import ConfirmationModal from './Components/ConfirmationModal';
import Skeleton from './Components/Skeleton';

const Index = () => {
  const [state, dispatch] = useContext(ProjectContext);

  const user = useMemo(() => state.user, [state.user]);
  const project = useMemo(() => state.project, [state.project]);
  const isEnabledHistoryStatus = useMemo(
    () => state.isEnabledHistoryStatus,
    [state.isEnabledHistoryStatus],
  );

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const sectionList: SectionList = {
    title: 'Audience Archetype',
    value: 'archetype',
    section: 'audience',
  };
  const [generatingStates, setGeneratingStates] = useState<
    Record<number, boolean>
  >({});
  const [showViewInfoModal, setShowViewInfoModal] = useState(false);
  const [showRegenerateModal, setShowRegenerateModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { mutate, isPending: isSubmitting } = useUpdateArchetype();
  const cardRefs = useRef<(CardHandles | null)[]>([]);

  const [goToIndex, setGoToIndex] = useState<number | undefined>(undefined);

  const [activeIndex, setActiveIndex] = useState<number>(0);

  const { data, refetch, isPending, isRefetching } = useArchetypes(
    project!.slug,
  );
  const [routeOptionValue, setRouteOptionValue] = useState<RouteOption>({
    id: 'btn-generate-key-insight',
    label: 'Generate Key Insight',
    isActive: true,
    isGenerate: true,
    isInactive: true,
    isDisabled: false,
  });

  const { roles } = useRolesBadge(project, user);
  const isEditor = roles.includes('Owner') || roles.includes('Strategist');

  const { data: differentArchetypes, refetch: refetchDiffArchetypes } =
    useDifferentArchetypes(project.slug);

  const [sectionData, setSectionData] = useState<Record<string, SectionData>>(
    {},
  );
  const [showAlertGenerateModal, setShowAlertGenerateModal] = useState(false);
  const [progressStates, setProgressStates] = useState<Record<number, number>>(
    {},
  );

  const [archetypes, setArchetypes] = useState<
    Record<
      number,
      {
        id: number;
        is_querying: boolean;
      }
    >
  >({});

  const [formState, setFormState] = useState({
    isEditing: false,
    isAdding: false,
  });

  const { mutateAsync: generate, isPending: isGenerating } = useGenerate();

  const {
    isKeyInsightsComplete,
    isTargetAudienceAudienceInsight,
    isTargetAudienceBehavioralTrend,
    isTargetAudienceMediaConsumptionPattern,
    isTargetAudiencePerceptionAnalysis,
    isAudienceArchetypeComplete,
    isOpportunityAmongCompetitionComplete,
    isOnePageStrategy,
    isIdeaAlignment,
    refetch: refetchHistory,
  } = useHistoryStatus(project, isEnabledHistoryStatus);

  const [totals, setTotals] = useState<{
    allSelected: number;
    allData: number;
  }>({
    allSelected: 0,
    allData: 0,
  });

  useEffect(() => {
    dispatch(saveInformation(''));
    dispatch(saveSubInformation(''));
  }, []);

  useEffect(() => {
    dispatch(
      saveInformation(
        `${totals.allSelected} from ${totals.allData} archetype selected`,
      ),
    );
  }, [totals]);

  useSectionData({
    activeSection: sectionList.value,
    data,
    progressStates,
    projectSlug: project.slug,
    sectionData,
    setGeneratingStates,
    setProgressStates,
    setSectionData,
    setTotals,
  });

  const handleRegenerateButton = async (prompt: string) => {
    triggerGTMEvent({
      event: `Generate More Audience Archetype`,
      eventCategory: `Generate More Audience Archetype Click`,
      eventAction: 'Click',
      eventLabel: 'Audience Archetype',
      userId: user.email,
      data: prompt,
    });

    dispatch(saveInformation(''));

    await generate({
      payload: {
        generate_more: prompt,
        regenerate_prompt: '',
      },
      projectSlug: project.slug,
      section: 'audience-archetype',
    });

    refetch();
    dispatch(setEnableHistoryStatus(true));
  };

  const handleRegenerateError = async () => {
    triggerGTMEvent({
      event: `Generate error Audience Archetype`,
      eventCategory: `Generate error Audience Archetype Click`,
      eventAction: 'Click',
      eventLabel: 'Audience Archetype',
      userId: user.email,
      data: prompt,
    });

    dispatch(saveInformation(''));

    await generate({
      payload: {
        generate_more: '',
        regenerate_prompt: '',
      },
      projectSlug: project.slug,
      section: 'audience-archetype',
    });

    refetch();
    dispatch(setEnableHistoryStatus(true));
  };

  const toggleEditing = () => {
    setFormState((prev) => ({ ...prev, isEditing: !prev.isEditing }));
  };

  const toggleAdding = () => {
    setFormState((prev) => ({ ...prev, isAdding: !prev.isAdding }));
  };

  const handleCancelEdit = () => {
    toggleEditing();

    setTimeout(() => {
      setGoToIndex(-1);
    }, 1000);
  };

  const handleSubmit = () => {
    cardRefs.current.forEach((ref, index) => {
      if (ref && index === activeIndex) {
        ref.submitForm();
      }
    });
  };

  const totalRegenerate = sectionData[sectionList.value]?.total_regenerate || 0;

  const message = sectionData[sectionList.value]?.message ?? '';
  const content = sectionData[sectionList.value]?.content ?? [];
  const sources = sectionData[sectionList.value]?.sources ?? [];
  const historyId = sectionData[sectionList.value]?.history_id ?? -1;
  const isQuerying = sectionData[sectionList.value]?.is_querying ?? false;
  const isError = sectionData[sectionList.value]?.is_error ?? false;
  const isIdeaAlignmentAndOnePageNeedRegenerate =
    sectionData[sectionList.value]
      ?.is_idea_alignment_and_one_page_need_regenerate ?? false;
  const isReadyToRegenerate =
    sectionData[sectionList.value]?.is_ready_to_regenerate ?? false;
  const impactedListKeyInsight = isKeyInsightsComplete?.status ?? false;
  const impactedListOnePage = isOnePageStrategy?.status ?? false;
  const impactedListIdeaAlignment = isIdeaAlignment?.status ?? false;
  const impactedOpportunity =
    isOpportunityAmongCompetitionComplete?.status ?? false;
  const { isSendingEmail: isEmailNotification } = isAudienceArchetypeComplete;

  useEffect(() => {
    if (historyId) {
      setArchetypes((prev) => ({
        ...prev,
        [historyId]: {
          id: historyId,
          is_querying: isQuerying ?? false,
        },
      }));
    }
  }, [historyId, isQuerying]);

  const activeArchetype = useMemo(() => {
    if (!historyId) return null;
    return archetypes[historyId] || null;
  }, [archetypes, historyId]);

  const handleAddNew = () => {
    const newData: ArchetypeData = {
      id: -1,
      is_edited: false,
      submission_id: -1,
      history_id: -1,
      archetype_number: 1,
      is_selected: false,
      is_manual: false,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      archetype_content: {
        archetype_number: 1,
        lifestyle: '',
        archetype_name: '',
        behavior: '',
        current_trends: '',
        demographics: '',
        needs_and_challenges: '',
        occupation: '',
        online_search_behavior: '',
        potential_buying_motives: '',
        preferred_sales_channels: '',
        purchase_frequency: '',
        purchasing_decision_influences: '',
        source_of_information: '',
      },
      origin_archetype_content: {
        archetype_number: 1,
        lifestyle: '',
        archetype_name: '',
        behavior: '',
        current_trends: '',
        demographics: '',
        needs_and_challenges: '',
        occupation: '',
        online_search_behavior: '',
        potential_buying_motives: '',
        preferred_sales_channels: '',
        purchase_frequency: '',
        purchasing_decision_influences: '',
        source_of_information: '',
      },
    };

    const newIndex = content.length;

    // Update state `archetypes` dengan data baru
    setSectionData((prev) => {
      const updatedContent = [
        ...(prev[sectionList.value]?.content || []),
        newData,
      ];

      return {
        ...prev,
        [sectionList.value]: {
          ...prev[sectionList.value],
          content: updatedContent,
        },
      };
    });

    setGoToIndex(newIndex);
    toggleAdding(); // Aktifkan mode "Adding"
  };

  const handleCancelAdd = () => {
    toggleAdding();

    setSectionData((prev) => {
      const curr = prev[sectionList.value]?.content || [];
      const filter = curr.filter((datum) => datum.id !== -1);

      return {
        ...prev,
        [sectionList.value]: {
          ...prev[sectionList.value],
          content: filter,
        },
      };
    });

    setTimeout(() => {
      setGoToIndex(-1);
    }, 1000);
  };

  const handleFetchSubmit = useCallback(
    async (payload: Schema, id: number, index: number) => {
      const projectSlug = project.slug;

      let isEdited = false;
      if (id !== -1) {
        isEdited = checkAndUpdateIsEdited(
          payload,
          content.find((item) => item.id === id)?.origin_archetype_content,
        );
      }

      mutate(
        { payload, id, projectSlug, isEdited },
        {
          onSuccess: ({ data }) => {
            triggerGTMEvent({
              event: `${id === -1 ? 'Add' : 'Update'} Audience Archetype`,
              eventCategory: `Button ${id === -1 ? 'Add' : 'Update'} Audience Archetype  Click`,
              eventAction: 'Click',
              eventLabel: 'Audience Archetype',
              userId: user.email,
              data: payload,
            });

            formState.isAdding && toggleAdding();
            formState.isEditing && toggleEditing();

            if (data.is_edited && isKeyInsightsComplete.status) {
              setShowAlertGenerateModal(true);
            }

            refetch();

            formState.isAdding &&
              setTimeout(() => {
                setGoToIndex(index);
              }, 1000);
          },
        },
      );
    },
    [project.slug, user.email, formState, isKeyInsightsComplete, content],
  );

  const handleCloseModalChangesImpact = () => {
    setShowRegenerateModal(false);
    dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: false }));
  };

  const handleRegenerate = useCallback(async () => {
    if (
      differentArchetypes?.is_different_archetype.key_insight ||
      isKeyInsightsComplete.isRegenerated
    ) {
      await generate({
        payload: {
          regenerate_prompt: '',
          generate_more: '',
        },
        projectSlug: project.slug,
        section: 'key-insight',
      });
    }

    if (isReadyToRegenerate) {
      const selectedAndEditedArchetype = content
        .filter((item: ArchetypeData) => item.is_selected && item.is_edited)
        .map((item: ArchetypeData) => item.id);

      if (impactedOpportunity) {
        triggerGTMEvent({
          event: `Generate Opportunity Among Competition`,
          eventCategory: `Generate Opportunity Among Competition Click`,
          eventAction: 'Click',
          eventLabel: 'Opportunity Among Competition',
          userId: user.email,
          data: project.slug,
        });

        await generate({
          payload: {
            regenerate_prompt: '',
            generate_more: '',
            status: 'edit per archetype from archetype',
            archetype_id: selectedAndEditedArchetype,
          },
          projectSlug: project.slug,
          section: 'opportunity-among-competition',
        });
      }

      await generate({
        payload: {
          regenerate_prompt: '',
          generate_more: '',
          status: 'edit per archetype from archetype',
          archetype_id: selectedAndEditedArchetype,
        },
        projectSlug: project.slug,
        section: 'key-insight',
      });
    }

    if (isIdeaAlignmentAndOnePageNeedRegenerate) {
      generate({
        payload: {
          regenerate_prompt: '',
          generate_more: '',
        },
        projectSlug: project.slug,
        section: 'one-page-strategy',
      });

      await generate({
        payload: {
          regenerate_prompt: '',
          generate_more: '',
          status:
            'regenerate from audience archetype due of unselected archetype',
        },
        projectSlug: project.slug,
        section: 'idea-alignment',
      });
    }

    handleCloseModalChangesImpact();
  }, [
    content,
    isIdeaAlignmentAndOnePageNeedRegenerate,
    impactedListIdeaAlignment,
    impactedListOnePage,
    isReadyToRegenerate,
    impactedOpportunity,
    isKeyInsightsComplete.isRegenerated,
    differentArchetypes?.is_different_archetype.key_insight,
  ]);

  const selectedIndexes = useMemo(() => {
    return content
      .map((item, index) => (item.is_selected ? index : null))
      .filter((index) => index !== null);
  }, [content]);

  const handleRefetchSelectedData = useCallback(() => {
    queryClient.resetQueries({
      queryKey: ['keyInsights', project.slug],
      exact: true,
    });

    dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: true }));

    if (
      isReadyToRegenerate ||
      (isKeyInsightsComplete.status &&
        !isKeyInsightsComplete.isRegenerated &&
        !differentArchetypes?.is_different_archetype.key_insight) ||
      (impactedListOnePage &&
        isIdeaAlignmentAndOnePageNeedRegenerate &&
        !differentArchetypes?.is_different_archetype.key_insight) ||
      (impactedListIdeaAlignment &&
        isIdeaAlignmentAndOnePageNeedRegenerate &&
        !differentArchetypes?.is_different_archetype.key_insight)
    ) {
      setShowRegenerateModal(true);
      return;
    }

    const shouldRegenerateKeyInsight =
      !isKeyInsightsComplete.status || isKeyInsightsComplete.isRegenerated;

    if (shouldRegenerateKeyInsight) {
      generate(
        {
          payload: {
            regenerate_prompt: '',
            generate_more: '',
          },
          projectSlug: project.slug,
          section: 'key-insight',
        },
        {
          onSuccess: () => {
            dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: false }));
            navigate(`/${project.slug}/key-insight`);
          },
        },
      );
      return;
    }

    if (differentArchetypes?.is_different_archetype.key_insight) {
      generate(
        {
          payload: {
            regenerate_prompt: '',
            generate_more: '',
            status: 'regenerate diff arch key insight',
          },
          projectSlug: project.slug,
          section: 'key-insight',
        },
        {
          onSuccess: () => {
            dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: false }));
            navigate(`/${project.slug}/key-insight`);
          },
        },
      );
      return;
    }

    dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: false }));
    navigate(`/${project.slug}/key-insight`);
  }, [
    project.slug,
    isKeyInsightsComplete.isRegenerated,
    isKeyInsightsComplete.status,
    routeOptionValue,
    isReadyToRegenerate,
    isIdeaAlignmentAndOnePageNeedRegenerate,
    differentArchetypes?.is_different_archetype.key_insight,
    impactedListOnePage,
    impactedListIdeaAlignment,
  ]);

  useProgress({
    activeArchetype,
    generatingStates,
    progressStates,
    refetch,
    activeTab: 0,
    projectSlug: project.slug,
    setArchetypes,
    setGeneratingStates,
    setProgressStates,
    section: sectionList.value,
    isQuerying: isAudienceArchetypeComplete.isQuerying,
  });

  const onSelectSuccess = (isEdited: boolean, isNeedRegenerate: boolean) => {
    if (
      (isNeedRegenerate && impactedListOnePage) ||
      (isNeedRegenerate && impactedListIdeaAlignment) ||
      isEdited
    ) {
      setShowAlertGenerateModal(true);
    }
    refetch();
    refetchHistory();
    refetchDiffArchetypes();
  };

  useEffect(() => {
    dispatch(
      setEnableHistoryStatus(!(formState.isAdding || formState.isAdding)),
    );
  }, [formState]);

  useCtaAction({
    message,
    differentArchetypes,
    isKeyInsightsComplete,
    isTargetAudienceAudienceInsight,
    isTargetAudienceBehavioralTrend,
    isTargetAudienceMediaConsumptionPattern,
    isTargetAudiencePerceptionAnalysis,
    isAudienceArchetypeComplete,
    isReadyToRegenerate,
    activeSection: sectionList.value,
    isEditing: formState.isAdding || formState.isEditing,
    isEditor,
    project,
    routeOptionValue,
    totalSelected: totals.allSelected,
    setShowViewInfoModal,
    handleRefetchSelectedData,
    setShowConfirmationModal,
    setRouteOption: setRouteOptionValue,
    isIdeaAlignmentAndOnePageNeedRegenerate:
      (impactedListOnePage && isIdeaAlignmentAndOnePageNeedRegenerate) ||
      (impactedListIdeaAlignment && isIdeaAlignmentAndOnePageNeedRegenerate),
  });

  useEffect(() => {
    const fetchData = async () => {
      await refetch();
    };
    fetchData();
  }, [
    isKeyInsightsComplete.isQuerying,
    isKeyInsightsComplete.isRegenerated,
    isAudienceArchetypeComplete.isQuerying,
  ]);

  useRoutePrev({
    id: 'btn-back-audience-archtype',
    label: 'Back',
    isActive: message !== 'No data found',
    onClick: () => navigate(`/${project.slug}/target-audience`),
  });

  return (
    <>
      <div className="size-full">
        <div className="sticky top-56 z-20 mb-24 flex flex-row items-center justify-between gap-16 bg-white py-16 md:flex-col">
          <div className="flex grow flex-col">
            <h1 className="mb-8 text-25 font-bold leading-none">
              Audience Archetype
            </h1>
            <span className="text-1620 font-normal text-grey-redx">
              Please{' '}
              <span className="font-bold text-black-redx">
                select one or more{' '}
              </span>{' '}
              audience archetypes, or click &apos;New Archetype&apos; to add a
              new one
            </span>
          </div>
          {!isError &&
            isEditor &&
            !isQuerying &&
            message !== 'No data found' && (
              <div className="flex items-center">
                <RegenerateButton
                  isGenerateMore
                  isGenerating={isGenerating}
                  limit={totalRegenerate}
                  maxLimit={project.max_generated_data} // to do ganti ke max limit
                  onSubmit={({ prompt }) => handleRegenerateButton(prompt)}
                  section="audience-archetype"
                />
              </div>
            )}
        </div>

        {isPending ? (
          <Skeleton />
        ) : (
          <div className="h-full py-24">
            {(() => {
              if (isQuerying) {
                return (
                  <GenerateLoading
                    estimateTimeTo={3}
                    progress={progressPortion({
                      progress: Number(
                        (progressStates[historyId] || 0).toFixed(0),
                      ),
                      isQuerying,
                      isContentAvailable: content.length > 0,
                    })}
                    project={project}
                    section={sectionList}
                    showEmailNotification={isEmailNotification}
                  />
                );
              }

              if (isError && !isQuerying) {
                return <ErrorGenerate onGenerate={handleRegenerateError} />;
              }

              if (message === 'No data found' && !isQuerying) {
                return (
                  <NotDataFound
                    buttonLabel="Go to Target audience"
                    description="Click the button below to generate a audience archetype in Target Audience page."
                    onClick={() => navigate(`/${project.slug}/target-audience`)}
                    title="No Data Found."
                  />
                );
              }

              return (
                <div className="flex flex-col gap-20 pb-40">
                  <SliderContainer
                    goToIndex={goToIndex}
                    isAdding={formState.isAdding}
                    isEditing={formState.isEditing}
                    length={content.length}
                    onActiveIndex={(index) => setActiveIndex(index)}
                    selectedIndexes={selectedIndexes}
                  >
                    {content.map((archetype, index) => (
                      <Card
                        key={archetype.id}
                        ref={(el) => (cardRefs.current[index] = el)}
                        id={archetype.id}
                        index={index}
                        isCanSelect={isEditor}
                        isDisabledSelectButton={
                          impactedListKeyInsight &&
                          totals.allSelected === 1 &&
                          !isKeyInsightsComplete.isRegenerated &&
                          !differentArchetypes?.is_different_archetype
                            .key_insight
                        }
                        isEdited={archetype.is_edited || archetype.is_manual}
                        isEditing={formState.isAdding || formState.isEditing}
                        isFetching={isRefetching}
                        isSelected={archetype.is_selected}
                        item={archetype.archetype_content}
                        onSelectSuccess={onSelectSuccess}
                        onSubmitSuccess={handleFetchSubmit}
                        project={project}
                        refetchDiffArchetype={refetchDiffArchetypes}
                        totalData={content.length}
                        totalSelected={totals.allSelected}
                        user={user}
                      />
                    ))}
                  </SliderContainer>

                  <ActionSection
                    isEditing={formState.isAdding || formState.isEditing}
                    isEditor={isEditor}
                    isSubmitting={isSubmitting}
                    label="Add Archetype Manually"
                    onAdd={handleAddNew}
                    onCancel={
                      formState.isAdding ? handleCancelAdd : handleCancelEdit
                    }
                    onSave={handleSubmit}
                    toggleEditing={toggleEditing}
                  />

                  {sources && <SourcesList sources={sources} />}
                </div>
              );
            })()}
          </div>
        )}
      </div>
      <ConfirmationModal
        handleGenerate={handleRefetchSelectedData}
        isOpen={showConfirmationModal}
        onClose={() => {
          setShowConfirmationModal(false);
        }}
      />
      <RegenerateInfoModal
        isOpen={showAlertGenerateModal}
        onClose={() => {
          setShowAlertGenerateModal(false);
        }}
        section="audience-archetype"
      />
      <ChangingImpactModal
        handleSubmit={handleRegenerate}
        isOpen={showRegenerateModal}
        isRegenerating={isGenerating}
        onClose={handleCloseModalChangesImpact}
        section="audience-archetype"
        visibleSections={[
          'Key Insight',
          'Challenge & Communication Task',
          'Opportunity Among Competition',
          'One-Page Strategy',
          'Key Touch Point',
          'Idea Alignment',
          'Idea Personalization',
          'Idea Expansion',
          'Searchability Content',
          'Discoverability Content',
          'Credibility Content',
          'Selected Content Ideas',
        ]}
      />
      <ViewInfoModal
        isOpen={showViewInfoModal}
        onClose={() => setShowViewInfoModal(false)}
      />
    </>
  );
};

export default Index;
