import PrimaryButton from '@/Components/PrimaryButton';

const UnsavedChangesPopup = ({
  onClose,
  onBack,
}: {
  onClose: () => void;
  onBack: () => void;
}) => {
  return (
    <div className="absolute right-0 top-0 z-60 w-1/2 rounded-10 border-1 border-gray-100 bg-white p-10 shadow-md transition-transform duration-500 md:-right-12 md:w-full">
      <div className="flex flex-col gap-3 p-5">
        <h1 className="text-sm font-semibold">Unsaved changes detected</h1>
        <p className="text-xs text-gray-500">
          Are you sure want to close this pop up? Any changes you&apos;ve made
          won&apos;t be saved if you do.
        </p>
        <div className="flex justify-end gap-16 py-4 md:gap-8">
          <PrimaryButton
            className="shrink-0 border-1 border-blue-redx bg-white px-31 py-10 !text-16 !leading-18 !text-blue-redx hover:bg-white active:bg-white"
            onClick={onClose}
          >
            Close Anyway
          </PrimaryButton>
          <PrimaryButton
            className="shrink-0 bg-blue-redx px-31 py-10 !text-16 !leading-18 hover:bg-blue-redx active:bg-blue-redx"
            onClick={onBack}
          >
            Back
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default UnsavedChangesPopup;
