import { AdminUserListProvider } from '../../Context/admin-user-list-provider';
import FilterAndSort from './FilterAndSort';
import TableRequestList from './Table';

const TabUserList = () => {
  return (
    <AdminUserListProvider>
      <div className="relative flex flex-col gap-24">
        <FilterAndSort />
        <TableRequestList />
      </div>
    </AdminUserListProvider>
  );
};
export default TabUserList;
