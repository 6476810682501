import { Button } from '@headlessui/react';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import Checkbox from '@/Components/Checkbox';
import ModalCenter from '@/Components/Modals/Center';
import TextInput from '@/Components/TextInput';
import {
  useAdminSubmitUser,
  useAdminUserContext,
  useRedHubUserOption,
} from '@/Hooks/Administrator';
import type { AdministratorUserResponse } from '@/Types/Administrator';
import { cn } from '@/Utils/cn';

import SuccessUpdateAccessToast from './SuccessUpdateAccessToast';

interface AddNewModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const AddNewModal: React.FC<AddNewModalProps> = ({ isOpen, onClose }) => {
  const { handleRefetch: refetch } = useAdminUserContext();
  const { mutate, isPending } = useAdminSubmitUser();
  const { data, refetch: refetchAdminOption } = useRedHubUserOption({
    isAdmin: true,
  });
  const [searchQuery, setSearchQuery] = useState('');
  const handleClose = () => {
    onClose();
  };
  const [selectedUserAdmins, setSelectedUserAdmins] = useState<
    AdministratorUserResponse[]
  >([]);
  const [filteredUsers, setFilteredUsers] = useState<
    AdministratorUserResponse[]
  >([]);

  const handleSubmit = () => {
    const payload = selectedUserAdmins.map((user) => ({
      redhub_id: user.id,
      email: user.email,
      name: user.name,
      whatsapp_number: user.whatsapp_number,
      avatar: user.avatar,
    }));

    mutate(payload, {
      onSuccess: () => {
        if (payload.length > 1) {
          toast.custom((t) => (
            <SuccessUpdateAccessToast
              message={`<b>${payload.length} users</b> is now an Admin. They have full access to admin features.`}
              t={t}
            />
          ));
        } else {
          const selectedUser = payload[0];
          toast.custom((t) => (
            <SuccessUpdateAccessToast
              message={`<b>${selectedUser?.email}</b> is now an Admin. They have full access to admin features.`}
              t={t}
            />
          ));
        }

        refetch(true);
        handleClose();
      },
    });
  };

  const handleOnChangeUser = (
    id: AdministratorUserResponse['id'],
    status: boolean,
  ) => {
    const selectedRedHubUser = data?.data.find((item) => item.id === id);
    setSelectedUserAdmins((prev) => {
      if (status) {
        if (!prev.some((user) => user.id === id) && selectedRedHubUser) {
          return [...prev, selectedRedHubUser];
        }
      } else {
        return prev.filter((user) => user.id !== id);
      }
      return prev;
    });
  };

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      if (data?.data) {
        const filtered = data.data.filter((user) =>
          user.name.toLowerCase().includes(searchQuery.toLowerCase()),
        );
        setFilteredUsers(filtered);
      }
    }, 300); // ⏳ Debounce selama 300ms

    return () => clearTimeout(delayDebounce);
  }, [searchQuery, data, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setSearchQuery('');
      setFilteredUsers([]);
      setSelectedUserAdmins([]);
    } else {
      refetchAdminOption();
    }
  }, [isOpen]);

  return (
    <ModalCenter isOpen={isOpen} onClose={handleClose}>
      <div className="relative w-886 p-35 md:w-full">
        <h1 className="text-24 font-semibold leading-32 text-black-redx md:text-18">
          Add new admin
        </h1>

        <div className="mt-24 grid grid-cols-1 gap-24 ">
          <TextInput
            className="!leading-22 w-full pl-8 !text-16 !shadow-none !outline-none focus:!border-none focus:!shadow-none focus:!outline-none focus:!ring-0"
            containerClassName="border-1 border-stroke-redx rounded-8 px-16 py-8"
            hasSearchIcon
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSearchQuery(e.target.value)
            }
            placeholder="Search name here"
          />

          <div className="grid max-h-200 grid-cols-1 overflow-scroll scrollbar-hide">
            {filteredUsers.map((item) => {
              const isChecked = selectedUserAdmins.some(
                (user) => user.id === item.id,
              );

              return (
                <Checkbox
                  key={item.id}
                  checked={isChecked}
                  label={item.name}
                  onChange={(value) => handleOnChangeUser(item.id, value)}
                />
              );
            })}
          </div>

          <p className="text-14 font-semibold leading-20">
            {selectedUserAdmins.length} User Selected
          </p>
        </div>

        <div className="mt-24 flex items-center justify-end gap-16 gap-x-10 md:flex-col">
          <Button
            className="rounded-8 border-1 border-blue-redx px-16 py-8 text-blue-redx md:w-full"
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            className={cn(
              'rounded-8  border-1  px-16 py-8 text-white md:w-full',
              selectedUserAdmins.length === 0
                ? 'opacity-50 cursor-not-allowed bg-placeholder-redx'
                : 'cursor-pointer bg-blue-purple-redx',
            )}
            disabled={isPending || selectedUserAdmins.length === 0}
            onClick={handleSubmit}
          >
            Add as Admin
          </Button>
        </div>
      </div>
    </ModalCenter>
  );
};

export default AddNewModal;
