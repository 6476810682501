import { TabGroup } from '@headlessui/react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import EditedLabel from '@/Components/EditedLabel';
import ErrorGenerate from '@/Components/ErrorGenerate';
import GenerateLoading from '@/Components/GenerateLoading';
import ChangingImpactModal from '@/Components/Modals/ChangingImpactModal';
import RegenerateInfoModal from '@/Components/Modals/RegenerateInfoModal';
import ViewInfoModal from '@/Components/Modals/ViewInfoModal';
import RegenerateButton from '@/Components/Projects/RegenerateButton';
import { saveInformation } from '@/Context/Actions/project-actions';
import { ProjectContext } from '@/Context/ProjectContext';
import type { RouteOption } from '@/Context/Reducer/project-reducer';
import {
  useCtaAction,
  useProductResearch,
  useSectionData,
} from '@/Hooks/ProductResearch';
import { useGenerate } from '@/Hooks/useGenerate';
import { useHistoryStatus } from '@/Hooks/useHistoryStatus';
import { useProgress } from '@/Hooks/useProgress';
import useRolesBadge from '@/Hooks/useRolesBadge';
import { useRoutePrev } from '@/Hooks/useSaveRoutePrev';
import type { SectionData } from '@/Types/ProductResearch';
import type { SectionList } from '@/Types/tabs';
import { cn } from '@/Utils/cn';
import { progressPortion } from '@/Utils/dispatcher';
import { triggerGTMEvent } from '@/Utils/gtm';
import { removeAllArchetypesBasedOnSectionFromLocalStorage } from '@/Utils/persist-progress';

import ContentSection from './Components/ContentSection';
import Tabs from './Components/Tabs';

const Index = () => {
  const [state, dispatch] = useContext(ProjectContext);
  const user = useMemo(() => state.user, [state.user]);
  const project = useMemo(() => state.project, [state.project]);

  const sectionList: SectionList[] = [
    {
      title: 'Product Description',
      value: 'product_description',
      section: 'product_research',
    },
    {
      title: 'Main Features',
      value: 'main_features',
      section: 'product_research',
    },
    {
      title: 'Marcomm Highlights',
      value: 'marcomm_highlights',
      section: 'product_research',
    },
  ];
  const navigate = useNavigate();
  const { mutateAsync: generate, isPending: isGenerating } = useGenerate();

  const maxGenerateLimit = project.max_generated_data;

  const [generatingStates, setGeneratingStates] = useState<
    Record<number, boolean>
  >({});
  const { roles } = useRolesBadge(project, user);
  const isEditor = roles.includes('Owner') || roles.includes('Strategist');
  const [isEditing, setIsEditing] = useState(false);
  const [successSelected, setSuccessSelected] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [sectionData, setSectionData] = useState<Record<string, SectionData>>(
    {},
  );
  const [archetypes, setArchetypes] = useState<
    Record<
      number,
      {
        id: number;
        is_querying: boolean;
      }
    >
  >({});

  const [showAlertGenerateModal, setShowAlertGenerateModal] = useState(false);

  const [progressStates, setProgressStates] = useState<Record<number, number>>(
    {},
  );

  const {
    isKeyInsightsComplete,
    isProductResearchMainFeature,
    isProductResearchMarcommHighlight,
    isProductResearchProductDescription,
  } = useHistoryStatus(project);

  const [showViewInfoModal, setShowViewInfoModal] = useState(false);

  const activeSection = useMemo(() => {
    return sectionList[activeTab];
  }, [activeTab]);

  const { data, isPending, refetch } = useProductResearch(
    project.slug,
    activeSection.value,
  );
  const [showRegenerateModal, setShowRegenerateModal] = useState(false);
  const routeOptionValue: RouteOption = {
    id: 'btn-next-to-market-research',
    label: 'Next to Market Research',
    isActive: true,
    isGenerate: false,
    isDisabled: false,
    onClick: () => navigate(`/${project.slug}/market-research`),
  };

  const handlers: Record<
    string,
    (slug: string, prompt?: string) => Promise<void>
  > = {
    product_description: async (slug: string, prompt?: string) => {
      await generate({
        projectSlug: slug,
        section: 'product-research/product-description',
        payload: {
          generate_more: '',
          regenerate_prompt: prompt ?? '',
        },
      });
    },
    main_features: async (slug: string, prompt?: string) => {
      await generate({
        projectSlug: slug,
        section: 'product-research/main-features',
        payload: {
          generate_more: '',
          regenerate_prompt: prompt ?? '',
        },
      });
    },
    marcomm_highlights: async (slug: string, prompt?: string) => {
      await generate({
        projectSlug: slug,
        section: 'product-research/marcomm-highlights',
        payload: {
          generate_more: '',
          regenerate_prompt: prompt ?? '',
        },
      });
    },
  };

  useEffect(() => {
    dispatch(saveInformation(''));
  }, []);

  useSectionData({
    data,
    activeSection: activeSection.value,
    projectSlug: project.slug,
    progressStates,
    setGeneratingStates,
    setProgressStates,
    setSectionData,
  });

  const totalRegenerate =
    sectionData[activeSection.value]?.total_regenerate || 0;
  const isEdited = sectionData[activeSection.value]?.is_edited || false;
  const isError = sectionData[activeSection.value]?.is_error || false;
  const content = sectionData[activeSection.value]?.content || '';
  const sources = sectionData[activeSection.value]?.sources;
  const historyId = sectionData[activeSection.value]?.history_id;
  const isQuerying = sectionData[activeSection.value]?.is_querying;
  const message = sectionData[activeSection.value]?.message;
  const previousContentPayload =
    sectionData[activeSection.value]?.previous_payload_data || '';
  const isDiffProductResearchWithOrigin =
    sectionData[activeSection.value]?.is_diff_product_research_with_origin ||
    false;

  let isEmailNotification = false;
  if (activeSection.value === 'product_description') {
    isEmailNotification = isProductResearchProductDescription.isSendingEmail;
  } else if (activeSection.value === 'marcomm_highlights') {
    isEmailNotification = isProductResearchMarcommHighlight.isSendingEmail;
  } else {
    isEmailNotification = isProductResearchMainFeature.isSendingEmail;
  }

  useEffect(() => {
    if (historyId) {
      setArchetypes((prev) => ({
        ...prev,
        [historyId]: {
          id: historyId,
          is_querying: isQuerying ?? false,
        },
      }));
    }
  }, [historyId, isQuerying]);

  const activeArchetype = useMemo(() => {
    if (!historyId) return null;
    return archetypes[historyId] || null;
  }, [archetypes, historyId]);

  useProgress({
    activeArchetype,
    generatingStates,
    progressStates,
    activeTab,
    projectSlug: project.slug,
    section: activeSection.value,
    refetch,
    setGeneratingStates,
    setArchetypes,
    setProgressStates,
  });

  const handleRegenerateButton = async (section: string, prompt: string) => {
    triggerGTMEvent({
      event: `Regenerate ${section}`,
      eventCategory: `Btn Regenerate ${section} Click`,
      eventAction: 'Click',
      eventLabel: section,
      userId: user.email,
      data: prompt,
    });

    removeAllArchetypesBasedOnSectionFromLocalStorage(
      project.slug,
      activeSection.value,
    );

    setSectionData((prev) => ({
      ...prev,
      [activeSection.value]: {
        ...prev[activeSection.value],
        is_querying: true,
      },
    }));

    await handlers[section](project.slug, prompt);

    refetch();
  };

  useRoutePrev({
    id: 'btn-back-product-research',
    label: 'Back',
    isActive: message !== 'No data found',
    onClick: () => navigate(`/${project.slug}/submission`),
  });

  useEffect(() => {
    successSelected && setShowAlertGenerateModal(isEdited);
  }, [isEdited, successSelected]);

  useCtaAction({
    isError,
    activeSection: activeSection.value,
    isEditing,
    isEditor,
    project,
    message,
    isEdited,
    routeOptionValue,
    setShowViewInfoModal,
    setShowRegenerateModal,
  });

  const handleRegenerate = async () => {
    await generate(
      {
        payload: {
          regenerate_prompt: '',
          generate_more: '',
          status: 'edit product research',
          section: 'product_research',
          subsection: activeSection.value,
        },
        projectSlug: project.slug,
        section: 'key-insight',
      },
      {
        onSuccess: () => {
          refetch();
          setShowRegenerateModal(false);
        },
      },
    );
  };

  const handleRegenerateError = async (section: string) => {
    triggerGTMEvent({
      event: `Regenerate error ${section}`,
      eventCategory: `Btn Regenerate ${section} Click`,
      eventAction: 'Click',
      eventLabel: section,
      userId: user.email,
    });

    removeAllArchetypesBasedOnSectionFromLocalStorage(
      project.slug,
      activeSection.value,
    );

    setSectionData((prev) => ({
      ...prev,
      [activeSection.value]: {
        ...prev[activeSection.value],
        is_querying: true,
      },
    }));

    await handlers[section](project.slug, '');

    refetch();
  };

  useEffect(() => {
    const fetchData = async () => {
      await refetch();
    };
    fetchData();
  }, [isKeyInsightsComplete.isQuerying, isKeyInsightsComplete.isRegenerated]);

  return (
    <>
      <div className="size-full">
        <div className="sticky top-56 z-20 w-full bg-white py-16">
          <h1 className="mb-8 text-25 font-bold leading-none">
            Product Research
          </h1>
          <span className="text-15 font-normal text-grey-redx">
            Please review and edit this information, considering that the
            provided reference may not always be accurate.
          </span>
        </div>

        <TabGroup
          className="pb-80"
          onChange={setActiveTab}
          selectedIndex={activeTab}
        >
          <Tabs sections={sectionList} user={user} />
          <div className="h-full py-24">
            <div
              className={cn(
                'mb-8 flex w-full items-center',
                isDiffProductResearchWithOrigin
                  ? 'justify-between'
                  : 'justify-end',
              )}
            >
              {!isError && isDiffProductResearchWithOrigin && !isQuerying && (
                <EditedLabel />
              )}
              {!isError &&
                isEditor &&
                !isQuerying &&
                message !== 'No data found' && (
                  <RegenerateButton
                    limit={totalRegenerate}
                    maxLimit={maxGenerateLimit}
                    onSubmit={({ prompt }) =>
                      handleRegenerateButton(activeSection.value, prompt)
                    }
                    section={activeSection.value}
                  />
                )}
            </div>
            {(() => {
              if (isPending) {
                return (
                  <ContentSection
                    activeSection={activeSection.value}
                    isLoading
                  />
                );
              }

              if (isQuerying) {
                return (
                  <GenerateLoading
                    progress={progressPortion({
                      progress: Number(
                        (progressStates[historyId] || 0).toFixed(0),
                      ),
                      isQuerying,
                      isContentAvailable: !!content,
                    })}
                    project={project}
                    section={activeSection}
                    showEmailNotification={isEmailNotification}
                  />
                );
              }

              if (isError && !isQuerying && !isPending) {
                return (
                  <ErrorGenerate
                    onGenerate={() =>
                      handleRegenerateError(activeSection.value)
                    }
                  />
                );
              }

              return (
                <ContentSection
                  activeSection={activeSection.value}
                  content={content ?? ''}
                  id={historyId}
                  isEditor={isEditor}
                  isKeyInsightsQuerying={isKeyInsightsComplete.isQuerying}
                  message={message}
                  onEditingChange={setIsEditing}
                  onSetSuccessSelected={setSuccessSelected}
                  previousContentPayload={previousContentPayload}
                  projectSlug={project.slug}
                  refetch={refetch}
                  sources={sources}
                />
              );
            })()}
          </div>
        </TabGroup>
      </div>

      <ViewInfoModal
        isOpen={showViewInfoModal}
        onClose={() => setShowViewInfoModal(false)}
      />

      <RegenerateInfoModal
        isOpen={showAlertGenerateModal}
        onClose={() => {
          setShowAlertGenerateModal(false);
        }}
        section="product-research"
      />

      <ChangingImpactModal
        handleSubmit={handleRegenerate}
        isOpen={showRegenerateModal}
        isRegenerating={isGenerating}
        onClose={() => setShowRegenerateModal(false)}
        section="audience-archetype"
        visibleSections={[
          'Key Insight',
          'Challenge & Communication Task',
          'One-Page Strategy',
          'Key Touch Point',
          'Idea Alignment',
          'Idea Personalization',
          'Idea Expansion',
          'Searchability Content',
          'Discoverability Content',
          'Credibility Content',
          'Selected Content Ideas',
        ]}
      />
    </>
  );
};

export default Index;
