import { useMutation } from '@tanstack/react-query';
import Cookies from 'js-cookie';

import { fetcher } from '@/Services/axios';
import { goFetcher } from '@/Services/axios-go';
import { saveDataToIndexedDb } from '@/Utils/indexed-db';

interface LoginProps {
  email: string;
  password: string;
}
const login = async ({ email, password }: LoginProps) => {
  try {
    const isDeveloper = email === 'developer.ai@redcomm.co.id';
    const responses = isDeveloper
      ? [
          await goFetcher.post('/auth/login', {
            email,
            password,
            remember: false,
            no_access: null,
          }),
        ]
      : await Promise.all([
          fetcher.post('/login', {
            email,
            password,
            remember: false,
            no_access: null,
          }),
          goFetcher.post('/auth/login', {
            email,
            password,
            remember: false,
            no_access: null,
          }),
        ]);

    const dataLogin = responses[responses.length - 1].data?.data;
    if (!dataLogin) throw new Error('No data returned');

    const {
      access_token: accessToken,
      expires_in: expiresInSeconds,
      user,
    } = dataLogin;
    await saveDataToIndexedDb('user', user);
    Cookies.set('access_token', accessToken, {
      expires: expiresInSeconds / (60 * 60 * 24),
    });

    if (!isDeveloper) {
      Cookies.set('access_token_ai', responses[0].data.access_token, {
        expires: 1,
      });
    }

    return dataLogin;
  } catch (error: any) {
    throw new Error(
      error.response?.data?.message ||
        error.response?.data?.error ||
        'Login failed',
    );
  }
};

const useLogin = () => {
  return useMutation<any, Error, LoginProps>({
    mutationFn: login,
  });
};

export { useLogin };
