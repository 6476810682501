import { isEqual } from 'lodash';
import { useEffect } from 'react';

import type {
  MarketResearchResponse,
  SectionData,
} from '@/Types/MarketResearch';
import { cleanContentAsText } from '@/Utils/clean-content';
import {
  initializeGeneratingStates,
  initializeProgressStates,
} from '@/Utils/init-data';
import { getSectionProgressFromLocalStorage } from '@/Utils/persist-progress';

interface UseSectionDataParams {
  data: MarketResearchResponse | undefined;
  activeSection: string;
  projectSlug: string;
  progressStates: Record<number, number>;
  setGeneratingStates: React.Dispatch<
    React.SetStateAction<Record<number, boolean>>
  >;
  setProgressStates: React.Dispatch<
    React.SetStateAction<Record<number, number>>
  >;
  setSectionData: React.Dispatch<
    React.SetStateAction<Record<string, SectionData>>
  >;
}

export const useSectionData = ({
  data,
  activeSection,
  projectSlug,
  progressStates,
  setGeneratingStates,
  setProgressStates,
  setSectionData,
}: UseSectionDataParams) => {
  useEffect(() => {
    if (data) {
      const sectionKey = activeSection;

      setSectionData((prev) => {
        const existingData = prev[sectionKey];

        if (data.is_querying) {
          const dataArray = [
            {
              id: data.history_id,
              ...data,
            },
          ];

          const initialStates = initializeGeneratingStates(dataArray);
          setGeneratingStates(initialStates);

          const savedProgress = getSectionProgressFromLocalStorage(
            projectSlug,
            sectionKey,
          );

          const updatedProgressStates = initializeProgressStates(
            dataArray, // Data array yang sudah diubah
            savedProgress,
            progressStates,
          );

          setProgressStates((prev) => ({
            ...prev,
            ...updatedProgressStates,
          }));
        }
        const currentVsOrigin =
          cleanContentAsText(data.content ?? '') ===
          cleanContentAsText(data.origin_content ?? '');

        // Update section data jika berbeda
        if (!isEqual(existingData, data)) {
          return {
            ...prev,
            [sectionKey]: {
              is_email_notification: data.is_email_notification,
              history_id: data.history_id ?? 0,
              content: data.content ?? '',
              sources: data.sources ?? [],
              total_regenerate: data.total_regenerate ?? 0,
              is_querying: data.is_querying,
              is_edited: data.is_edited,
              is_diff_market_research_with_origin: !currentVsOrigin, // jka true maka artinnya sama
              message: data.message,
              is_error: data.is_error,
              previous_payload_data: data.previous_payload_data,
            },
          };
        }

        return prev;
      });
    }
  }, [data, activeSection]);
};
