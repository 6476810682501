/* eslint-disable @typescript-eslint/naming-convention */
import { Icon } from '@iconify/react/dist/iconify.js';
import type { InputHTMLAttributes, TextareaHTMLAttributes } from 'react';
import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';

import { setEnableHistoryStatus } from '@/Context/Actions/project-actions';
import { ProjectContext } from '@/Context/ProjectContext';
import { cn } from '@/Utils/cn';

import Checkbox from './Checkbox';
import RadioButton from './RadioButton';

type Props = {
  isFocused?: boolean;
  rightElement?: React.ReactNode;
  containerClassName?: string;
  radioButtonValue?: string;
  error?: string;
  light?: boolean;
  hasSearchIcon?: boolean;
  hasRadio?: boolean;
  hasCheckBox?: boolean;
  isCheckboxChecked?: boolean;
  isMultiline?: boolean; // Tambahkan untuk menentukan penggunaan textarea
} & (
  | InputHTMLAttributes<HTMLInputElement>
  | TextareaHTMLAttributes<HTMLTextAreaElement>
);

export default forwardRef(function TextInput(
  {
    isFocused = false,
    className = '',
    radioButtonValue = '',
    containerClassName = '',
    placeholder = '',
    light = false,
    hasSearchIcon = false,
    error = '',
    rightElement = null,
    hasRadio = false,
    hasCheckBox = false,
    isCheckboxChecked = false,
    isMultiline = false, // Default menggunakan input
    ...props
  }: Props,
  ref,
) {
  const [, dispatch] = useContext(ProjectContext);

  const localRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);

  useImperativeHandle(ref, () => ({
    focus: () => localRef.current?.focus(),
  }));

  useEffect(() => {
    if (isFocused) {
      localRef.current?.focus();
    }
  }, [isFocused]);

  const placeholderClass = light
    ? 'placeholder-lightplaceholder-redx'
    : 'placeholder-placeholder-redx';

  // Event handler untuk fokus
  const handleFocus = () => {
    dispatch(setEnableHistoryStatus(false));
  };

  return (
    <div className="relative flex w-full flex-col">
      <div
        className={`flex w-full items-center overflow-hidden border-b-1 bg-transparent transition-all duration-300 focus-within:!border-b-2 ${
          error ? '!border-red-500' : 'border-soft-purplestroke-redx'
        } ${containerClassName}`}
      >
        {hasSearchIcon && <Icon icon="lucide:search" />}

        {hasRadio && (
          <RadioButton className="pb-20" disabled value={radioButtonValue} />
        )}
        {hasCheckBox && (
          <Checkbox checked={isCheckboxChecked} className="pb-20" disabled />
        )}

        {isMultiline ? (
          <textarea
            ref={localRef as React.RefObject<HTMLTextAreaElement>} // Casting ref ke textarea
            className={`resize-none overflow-auto break-words rounded-md border-transparent bg-transparent  px-0   text-12 shadow-sm ${placeholderClass} ${className}`}
            disabled={props.disabled}
            onFocus={handleFocus}
            placeholder={placeholder}
            {...(props as TextareaHTMLAttributes<HTMLTextAreaElement>)} // Pastikan props sesuai dengan textarea
          />
        ) : (
          <input
            ref={localRef as React.RefObject<HTMLInputElement>} // Casting ref ke input
            className={cn(
              '!focus:border-transparent !focus:ring-0 rounded-md !border-transparent bg-transparent px-0 py-2 text-12 shadow-sm',
              placeholderClass,
              className,
              props.disabled && '!text-placeholder-redx',
            )}
            disabled={props.disabled}
            onFocus={handleFocus}
            placeholder={placeholder}
            {...(props as InputHTMLAttributes<HTMLInputElement>)} // Pastikan props sesuai dengan input
          />
        )}
        {rightElement}
      </div>
      {error && (
        <p className="absolute -bottom-20 text-12 text-red-500">{error}</p>
      )}
    </div>
  );
});
