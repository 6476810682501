import Table from '@/Components/Table';
import { useAdminUserContext } from '@/Hooks/Administrator';

import DetailModal from './DetailModal';
import RevokeConfirmationModal from './RevokeConfirmationModal';
import TableRowRequestList from './TableRow';

const COLUMNS = [
  { title: 'Admin Name' },
  { title: 'Admin Email' },
  { title: 'Admin Role Activation Date' },
  { title: 'Status' },
  { title: '' },
];

const TableRequestList = () => {
  const {
    records,
    hasNextPage,
    isFetchingNextPage,
    updateStatus,
    isLoading,
    setTargetRef,
    handleUpdateStatus,
  } = useAdminUserContext();

  const isDetail = updateStatus.status === 'detail';
  const isRevoke = updateStatus.status === 'revoke';
  const selectedUser = updateStatus.id;

  if (isLoading) return null;

  return (
    <>
      <div className="border max-h-[calc(100vh-300px)] w-full overflow-auto scrollbar-hide md:max-w-sm">
        {records.length > 0 && !isLoading ? (
          <>
            <Table columns={COLUMNS}>
              {records.map((record) => (
                <TableRowRequestList key={record.id} row={record} />
              ))}
            </Table>
            {hasNextPage && (
              <div
                ref={(el) => {
                  if (el) {
                    setTargetRef(el);
                  }
                }}
                className="p-24 text-center"
              >
                {isFetchingNextPage ? (
                  <p className="font-medium">Loading more data...</p>
                ) : null}
              </div>
            )}
          </>
        ) : (
          <div className="flex flex-col items-center justify-center">
            <img
              alt="Empty-amico 1.png"
              className="size-400"
              src="assets/img/Empty-amico 1.png"
            />
            <div className="flex flex-col items-center justify-items-center gap-20">
              <h2 className="text-32 font-semibold leading-[36px] text-black-redx md:text-24">
                Data not found
              </h2>
              <p className="text-center text-16 font-normal leading-20 text-grey-redx">
                There’s currently no information available here.
              </p>
            </div>
          </div>
        )}
      </div>
      <RevokeConfirmationModal
        isOpen={isRevoke}
        onClose={() => handleUpdateStatus([], '')}
        selectedUser={records.find((record) => record.id === selectedUser)}
      />
      <DetailModal
        isOpen={isDetail}
        onClose={() => handleUpdateStatus([], '')}
        selectedUser={records.find((record) => record.id === selectedUser)}
      />
    </>
  );
};

export default TableRequestList;
