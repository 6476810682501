import { z } from 'zod';

export const AddUser = z.object({
  name: z.number().min(1, { message: 'Name is required' }),
  email: z
    .string()
    .email({ message: 'Invalid email address' })
    .min(4, { message: 'email is required' }),
  reason: z.string().optional(), // Optional string
});

export type AddUserSchema = z.infer<typeof AddUser>;

export const Role = z.object({
  name: z.string().min(1, { message: 'Name must is required' }),
  display_name: z.string().min(1, { message: 'Display Name is required' }),
});

export type RoleSchema = z.infer<typeof Role>;
