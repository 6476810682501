import { isEqual } from 'lodash';
import { useCallback, useEffect } from 'react';

import type {
  CompetitorAnalysisContentData,
  CompetitorAnalysisListResponse,
  SectionData,
} from '@/Types/CompetitorAnalysis';
import type { HistoryStatus } from '@/Types/history';

interface UseSectionDataHandlerProps {
  isDiffArchetype: boolean;
  isOpportunityAmongCompetitionComplete: HistoryStatus;
  data?: CompetitorAnalysisListResponse;
  sectionData: Record<string, SectionData>;
  sectionKey: string;
  projectSlug: string;
  initializeGeneratingStates: (dataArray: any[]) => Record<number, boolean>;
  getSectionProgressFromLocalStorage: (slug: string, section: string) => any;
  initializeProgressStates: (
    dataArray: any[],
    savedProgress: any,
    progressStates: Record<number, number>,
  ) => Record<number, number>;
  setGeneratingStates: React.Dispatch<
    React.SetStateAction<Record<number, boolean>>
  >;
  setProgressStates: React.Dispatch<
    React.SetStateAction<Record<number, number>>
  >;
  setSectionData: React.Dispatch<
    React.SetStateAction<Record<string, SectionData>>
  >;
  progressStates: Record<number, number>;
}

export const useSectionDataHandler = ({
  isOpportunityAmongCompetitionComplete,
  data,
  sectionData,
  sectionKey,
  projectSlug,
  initializeGeneratingStates,
  getSectionProgressFromLocalStorage,
  initializeProgressStates,
  setGeneratingStates,
  setProgressStates,
  setSectionData,
  progressStates,
}: UseSectionDataHandlerProps) => {
  const hasDifferences = useCallback(
    (
      current: CompetitorAnalysisContentData[],
      previous: CompetitorAnalysisContentData[],
    ): boolean => {
      if (!isOpportunityAmongCompetitionComplete.status) return false;

      // Jika panjang array berbeda, langsung return true
      if (current.length !== previous.length) return true;

      return current.some((currentItem, index) => {
        const prevItem = previous[index]; // Ambil item yang sama berdasarkan indeks

        if (!prevItem) return true; // Jika tidak ada di previous, berarti ada perubahan

        // Daftar properti yang harus dibandingkan
        const keysToCompare: (keyof CompetitorAnalysisContentData)[] = [
          'brand_name',
          'product_name',
          'communication_angle',
          'communication_idea',
          'communication_tone',
          'features_benefits',
          'key_touch_points',
          'opportunities',
        ];

        // Cek jika ada perubahan di salah satu properti
        return keysToCompare.some(
          (key) => !isEqual(currentItem[key], prevItem[key]),
        );
      });
    },
    [isOpportunityAmongCompetitionComplete],
  );

  const markEditedItems = (
    current: CompetitorAnalysisContentData[],
    previous: CompetitorAnalysisContentData[],
  ) => {
    return current.map((item, index) => {
      const prevItem = previous[index]; // Ambil item sebelumnya pada index yang sama

      // Jika tidak ada data sebelumnya di index ini, tandai sebagai item baru
      if (!prevItem) return { ...item, is_edited: true };

      // Properti yang harus dibandingkan
      const keysToCompare: (keyof CompetitorAnalysisContentData)[] = [
        'brand_name',
        'product_name',
        'communication_angle',
        'communication_idea',
        'communication_tone',
        'features_benefits',
        'key_touch_points',
        'opportunities',
      ];

      // Cek apakah ada perubahan dalam salah satu properti
      const isEdited = keysToCompare.some(
        (key) => !isEqual(item[key], prevItem[key]),
      );

      return { ...item, is_edited: isEdited };
    });
  };

  useEffect(() => {
    if (!data) return;

    if (data.is_querying) {
      const dataArray = [{ id: data.history_id, ...data }];
      setGeneratingStates(initializeGeneratingStates(dataArray));

      setProgressStates((prev) => ({
        ...prev,
        ...initializeProgressStates(
          dataArray,
          getSectionProgressFromLocalStorage(projectSlug, sectionKey),
          progressStates,
        ),
      }));
    }

    const updatedContent = markEditedItems(data.content, data.origin_data);
    const isDiff = hasDifferences(
      data.content,
      data.previous_selected_competitors,
    );

    if (!isEqual(sectionData[sectionKey], data)) {
      setSectionData((prev) => ({
        ...prev,
        [sectionKey]: {
          is_edited: data.is_edited ?? false,
          history_id: data.history_id ?? 0,
          is_querying: data.is_querying ?? false,
          is_email_notification: data.is_email_notification ?? false,
          sources: data.sources ?? [],
          total_regenerate: data.total_regenerate ?? 0,
          content: updatedContent,
          is_diff_competitor: isDiff,
          message: data.message,
          origin_data: data.origin_data ?? [],
          is_error: data.is_error ?? false,
        },
      }));
    }
  }, [data, isOpportunityAmongCompetitionComplete]);
};
