import { Button } from '@headlessui/react';

import Checkbox from '@/Components/Checkbox';
import { useUserContext } from '@/Hooks/Administrator/useUserContext';
import type { AdministratorUserResponse } from '@/Types/Administrator';

interface TableRowProps {
  row: AdministratorUserResponse;
}

const TableRowRequestList = ({ row }: TableRowProps) => {
  const { checkPerRow, setCheckPerRow, handleUpdateStatus, activeFilter } =
    useUserContext();

  return (
    <tr>
      <td className="border-b-1 border-stroke-redx px-16 py-30 text-center">
        {row.email !== 'developer.ai@redcomm.co.id' && (
          <Checkbox
            checked={!!checkPerRow.find((id) => id === row.id)}
            onChange={(value) => setCheckPerRow(row.id, value)}
          />
        )}
      </td>
      <td className="border-b-1 border-stroke-redx px-16 py-30">
        <div className="flex w-155 max-w-155 flex-col items-start gap-8 ">
          <p className="break-words text-14 font-normal leading-16">
            {row.name}
          </p>
        </div>
      </td>
      <td className="border-b-1 border-stroke-redx px-16 py-30">
        <p className="text-14 font-normal leading-16">{row.email}</p>
        <p className="whitespace-normal break-all text-12 font-normal leading-16 text-dark-stroke-redx">
          {row.redhub_id}
        </p>
      </td>
      <td className="border-b-1 border-stroke-redx px-16 py-30 text-14 font-normal leading-16">
        {row.request_date && (
          <>
            <p>
              {new Date(row.request_date).toLocaleDateString('id-ID', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })}
            </p>
            <p>
              {new Date(row.request_date)
                .toLocaleTimeString('id-ID', {
                  hour: '2-digit',
                  minute: '2-digit',
                })
                .replace('.', ':')}
            </p>
          </>
        )}
      </td>
      <td className="border-b-1 border-stroke-redx px-16 py-30 text-14 font-normal leading-16">
        {activeFilter === 'disabled'
          ? row.deleted_at && (
              <>
                <p>
                  {new Date(row.deleted_at).toLocaleDateString('id-ID', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                  })}
                </p>
                <p>
                  {new Date(row.deleted_at)
                    .toLocaleTimeString('id-ID', {
                      hour: '2-digit',
                      minute: '2-digit',
                    })
                    .replace('.', ':')}
                </p>
              </>
            )
          : row.request_activation_date && (
              <>
                <p>
                  {new Date(row.request_activation_date).toLocaleDateString(
                    'id-ID',
                    {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                    },
                  )}
                </p>
                <p>
                  {new Date(row.request_activation_date)
                    .toLocaleTimeString('id-ID', {
                      hour: '2-digit',
                      minute: '2-digit',
                    })
                    .replace('.', ':')}
                </p>
              </>
            )}
      </td>
      <td className="border-b-1 border-stroke-redx px-16 py-30 text-14 font-normal leading-20">
        {!row.deleted_at ? (
          <p className="max-w-fit rounded-20 bg-light-g100-redx px-12 py-6 text-14 font-semibold leading-16 text-light-success-redx">
            Active
          </p>
        ) : (
          <p className="max-w-fit rounded-20 bg-soft-grey-redx px-12 py-6 text-14 font-semibold leading-16 text-grey-redx">
            Disabled
          </p>
        )}
      </td>

      <td className="border-b-1 border-stroke-redx px-16 py-30 text-center">
        <Button
          className="min-w-120 rounded-8 border-1 border-stroke-redx px-12 py-8 text-center text-14 font-semibold leading-20 text-black-redx"
          onClick={() => handleUpdateStatus([row.id], 'detail')}
        >
          Detail
        </Button>
      </td>
    </tr>
  );
};

export default TableRowRequestList;
