import { useMutation } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { RequestUserResponse } from '@/Types/Administrator';
import type { ArchetypeResponse } from '@/Types/AudienceArchetype';

interface UpdateStatusProps {
  status: string;
  ids: RequestUserResponse['id'][];
  reason: string;
}

const updateStatus = async ({ ids, status, reason }: UpdateStatusProps) => {
  try {
    const url = `/administrators/request-lists/update-status`;
    const { data } = await goFetcher.post(url, {
      ids,
      status,
      reason,
    });

    return data as ArchetypeResponse;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useUpdateRequestList = () => {
  return useMutation<any, Error, UpdateStatusProps>({
    mutationFn: updateStatus,
  });
};

export { useUpdateRequestList };
