import { useQuery } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { RedHubUserOptionsResponse } from '@/Types/Administrator';

const getRedHubUserOptions = async ({
  isAdmin = false,
}: {
  isAdmin?: boolean;
}) => {
  try {
    const url = isAdmin
      ? `/administrators/redhub-admin-user-options`
      : `/administrators/redhub-user-options`;
    const { data } = await goFetcher.get(url);

    return data.data as RedHubUserOptionsResponse;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useRedHubUserOption = ({ isAdmin = false }: { isAdmin?: boolean }) => {
  return useQuery<RedHubUserOptionsResponse, Error>({
    queryKey: ['redhubOptions'], // use project.slug to ensure cache uniqueness per project
    queryFn: () => getRedHubUserOptions({ isAdmin }),
    refetchOnWindowFocus: false, // Keep data fresh indefinitely (no refetching)
    refetchOnMount: false, // Keep data fresh indefinitely (no refetching)
  });
};

export { useRedHubUserOption };
