import { TabGroup } from '@headlessui/react';
import Cookies from 'js-cookie';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import ErrorGenerate from '@/Components/ErrorGenerate';
import GenerateLoading from '@/Components/GenerateLoading';
import ChangingImpactModal from '@/Components/Modals/ChangingImpactModal';
import RegenerateInfoModal from '@/Components/Modals/RegenerateInfoModal';
import SelectingFunnel from '@/Components/Modals/SelectingFunnel';
import ViewInfoModal from '@/Components/Modals/ViewInfoModal';
import NotDataFound from '@/Components/NotDataFound';
import ActionSection from '@/Components/Projects/ActionSection';
import RegenerateButton from '@/Components/Projects/RegenerateButton';
import SliderContainer from '@/Components/Projects/SliderContainer';
import SourcesList from '@/Components/Projects/SourcesList';
import SelectedCard from '@/Components/SelectedCard';
import {
  saveInformation,
  saveRouteNext,
  saveSubInformation,
  setEnableHistoryStatus,
} from '@/Context/Actions/project-actions';
import { ProjectContext } from '@/Context/ProjectContext';
import type { RouteOption } from '@/Context/Reducer/project-reducer';
import { useDifferentArchetypes } from '@/Hooks/AudienceArchetype';
import {
  useCtaAction,
  useKeyTouchPoint,
  useSectionDataHandler,
  useUpdateKeyTouchPoint,
} from '@/Hooks/KeyTouchPoint';
import { useGenerate } from '@/Hooks/useGenerate';
import { useHistoryStatus } from '@/Hooks/useHistoryStatus';
import { useProgress } from '@/Hooks/useProgress';
import useRolesBadge from '@/Hooks/useRolesBadge';
import { useRoutePrev } from '@/Hooks/useSaveRoutePrev';
import type {
  ArchetypeKeyTouchPoint,
  ArchetypesState,
  RecommendationContent,
  SectionKeyTouchPoint,
} from '@/Types/KeyTouchPoint';
import type { KeyTouchPointSchema } from '@/Types/KeyTouchPoint/schema';
import type { SelectedFramework, SourcesType } from '@/Types/Projects';
import type { SectionList } from '@/Types/tabs';
import { getArchetypeDetails } from '@/Utils/archetype';
import { progressPortion } from '@/Utils/dispatcher';
import { triggerGTMEvent } from '@/Utils/gtm';
import { removeAllArchetypesBasedOnSectionFromLocalStorage } from '@/Utils/persist-progress';

import type { CardHandles } from './Components/Card';
import Card from './Components/Card';
import SelectedFrameworkCard from './Components/SelectedFrameworkCard';
import Skeleton from './Components/Skeleton';
import TabListFunnel from './Components/TabListFunnel';
import Tabs from './Components/Tabs';

const Index = () => {
  const [message, setMessage] = useState('');
  const [state, dispatch] = useContext(ProjectContext);
  const user = useMemo(() => state.user, [state.user]);
  const project = useMemo(() => state.project, [state.project]);
  const [selectedFramework, setSelectedFramework] = useState<SelectedFramework>(
    project.submissions[0].selected_framework,
  );
  const [selectedSuccess, setSelectedSuccess] = useState(false);
  const sectionList: SectionList = useMemo(
    () => ({
      title: 'Key Touch Point',
      value: selectedFramework,
      section: 'key_touch_point',
    }),
    [selectedFramework],
  );

  const navigate = useNavigate();
  const [modalFramework, setModalFramework] = useState<boolean>(false);
  const cardRefs = useRef<(CardHandles | null)[]>([]);

  const { roles } = useRolesBadge(project, user);

  const isEditor =
    roles.includes('Owner') ||
    roles.includes('Strategist') ||
    roles.includes('Creative');
  const [archetypes, setArchetypes] = useState<ArchetypesState>({});
  const [errorTabIndex, setErrorTabIndex] = useState<number[]>([]);
  const [showRegenerateModal, setShowRegenerateModal] = useState(false);
  const maxGenerateLimit = project.max_generated_data;
  const [activeTab, setActiveTab] = useState(0);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const { data, isLoading, refetch, isRefetching } = useKeyTouchPoint(
    project.slug,
  );
  const [totals, setTotals] = useState<{
    totalArchetype: number;
    totalArchetypeSelected: number;
    totalSelectedTouchPoint: number;
  }>({
    totalArchetype: 0,
    totalArchetypeSelected: 0,
    totalSelectedTouchPoint: 0,
  });
  const { mutate: generate, isPending: isGenerating } = useGenerate();
  const [totalRegenerate, setTotalRegenerate] = useState<number>(0);
  const [goToIndex, setGoToIndex] = useState<number | undefined>(undefined);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [showViewInfoModal, setShowViewInfoModal] = useState(false);
  const [progressStates, setProgressStates] = useState<Record<number, number>>(
    {},
  );
  const { mutate, isPending: isSubmitting } = useUpdateKeyTouchPoint();
  const [showAlertGenerateModal, setShowAlertGenerateModal] = useState(false);
  const [generatingStates, setGeneratingStates] = useState<
    Record<number, boolean>
  >({});
  // State per archetype
  const archetypesArray = useMemo(
    () =>
      Object.values(archetypes).filter(
        (archetype) => archetype.framework === selectedFramework,
      ),
    [archetypes, selectedFramework],
  );

  const [sources, setSources] = useState<SourcesType[]>([]);

  const { data: diffArch, refetch: refetchDiffArchetype } =
    useDifferentArchetypes(project.slug);

  const { isIdeaAlignment, isKeyTouchPoint, isIdeaExpansion } =
    useHistoryStatus(project, state.isEnabledHistoryStatus);

  const [activeCategoryTab, setActiveCategoryTab] = useState(0);
  const [selectedFunnel, setSelectedFunnel] = useState<string>('');

  const [routeOptionValue, setRouteOptionValue] = useState<RouteOption>({
    label: 'Next to Creative Idea',
    isActive: true,
    isGenerate: false,
    isInactive: true,
    isDisabled: false,
  });

  const activeArchetype = useMemo(() => {
    return (
      archetypesArray.find(
        (archetype) => archetype.id === archetypesArray[activeTab]?.id,
      ) || null
    );
  }, [archetypesArray, activeTab]);

  const contents = useMemo(() => {
    return (
      activeArchetype?.key_touch_points.find(
        (item) => item.funnel === selectedFunnel,
      )?.content || []
    );
  }, [activeArchetype?.key_touch_points, selectedFunnel]);

  const selectedIndexes = useMemo(() => {
    return contents
      ?.map((item, index) => (item.is_selected ? index : null))
      .filter((index) => index !== null);
  }, [contents]);

  useSectionDataHandler({
    diffArch,
    archetypes,
    data,
    progressStates,
    isIdeaExpansion,
    projectSlug: project.slug,
    sectionKey: sectionList.value,
    setArchetypes,
    setGeneratingStates,
    setProgressStates,
    setSources,
    setTotalRegenerate,
    setTotals,
    setActiveTab,
    setMessage,
  });

  useProgress({
    activeArchetype,
    generatingStates,
    progressStates,
    activeTab,
    projectSlug: project.slug,
    section: sectionList.value,
    refetch,
    setGeneratingStates,
    setArchetypes,
    setProgressStates,
    isQuerying: isKeyTouchPoint.isQuerying,
  });

  const onSelectSuccess = () => {
    setSelectedSuccess(true);
    refetch();
  };

  const toggleIsEditing = () => setIsEditing(!isEditing);
  const toggleIsAdding = () => setIsAdding(!isAdding);

  // const limit = activeArchetype?.total_regenerate || 0; TODO: will use this if 'generate more' is generated by archetype

  const handleFetchSubmit = async (
    data: KeyTouchPointSchema,
    id: number,
    index: number,
  ) => {
    const projectSlug = project.slug;
    const payload = {
      recommendation: {
        ...data,
      },
    } as RecommendationContent;

    mutate(
      {
        payload,
        id,
        archetypeId: activeArchetype?.id!,
        projectSlug,
        funnel: selectedFunnel,
      },
      {
        onSuccess: () => {
          triggerGTMEvent({
            event: `${id === -1 ? 'Add' : 'Update'} Key Touch Point`,
            eventCategory: `Button ${id === -1 ? 'Add' : 'Update'} Key Touch Point  Click`,
            eventAction: 'Click',
            eventLabel: 'Key Touch Point',
            userId: user.email,
            data: payload,
          });

          isAdding && toggleIsAdding();
          isEditing && toggleIsEditing();

          refetch();

          setSelectedSuccess(true);
          isAdding &&
            setTimeout(() => {
              setGoToIndex(index);
            }, 1000);
        },
      },
    );
  };

  const handleCancelEdit = () => {
    toggleIsEditing();

    setTimeout(() => {
      setGoToIndex(-1);
    }, 1000);
  };

  const handleCancelAdd = () => {
    if (!activeArchetype) return;

    setArchetypes((prev) => {
      const currentTouchPoints =
        prev[activeArchetype.id]?.key_touch_points || [];

      const updatedTouchPoints = currentTouchPoints.map((touchPoint) => {
        if (touchPoint.funnel === selectedFunnel) {
          return {
            ...touchPoint,
            content: touchPoint.content.filter((content) => content.id !== -1),
          };
        }
        return touchPoint;
      });

      return {
        ...prev,
        [activeArchetype.id]: {
          ...prev[activeArchetype.id],
          key_touch_points: updatedTouchPoints,
        },
      };
    });

    toggleIsAdding();

    // Atur kembali slider ke posisi awal
    setTimeout(() => {
      setGoToIndex(-1);
    }, 1000);
  };

  const handleSubmit = () => {
    cardRefs.current.forEach((ref, index) => {
      if (ref && index === activeIndex) {
        ref.submitForm();
      }
    });
  };

  const handleAddNew = () => {
    if (!activeArchetype) return;

    const newData: SectionKeyTouchPoint = {
      id: -1, // Gunakan ID sementara
      archetype_id: activeArchetype.id,
      is_edited: false,
      submission_id: -1, // Sesuaikan dengan tipe Anda
      history_id: -1, // Sesuaikan dengan tipe Anda
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      is_selected: false,
      is_manual: false,
      recommendation_number: 1,
      recommendation_content: {
        recommendation: {
          reason: '',
          media_types: '',
          idea_example: '',
          success_measurement: '',
          digital_recommendation: '',
        },
        recommendation_number: 1,
      },
    };

    // Hitung panjang array baru

    const newIndex = contents.length + 1;

    // Update state `archetypes` dengan data baru
    setArchetypes((prev) => {
      const updatedArchetypes = {
        ...prev,
        [activeArchetype.id]: {
          ...prev[activeArchetype.id],
          key_touch_points: prev[activeArchetype.id]?.key_touch_points?.map(
            (touchPoint) => {
              if (touchPoint.funnel === selectedFunnel) {
                return {
                  ...touchPoint,
                  content: [...touchPoint.content, newData],
                };
              }
              return touchPoint;
            },
          ),
        },
      };
      return updatedArchetypes;
    });

    // Setelah state diperbarui, pastikan slider berpindah ke item baru
    setGoToIndex(newIndex);
    toggleIsAdding();
  };

  const handleRegenerate = useCallback(() => {
    if (!activeArchetype) return;

    if (
      activeArchetype.has_regenerate_idea_expansions ||
      activeArchetype?.is_diff_key_touch_point
    ) {
      if (isIdeaAlignment.status && !isIdeaAlignment.isRegenerated) {
        const funnelSections: Record<string, string> = {
          credible: 'content/credible/objective',
          discoverable: 'content/discoverable/objective',
          searchable: 'content/searchable/objective',
        };

        ['searchability', 'credibility', 'discoverability'].forEach((section) =>
          removeAllArchetypesBasedOnSectionFromLocalStorage(
            project.slug,
            `${section}_goals_success_metrics_main_features`,
          ),
        );

        activeArchetype.key_touch_points
          .filter((item) => item.total_selected_touchpoint > 0)
          .forEach(({ funnel }) => {
            if (funnel in funnelSections) {
              generate({
                projectSlug: project.slug,
                section: funnelSections[funnel],
                payload: {
                  generate_more: '',
                  regenerate_prompt: '',
                  status: 'regenerate per archetype from key touch point',
                  archetype_id: [activeArchetype.id],
                },
              });
            }
          });
      }

      generate(
        {
          projectSlug: project.slug,
          section: 'idea-expansion',
          payload: {
            generate_more: '',
            regenerate_prompt: '',
            ...((activeArchetype.has_regenerate_idea_expansions ||
              activeArchetype?.is_diff_key_touch_point) && {
              status: 'regenerate per archetype from key touch point',
              archetype_id: [activeArchetype.id],
            }),
          },
        },
        {
          onSuccess: () => {
            setShowRegenerateModal(false);
            dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: false }));
          },
        },
      );
    }
  }, [
    activeArchetype,
    routeOptionValue,
    isIdeaAlignment.status,
    isIdeaAlignment.isRegenerated,
  ]);

  const handleRegenerateButton = async (prompt: string) => {
    if (!activeArchetype) return;

    removeAllArchetypesBasedOnSectionFromLocalStorage(
      project.slug,
      sectionList.value,
    );

    setArchetypes((prev) => {
      const updatedArchetypes = Object.keys(prev).reduce<
        Record<number, ArchetypeKeyTouchPoint>
      >((acc, key) => {
        const archetypeId = parseInt(key, 10);
        acc[archetypeId] = {
          ...prev[archetypeId],
          is_querying: true,
        };
        return acc;
      }, {});

      return updatedArchetypes;
    });

    triggerGTMEvent({
      event: `Generate More Key Touch Point`,
      eventCategory: `Generate More Key Touch Point Click`,
      eventAction: 'Click',
      eventLabel: 'Key Touch Point',
      userId: user.email,
      data: prompt,
    });

    generate(
      {
        payload: {
          generate_more: prompt,
          regenerate_prompt: '',
        },
        projectSlug: project.slug,
        section: 'key-touch-point',
      },
      {
        onSuccess: () => {
          refetch();
          dispatch(setEnableHistoryStatus(true));
        },
      },
    );
  };

  const handleRegenerateError = async () => {
    if (!activeArchetype) return;

    removeAllArchetypesBasedOnSectionFromLocalStorage(
      project.slug,
      sectionList.value,
    );

    setArchetypes((prev) => {
      const updatedArchetypes = Object.keys(prev).reduce<
        Record<number, ArchetypeKeyTouchPoint>
      >((acc, key) => {
        const archetypeId = parseInt(key, 10);
        acc[archetypeId] = {
          ...prev[archetypeId],
          is_querying: true,
        };
        return acc;
      }, {});

      return updatedArchetypes;
    });

    triggerGTMEvent({
      event: `Generate More Key Touch Point`,
      eventCategory: `Generate More Key Touch Point Click`,
      eventAction: 'Click',
      eventLabel: 'Key Touch Point',
      userId: user.email,
    });

    generate(
      {
        payload: {
          generate_more: '',
          regenerate_prompt: '',
        },
        projectSlug: project.slug,
        section: 'key-touch-point',
      },
      {
        onSuccess: () => {
          refetch();
          dispatch(setEnableHistoryStatus(true));
        },
      },
    );
  };

  useEffect(() => {
    dispatch(
      saveInformation(
        `${totals.totalSelectedTouchPoint} Touch points from ${totals.totalArchetype} Archetype are selected`,
      ),
    );
  }, [totals]);

  useEffect(() => {
    dispatch(saveInformation(''));
    dispatch(saveSubInformation(''));
    setSelectedFunnel('');
    const newFramework = Cookies.get('selected_framework') as SelectedFramework;
    setSelectedFramework(
      newFramework ?? project.submissions[0].selected_framework,
    );
  }, [project.submissions[0].selected_framework]);

  useEffect(() => {
    if (selectedFunnel === '' && activeArchetype?.key_touch_points.length) {
      const defaultVal = activeArchetype.key_touch_points[0];
      setSelectedFunnel(defaultVal.funnel);
    }
  }, [activeArchetype?.key_touch_points, selectedFunnel, selectedFramework]);

  useRoutePrev({
    id: 'btn-back-key-touch-point',
    label: 'Back',
    isActive: message !== 'No data found',
    onClick: () => navigate(`/${project.slug}/one-page-strategy`),
  });

  useEffect(() => {
    dispatch(setEnableHistoryStatus(!(isAdding || isEditing)));
  }, [isAdding, isEditing]);

  const handleSelectedFramework = (value: SelectedFramework) => {
    if (value === 'marketing_funnel') return 'Marketing Funnel';
    return 'S.D.R.C.P';
  };

  useEffect(() => {
    if (!archetypesArray.length) return;

    const funnel = archetypesArray[activeTab]?.key_touch_points?.[0]?.funnel;
    if (funnel) {
      setSelectedFunnel(funnel);
      setActiveCategoryTab(0);
    }
  }, [activeTab]);

  useEffect(() => {
    setGoToIndex(-1);
  }, [selectedFunnel, activeTab]);

  const handleTitleFunnel = (value: string): string => {
    if (!value) return '';

    return value
      .replace(/\bcredible\b/g, 'credibility')
      .replace(/\bpurchase\b/g, 'purchasability')
      .replace(/able\b/g, 'ability')
      .replace('_funnel', '')
      .replace(/_/g, ' ')
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  useCtaAction({
    isIdeaExpansion,
    isKeyTouchPoint,
    activeArchetype,
    archetypesArray,
    isEditing: isEditing || isAdding,
    project,
    routeOptionValue,
    totalSelected: totals.totalArchetypeSelected,
    isEditor,
    message,
    setErrorTabIndex,
    setRouteOption: setRouteOptionValue,
    setShowRegenerateModal,
  });

  const handleClick = () => {
    navigate(`/${project.slug}/one-page-strategy`);
  };

  useEffect(() => {
    const fetchData = async () => {
      await refetch();
    };
    fetchData();
  }, [
    isKeyTouchPoint.isQuerying,
    isKeyTouchPoint.isRegenerated,
    isIdeaExpansion.isQuerying,
  ]);

  useEffect(() => {
    selectedSuccess &&
      isIdeaExpansion.status &&
      !isIdeaAlignment.isRegenerated &&
      !isIdeaExpansion.isRegenerated &&
      setShowAlertGenerateModal(
        !(
          activeArchetype?.has_regenerate_idea_expansions ||
          activeArchetype?.is_diff_key_touch_point
        ),
      );

    setSelectedSuccess(false);
  }, [selectedSuccess, isIdeaAlignment, isIdeaExpansion, activeArchetype]);

  return (
    <>
      <div className="size-full">
        <div className="sticky top-56 z-20 flex flex-row items-center justify-between gap-16 bg-white py-16 md:flex-col">
          <div className="flex max-w-4xl grow flex-col">
            <h1 className="mb-8 text-25 font-bold leading-none">
              Key Touch Point{' '}
              {message !== 'No data found' && (
                <span>- {handleSelectedFramework(selectedFramework)}</span>
              )}
            </h1>
            <span className="text-1620 font-normal leading-relaxed text-grey-redx">
              Please select{' '}
              <span className="font-bold text-black-redx">one or more</span>{' '}
              touchpoint for{' '}
              <span className="font-bold text-black-redx">
                each archetype,{' '}
              </span>
              or add by clicking &apos;Add Touchpoint Manually&apos;.
            </span>
          </div>
          {!isKeyTouchPoint.isError &&
            activeArchetype &&
            isEditor &&
            !activeArchetype.is_querying &&
            message !== 'No data found' &&
            !activeArchetype.is_regenerate && (
              <RegenerateButton
                isGenerateMore
                isLoading={isLoading}
                limit={totalRegenerate} // TODO: change with 'limit', if 'generate more' is generated per archetype
                maxLimit={maxGenerateLimit}
                onSubmit={(form) => handleRegenerateButton(form.prompt)}
                section="key-touch-point"
              />
            )}
        </div>

        {!isKeyTouchPoint.isError &&
          activeArchetype &&
          message !== 'No data found' &&
          !activeArchetype.is_querying &&
          !activeArchetype.is_regenerate && (
            <SelectedFrameworkCard
              onChangeFramework={() => setModalFramework(true)}
              selectedFramework={handleSelectedFramework(selectedFramework)}
            />
          )}

        {(() => {
          if (message === 'No data found') {
            return (
              <NotDataFound
                buttonLabel="Go to Audience Archetype"
                description="You don't have selected archetype, click the button below to generate a archetype in Audience Archetype page."
                onClick={() => navigate(`/${project.slug}/audience-archetype`)}
                title="No Data Found."
              />
            );
          }

          if (!activeArchetype) {
            return null; // Jangan tampilkan apa-apa
          }

          return (
            <TabGroup
              className="my-24"
              onChange={setActiveTab}
              selectedIndex={activeTab}
            >
              <Tabs
                activeTab={activeTab}
                archetypesArray={archetypesArray}
                errorTabIndex={errorTabIndex}
                isEditing={isEditing || isAdding}
                isLoading={isLoading}
                isQuerying={isKeyTouchPoint.isQuerying}
                onActiveTab={setActiveTab}
                user={user}
              />
              {isLoading ? (
                <Skeleton />
              ) : (
                <div
                  key={activeArchetype?.id || activeTab}
                  className="h-full py-24"
                >
                  {(() => {
                    if (!activeArchetype) {
                      return null; // Jangan tampilkan apa-apa
                    }

                    if (
                      activeArchetype.is_querying &&
                      !isKeyTouchPoint.isError
                    ) {
                      return (
                        <GenerateLoading
                          estimateTimeTo={4}
                          progress={progressPortion({
                            progress: Number(
                              (progressStates[activeArchetype.id] || 0).toFixed(
                                0,
                              ),
                            ),
                            isQuerying: activeArchetype.is_querying,
                            isContentAvailable:
                              activeArchetype.key_touch_points.length > 0,
                          })}
                          project={project}
                          section={sectionList}
                          showEmailNotification={isKeyTouchPoint.isSendingEmail}
                        />
                      );
                    }

                    if (isKeyTouchPoint.isError) {
                      return (
                        <ErrorGenerate
                          onGenerate={() => handleRegenerateError()}
                        />
                      );
                    }

                    if (
                      (activeArchetype.is_regenerate ||
                        activeArchetype.key_touch_points.length === 0) &&
                      !activeArchetype.is_querying
                    ) {
                      return (
                        <NotDataFound
                          buttonLabel="Generate Key Touch Point"
                          description=" To show results on Key Touch Point, Click button below to generate Key touch point.."
                          onClick={handleClick}
                          title="You don’t have Key Touch Point..."
                        />
                      );
                    }

                    return (
                      <div className="flex flex-col gap-20">
                        <SelectedCard
                          data={getArchetypeDetails(
                            activeArchetype.archetype_content,
                          )}
                          title={`Archetype Detail - ${activeArchetype.archetype_content.archetype_name}`}
                        />

                        <TabGroup
                          className="inline-flex gap-24"
                          defaultIndex={activeCategoryTab}
                          onChange={setActiveCategoryTab}
                          selectedIndex={activeCategoryTab}
                        >
                          <TabListFunnel
                            archetypeName={
                              activeArchetype.archetype_content.archetype_name
                            }
                            funnels={activeArchetype.key_touch_points}
                            isEditing={isEditing || isAdding}
                            onHandleTitleFunnel={handleTitleFunnel}
                            onSetSelectedFunnel={setSelectedFunnel}
                            user={user}
                          />
                          <div className="flex w-10/12 flex-col gap-24">
                            <SliderContainer
                              goToIndex={goToIndex}
                              isAdding={isAdding}
                              isEditing={isEditing}
                              length={contents.length}
                              onActiveIndex={(index) => setActiveIndex(index)}
                              selectedIndexes={selectedIndexes}
                            >
                              {contents.map((keyTouchPoint, index) => (
                                <Card
                                  key={index}
                                  ref={(el) => (cardRefs.current[index] = el)}
                                  funnel={
                                    selectedFunnel === 'marketing_funnel'
                                      ? `${handleTitleFunnel(
                                          selectedFunnel,
                                        )} funnel`
                                      : handleTitleFunnel(selectedFunnel)
                                  }
                                  id={keyTouchPoint.id}
                                  index={index}
                                  isCanSelect={isEditor}
                                  isDisabledSelectButton={
                                    isIdeaAlignment.status &&
                                    isIdeaExpansion.status &&
                                    !isIdeaAlignment.isRegenerated &&
                                    !isIdeaExpansion.isRegenerated &&
                                    !diffArch?.is_different_archetype
                                      .idea_expansions &&
                                    !activeArchetype.has_regenerate_idea_expansions &&
                                    activeArchetype.total_selected_key_touch_points ===
                                      1
                                  }
                                  isEdited={
                                    keyTouchPoint.is_edited ||
                                    keyTouchPoint.is_manual
                                  }
                                  isEditing={isEditing || isAdding}
                                  isFetching={isRefetching}
                                  isSelected={!!keyTouchPoint.is_selected}
                                  item={keyTouchPoint.recommendation_content}
                                  onSelectSuccess={onSelectSuccess}
                                  onSubmitSuccess={handleFetchSubmit}
                                  project={project}
                                  refetchDiffArchetype={refetchDiffArchetype}
                                  totalData={contents.length}
                                  user={user}
                                />
                              ))}
                            </SliderContainer>

                            <ActionSection
                              isEditing={isAdding || isEditing}
                              isEditor={isEditor}
                              isSubmitting={isSubmitting}
                              label={`Add ${handleTitleFunnel(selectedFunnel)} Touchpoint Manually`}
                              onAdd={handleAddNew}
                              onCancel={
                                isAdding ? handleCancelAdd : handleCancelEdit
                              }
                              onSave={handleSubmit}
                              toggleEditing={toggleIsEditing}
                            />
                            {sources && <SourcesList sources={sources} />}
                          </div>
                        </TabGroup>
                      </div>
                    );
                  })()}
                </div>
              )}
            </TabGroup>
          );
        })()}
      </div>
      <SelectingFunnel
        defaultSelected={selectedFramework}
        isOpen={modalFramework}
        onClose={() => setModalFramework(false)}
        onRefetch={refetch}
        project={project}
        user={user}
      />
      <RegenerateInfoModal
        isOpen={showAlertGenerateModal}
        onClose={() => {
          setShowAlertGenerateModal(false);
        }}
        section="key-touch-point"
      />

      <ChangingImpactModal
        handleSubmit={handleRegenerate}
        isOpen={showRegenerateModal}
        isRegenerating={isGenerating}
        onClose={() => {
          setShowRegenerateModal(false);
          dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: false }));
        }}
        section="key-touch-point"
        visibleSections={[
          'Idea Expansion',
          'Searchability Content',
          'Discoverability Content',
          'Credibility Content',
        ]}
      />

      <ViewInfoModal
        isOpen={showViewInfoModal}
        onClose={() => setShowViewInfoModal(false)}
      />
    </>
  );
};

export default Index;
