import { useUserContext } from '@/Hooks/Administrator/useUserContext';
import { cn } from '@/Utils/cn';

const HeaderRequestLists = () => {
  const { activeFilter, handleActiveFilter } = useUserContext();
  return (
    <div className="inline-flex h-24 items-center gap-16">
      <p className="text-14 font-semibold leading-16 text-black-redx">
        Status:
      </p>
      <ul className="inline-flex items-center gap-16">
        {['active', 'disabled'].map((status) => (
          <li key={status}>
            <button
              className={cn(
                'text-14 font-normal leading-16 text-black-redx transition-all duration-300',
                activeFilter === status &&
                  'rounded-16 bg-soft-purplestroke-redx px-12 py-6 font-semibold text-blue-purple-redx',
              )}
              onClick={() => handleActiveFilter(status)}
            >
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default HeaderRequestLists;
