import { z } from 'zod';

export const IdeaPersonalization = z.object({
  idea_title: z.string().min(2, 'Idea Title is required'),
  idea_description: z.string().min(2, 'Idea Description is required'),
  reason_product_brand: z.string().min(2, 'Reason Product Brand is required'),
  reason_target_audience: z
    .string()
    .min(2, 'Reason Target Audience is required'),
});

export type IdeaPersonalizationSchema = z.infer<typeof IdeaPersonalization>;
