import { z } from 'zod';

export const CompetitorAnalysis = z.object({
  competitor_id: z.number().optional(),
  brand_name: z.string().min(2, 'Brand name is required'),
  product_name: z.string().min(2, 'Product name is required'),
  communication_angle: z.string().min(2, 'Communication angle is required'),
  communication_idea: z.string().min(2, 'Communication idea is required'),
  communication_tone: z.string().min(2, 'Communication tone is required'),
  features_benefits: z.union([
    z
      .string()
      .min(2, 'Features and benefits must be at least 4 characters long'),
    z.array(
      z
        .string()
        .min(2, 'Each feature or benefit must be at least 4 characters long'),
    ),
  ]),
  key_touch_points: z.union([
    z.string().min(2, 'Key touch points must be at least 4 characters long'),
    z.array(
      z
        .string()
        .min(2, 'Each feature or benefit must be at least 4 characters long'),
    ),
  ]),
  opportunities: z.union([
    z.string().min(2, 'Opportunities must be at least 4 characters long'),
    z.array(
      z
        .string()
        .min(2, 'Each feature or benefit must be at least 4 characters long'),
    ),
  ]),
});

export type CompetitorAnalysisSchema = z.infer<typeof CompetitorAnalysis>;
