import { Tab, TabList } from '@headlessui/react';
import { Icon } from '@iconify/react';
import React, { useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import type {
  ArchetypeKeyInsight,
  SectionKeyInsight,
} from '@/Types/KeyInsight';
import type { UserResponse } from '@/Types/user';
import { cn } from '@/Utils/cn';
import { triggerGTMEvent } from '@/Utils/gtm';

interface TabsProps {
  user: UserResponse;
  archetypesArray: ArchetypeKeyInsight[];
  activeTab: number;
  isQuerying: boolean;
  isLoading: boolean;
  isEditing: boolean;
  errorTabIndex?: number[];
  onActiveTab: (index: number) => void;
}

const Tabs: React.FC<TabsProps> = ({
  archetypesArray,
  user,
  activeTab,
  isQuerying,
  isLoading = true,
  isEditing = false,
  errorTabIndex = [],
  onActiveTab,
}) => {
  const [hoveredData, setHoveredData] = useState<{
    index: number | null;
    isRegenerate: boolean;
  }>({ index: null, isRegenerate: false });

  const [popupPosition, setPopupPosition] = useState<{
    top: number;
    left: number;
  }>({ top: 0, left: 0 });

  const tabsRef = useRef<HTMLElement[]>([]);

  const handleMouseEnter = (index: number) => {
    const tabElement = tabsRef.current[index];
    if (tabElement) {
      const rect = tabElement.getBoundingClientRect();
      setPopupPosition({
        top: rect.bottom + window.scrollY + 8, // Adjust popup position below the tab
        left: rect.left + rect.width / 2 + window.scrollX, // Center horizontally
      });
      setHoveredData({
        index,
        isRegenerate:
          archetypesArray[index]?.is_regenerate ||
          (archetypesArray[index]?.key_insights.length === 0 && !isQuerying) ||
          false,
      });
    }
  };

  const handleMouseLeave = () => {
    setHoveredData({ index: null, isRegenerate: false });
  };

  const handlePrev = () => {
    if (activeTab > 0) {
      onActiveTab(activeTab - 1);
    }
  };

  const handleNext = () => {
    if (activeTab < archetypesArray.length - 1) {
      onActiveTab(activeTab + 1);
    }
  };

  const handleTabClicked = (
    title: string,
    keyInsights: SectionKeyInsight[],
  ) => {
    triggerGTMEvent({
      event: `Tab Key Insight`,
      eventCategory: `Tab Key Insight Click`,
      eventAction: 'Click',
      eventLabel: 'Key Insight',
      userId: user.email,
      data: { title, keyInsights },
    });
  };

  return (
    <div className="flex items-center">
      {archetypesArray.length > 3 && (
        <button
          className="p-2 text-gray-500 hover:text-black"
          disabled={activeTab === 0}
          onClick={handlePrev}
        >
          <Icon className="text-25" icon="mingcute:left-line" />
        </button>
      )}
      <TabList className="border-grey-200 white-space-nowrap flex w-full gap-24 overflow-x-auto overflow-y-hidden border-b-1 scrollbar-hide">
        {!isLoading
          ? archetypesArray.map((section, index) => (
              <div
                key={index}
                ref={(el) => {
                  if (el) tabsRef.current[index] = el;
                }}
                className="relative w-full text-center text-15"
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                <Tab
                  className={cn(
                    'data-[selected]:border-b-3 w-full pb-10 data-[selected]:border-blue-redx data-[selected]:font-bold data-[selected]:text-blue-redx data-[focus]:outline-0 data-[focus]:outline-transparent [&:has(:focus-visible)]:ring-0',
                    isEditing ||
                      section.is_regenerate ||
                      (section.key_insights.length === 0 && !isQuerying)
                      ? 'cursor-not-allowed text-placeholder-redx'
                      : 'cursor-pointer text-black-redx',
                  )}
                  disabled={
                    isEditing ||
                    section.is_regenerate ||
                    (section.key_insights.length === 0 && !isQuerying)
                  }
                  onClick={(e) => {
                    if (section.is_regenerate || isEditing) {
                      e.preventDefault();
                      return;
                    }
                    handleTabClicked(
                      section.archetype_content.archetype_name,
                      section.key_insights,
                    );
                  }}
                >
                  <div className="-mb-px text-sm inline-flex h-78 w-full items-center justify-center gap-8 py-2 font-medium focus:outline-none">
                    <div className="flex flex-col gap-8">
                      {`Archetype ${index + 1}`}
                      <div
                        className={cn(
                          'rounded-8 bg-soft-grey-redx px-8 py-4 font-semibold whitespace-nowrap',
                          isEditing || section.is_regenerate
                            ? 'cursor-not-allowed text-grey-redx'
                            : 'cursor-pointer text-black-redx',
                        )}
                      >
                        {section.archetype_content.archetype_name}
                      </div>
                    </div>
                    {errorTabIndex?.includes(index) && (
                      <Icon
                        className="text-25 text-error-redx"
                        icon="mingcute:information-line"
                      />
                    )}
                  </div>
                </Tab>
              </div>
            ))
          : Array.from({ length: 3 }).map((_, index) => (
              <button
                key={`dummy-${index}`}
                className="-mb-px text-sm flex h-78 w-full flex-col items-center justify-center gap-8 py-2 font-medium text-gray-600 hover:text-indigo-600 focus:outline-none"
              >
                <div className="h-20 w-155 animate-pulse rounded-full bg-soft-purple-redx" />
              </button>
            ))}
      </TabList>
      {hoveredData.index !== null &&
        hoveredData.isRegenerate &&
        createPortal(
          <div
            className="absolute z-50 max-w-250 rounded-8 bg-white px-15 py-8 text-center text-15"
            style={{
              top: `${popupPosition.top - 20}px`,
              left: `${popupPosition.left}px`,
              transform: 'translateX(-50%)',
              boxShadow: '0px 0px 12px 0px #00000026',
            }}
          >
            Please generate the key insight for this archetype first
          </div>,
          document.body,
        )}
      {archetypesArray.length > 3 && (
        <button
          className="p-2 text-gray-500 hover:text-black"
          disabled={activeTab === archetypesArray.length - 1}
          onClick={handleNext}
        >
          <Icon className="text-25" icon="mingcute:right-line" />
        </button>
      )}
    </div>
  );
};

export default Tabs;
