import { useContext } from 'react';

import { RequestListContext } from '@/Pages/Administrator/Context/request-list-context';

export const useRequestUserContext = () => {
  const context = useContext(RequestListContext);
  if (!context)
    throw new Error(
      'useRequestUserContext must be used within a RequestListProvider',
    );
  return context;
};
