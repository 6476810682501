import { useMutation } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { RequestAccessResponse } from '@/Types/Login/login';

export interface FormDataAccessRequest {
  email: string;
  password: string;
  reason: string;
}

const submitRequestAccess = async ({
  email,
  password,
  reason,
}: FormDataAccessRequest) => {
  try {
    const { data } = await goFetcher.post('/auth/request-access', {
      email,
      password,
      reason,
    });
    return data as RequestAccessResponse;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useRequestAccess = () => {
  return useMutation<RequestAccessResponse, Error, FormDataAccessRequest>({
    mutationFn: submitRequestAccess,
  });
};

export { useRequestAccess };
