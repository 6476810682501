import { memo } from 'react';

import MarkdownView from '@/Components/MardownView';

interface ContentProps {
  value: string | string[] | number | boolean;
  isFetching?: boolean;
}

const Content = ({ value, isFetching = false }: ContentProps) => {
  if (isFetching) {
    return (
      <div className="flex flex-col gap-10">
        <div className="h-10 w-full animate-pulse rounded-full bg-soft-purple-redx" />
      </div>
    );
  }

  if (Array.isArray(value)) {
    return (
      <ul className="ms-20 list-disc">
        {value.map((item, index) => (
          <li
            key={index}
            className="text-14 font-normal leading-20 text-black-redx"
          >
            <MarkdownView content={item} />
          </li>
        ))}
      </ul>
    );
  }

  return (
    <div className="text-14 font-normal leading-20 text-black-redx">
      <MarkdownView content={value.toString()} />
    </div>
  );
};

export default memo(Content);
