import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from '@headlessui/react';
import { Icon } from '@iconify/react/dist/iconify.js';
import { useContext, useMemo } from 'react';

import ResponsiveNavLink from '@/Components/ResponsiveNavLink';
import { ProjectContext } from '@/Context/ProjectContext';
import { cn } from '@/Utils/cn';

interface ProfileProps {
  avatar: string;
}

const popOverMenu = [
  {
    name: 'Administrator',
    href: '/administrator',
    icon: 'lucide:user-round-cog',
  },
  {
    name: 'Logout',
    href: '/logout',
    icon: 'lucide:log-out',
  },
];

const NotificationDot = ({ className = '' }: { className?: string }) => {
  const totalOutstanding = 0;
  if (totalOutstanding === 0) return null;

  return (
    <div
      className={cn(`size-8 rounded-full bg-button-gradient-start`, className)}
    />
  );
};

const Profile = ({ avatar }: ProfileProps) => {
  const [state] = useContext(ProjectContext);
  const user = useMemo(() => state.user, [state.user]);

  return (
    <Popover>
      <PopoverButton className="relative flex cursor-pointer items-center rounded-full bg-white p-3">
        <div className=" size-30 overflow-hidden rounded-full">
          {avatar && <img alt="User avatar" src={avatar} />}
        </div>
        <NotificationDot className="absolute -right-5 -top-5" />
      </PopoverButton>
      <Transition
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <PopoverPanel className="divide-y absolute right-20 z-60 mt-5 min-w-280 rounded-lg bg-white p-16 text-14 font-semibold leading-20 shadow-lg [--anchor-offset:5px]">
          {popOverMenu.map((item) => (
            <div key={item.name} className="flex flex-col">
              {item.name === 'Administrator' ? (
                user &&
                user?.role?.name === 'admin' && (
                  <>
                    <ResponsiveNavLink
                      className="!hover:bg-transparent inline-flex cursor-pointer items-center justify-between"
                      to={item.href}
                    >
                      <div className="flex items-center gap-8">
                        <span>{item.name}</span>
                        {item.name === 'Administrator' && <NotificationDot />}
                      </div>
                      <Icon className="size-16" icon={item.icon} />
                    </ResponsiveNavLink>
                    <hr className="border-t my-7 border-stroke-redx" />
                  </>
                )
              ) : (
                <ResponsiveNavLink
                  className="!hover:bg-transparent inline-flex cursor-pointer items-center justify-between"
                  to={item.href}
                >
                  <div className="flex items-center gap-8">
                    <span>{item.name}</span>
                  </div>
                  <Icon className="size-16" icon={item.icon} />
                </ResponsiveNavLink>
              )}
            </div>
          ))}
        </PopoverPanel>
      </Transition>
    </Popover>
  );
};

export default Profile;
