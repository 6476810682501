import { useInfiniteQuery } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { RequestListResponse } from '@/Types/Administrator';

interface UserRequestListProps {
  pageParam: number;
  search: string;
  sort: string;
  activeFilter: string;
  date: number;
}

const getLists = async ({
  pageParam,
  search,
  sort,
  activeFilter,
}: UserRequestListProps) => {
  try {
    const params = new URLSearchParams({
      next: String(pageParam),
    });
    if (search) params.append('search', search);
    if (sort) params.append('sort', sort);

    const { data } = await goFetcher.get(
      `/administrators/request-lists/${activeFilter}?limit=10&${params.toString()}`,
    );

    return data as RequestListResponse;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'An error occurred');
  }
};

const useRequestLists = ({
  search = '',
  date,
  sort,
  activeFilter,
}: UserRequestListProps) => {
  return useInfiniteQuery({
    queryKey: ['userRequestList', search, date, activeFilter, sort],
    queryFn: ({ pageParam = 0 }) =>
      getLists({ pageParam, search, date, activeFilter, sort }),
    getNextPageParam: (lastPage) => lastPage.next ?? false,
    initialPageParam: 0,
  });
};

export { useRequestLists };
