import { zodResolver } from '@hookform/resolvers/zod';
import { Icon } from '@iconify/react/dist/iconify.js';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import InputLabel from '@/Components/InputLabel';
import ModalCenter from '@/Components/Modals/Center';
import TextInput from '@/Components/TextInput';
import { useSubmitRole } from '@/Hooks/Administrator';
import { useRoleContext } from '@/Hooks/Administrator/useRoleContext';
import type { AdministratorRoleResponse } from '@/Types/Administrator';
import { Role, type RoleSchema } from '@/Types/Administrator/schema';
import { cn } from '@/Utils/cn';

import SuccessUpdateAccessToast from './SuccessUpdateAccessToast';

interface FormModalProps {
  selectedRole: AdministratorRoleResponse | null;
  title: string;
  isOpen: boolean;
  onClose: () => void;
}

const fieldsToDisplay = [
  { name: 'name', label: 'Role Name', placeholder: 'Input the role name here' },
  {
    name: 'display_name',
    label: 'Display Role Name',
    placeholder: 'Input the display role name here',
  },
];

const FormModal: React.FC<FormModalProps> = ({
  selectedRole,
  title,
  isOpen,
  onClose,
}) => {
  const {
    handleRefetch: refetch,
    handleUpdateStatus,
    handleDataForm,
  } = useRoleContext();
  const { mutate, isPending } = useSubmitRole();
  const handleClose = () => {
    onClose();
  };

  const { handleSubmit, control, reset, watch } = useForm<RoleSchema>({
    resolver: zodResolver(Role),
    defaultValues: {
      name: '',
      display_name: '',
    },
  });

  const selectedName = watch('name');

  const onSubmit = (dataForm: RoleSchema) => {
    if (selectedRole?.id) {
      handleDataForm(dataForm.name, dataForm.display_name);
      handleUpdateStatus(selectedRole?.id, 'confirm_edit');

      return;
    }

    mutate(
      {
        ...(selectedRole?.id && { id: selectedRole.id }),
        name: dataForm.name,
        displayName: dataForm.display_name,
      },
      {
        onSuccess: () => {
          if (selectedRole) {
            toast.custom((t) => (
              <SuccessUpdateAccessToast
                message="Role information has been successfully updated."
                t={t}
              />
            ));
          } else {
            toast.custom((t) => (
              <SuccessUpdateAccessToast
                message="Account role has been created successfully.."
                t={t}
              />
            ));
          }
          handleUpdateStatus(0, '');
          refetch(true);
        },
      },
    );
  };

  useEffect(() => {
    if (!isOpen) {
      reset({
        display_name: selectedRole?.display_name ?? '',
        name: selectedRole?.name ?? '',
      });
    }
  }, [isOpen, selectedRole]);

  return (
    <ModalCenter isOpen={isOpen} onClose={handleClose}>
      <div className="relative w-886 p-35 md:w-full">
        <div className="inline-flex items-center gap-8">
          {selectedRole && (
            <Icon
              className="size-24 cursor-pointer text-blue-redx"
              icon="lucide:arrow-left"
              onClick={() => handleUpdateStatus(selectedRole.id, 'detail')}
            />
          )}
          <h1 className="text-24 font-semibold leading-32 text-black-redx md:text-18">
            {title}
          </h1>
        </div>

        <div className="mt-24 grid grid-cols-2 gap-24 md:grid-cols-1">
          {fieldsToDisplay.map((field) => (
            <div key={field.name}>
              <InputLabel
                disabled={field.name === 'name' && selectedRole !== null}
                value={field.label}
              />

              <Controller
                control={control}
                name={field.name as keyof RoleSchema}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <TextInput
                      autoComplete="off"
                      className="overflow-custom mt-1 block w-full resize-none !text-14"
                      containerClassName={cn(
                        'border-b-2 border-soft-purplestroke-redx text-black-redx',
                      )}
                      disabled={field.name === 'name' && selectedRole !== null}
                      error={error?.message}
                      onChange={onChange}
                      placeholder={field.placeholder}
                      rows={3}
                      value={value}
                    />
                  );
                }}
              />
            </div>
          ))}
        </div>

        <div className="mt-30 flex items-center justify-end gap-16 gap-x-10 md:flex-col">
          <button
            className="rounded-8 border-1 border-blue-redx px-16 py-8 text-blue-redx md:w-full"
            onClick={handleClose}
          >
            Close
          </button>
          <button
            className={cn(
              'rounded-8  border-1  px-16 py-8 text-white md:w-full',
              isPending || !selectedName
                ? 'opacity-50 cursor-not-allowed bg-placeholder-redx'
                : 'cursor-pointer bg-blue-purple-redx',
            )}
            disabled={isPending || !selectedName}
            onClick={() => handleSubmit(onSubmit)()}
          >
            {selectedRole ? 'Update' : 'Add Role'}
          </button>
        </div>
      </div>
    </ModalCenter>
  );
};

export default FormModal;
