import { useMutation } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { AdministratorUserResponse } from '@/Types/Administrator';

interface SubmitRoleProps {
  name: string;
  id?: AdministratorUserResponse['id'];
  displayName: string;
}

const updateStatus = async ({ id, displayName, name }: SubmitRoleProps) => {
  try {
    if (id) {
      const url = `/administrators/role-lists/update/${id}`;
      await goFetcher.put(url, {
        display_name: displayName,
        name,
      });
      return;
    }

    const url = `/administrators/role-lists/submit`;
    await goFetcher.post(url, {
      display_name: displayName,
      name,
    });
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useSubmitRole = () => {
  return useMutation<any, Error, SubmitRoleProps>({
    mutationFn: updateStatus,
  });
};

export { useSubmitRole };
