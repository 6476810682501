import { useMutation } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { ArchetypeResponse } from '@/Types/AudienceArchetype';
import type { Schema } from '@/Types/AudienceArchetype/schema';

interface UpdateArchetypeParams {
  payload: Schema;
  id: number;
  isEdited: boolean;
  projectSlug: string;
}

const updateArchetype = async ({
  payload,
  id,
  projectSlug,
  isEdited,
}: UpdateArchetypeParams) => {
  try {
    if (id === -1) {
      const url = `/projects/${projectSlug}/archetype/submit`;
      const { data } = await goFetcher.post(url, {
        content: payload,
        is_edited: isEdited,
      });
      return data as ArchetypeResponse;
    }

    const url = `/projects/${projectSlug}/archetype/update/${id}`;
    const { data } = await goFetcher.put(url, {
      content: payload,
      is_edited: isEdited,
    });

    return data as ArchetypeResponse;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useUpdateArchetype = () => {
  return useMutation<ArchetypeResponse, Error, UpdateArchetypeParams>({
    mutationFn: updateArchetype,
  });
};

export { useUpdateArchetype };
