import { UserListProvider } from '../../Context/user-list-provider';
import CtaActions from './CtaAction';
import FilterAndSort from './FilterAndSort';
import HeaderRequestLists from './Header';
import TableRequestList from './Table';

const TabUserList = () => {
  return (
    <UserListProvider>
      <div className="relative flex flex-col gap-24">
        <HeaderRequestLists />
        <FilterAndSort />
        <TableRequestList />
      </div>
      <CtaActions />
    </UserListProvider>
  );
};
export default TabUserList;
