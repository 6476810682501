import { zodResolver } from '@hookform/resolvers/zod';
import {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { Controller, useForm } from 'react-hook-form';

import CardTitle from '@/Components/CardTitle';
import Content from '@/Components/Content';
import Label from '@/Components/Label';
import SelectButton from '@/Components/Projects/SliderCard/SelectButton';
import TextAreaInput from '@/Components/TextAreaInput';
import { useUpdateSelectedOpportunity } from '@/Hooks/Opportunity';
import type { OpportunitiesContentData } from '@/Types/OpportunityAmongCompetition';
import {
  OpportunitySchema,
  type OpportunitySchemaType,
} from '@/Types/OpportunityAmongCompetition/schema';
import type { ProjectProps } from '@/Types/Projects';
import type { UserResponse } from '@/Types/user';
import { triggerGTMEvent } from '@/Utils/gtm';

import Container from './Container';

export interface CardHandles {
  submitForm: () => void;
}

const fieldsToDisplay = [
  { name: 'brand_persona', label: 'Brand Persona' },
  {
    name: 'creative_communication_angle',
    label: 'Creative Communication Angle',
  },
  {
    name: 'features_and_benefits',
    label: 'Features & Benefits',
  },
  {
    name: 'digital_key_touch_points',
    label: 'Digital Key Touch Points',
  },
];

const Card = forwardRef<
  CardHandles,
  {
    isDisabledSelectButton?: boolean;
    isEdited?: boolean;
    user?: UserResponse;
    id?: number;
    project?: ProjectProps;
    totalData?: number;
    isFetching?: boolean;
    item?: OpportunitiesContentData;
    index?: number;
    isEditing?: boolean;
    isSelected?: boolean;
    isCanSelect?: boolean;
    onSubmitSuccess?: (
      data: OpportunitySchemaType,
      id: number,
      index: number,
    ) => void;
    onSelectSuccess?: (isEdited: boolean) => void;
    refetchDiffArchetype?: () => void;
  }
>(
  (
    {
      user,
      id,
      project,
      totalData,
      item,
      isDisabledSelectButton = false,
      isEdited = false,
      isFetching = true,
      index = 0,
      isSelected = false,
      isEditing,
      isCanSelect = false,
      onSubmitSuccess,
      onSelectSuccess,
      refetchDiffArchetype,
    },
    ref,
  ) => {
    const brandPersonaRef = useRef<HTMLTextAreaElement | null>(null);
    const { mutate: mutateSelected, isPending: isUpdatingSelected } =
      useUpdateSelectedOpportunity();

    const {
      handleSubmit,
      reset,
      control,
      formState: { errors },
    } = useForm<OpportunitySchemaType>({
      resolver: zodResolver(OpportunitySchema),
      defaultValues: {
        brand_persona: item?.brand_persona ?? '',
        creative_communication_angle: item?.creative_communication_angle ?? '',
        digital_key_touch_points: item?.digital_key_touch_points ?? '',
        features_and_benefits: item?.features_and_benefits ?? '',
      },
      mode: 'all',
    });

    const onSubmit = (data: OpportunitySchemaType) => {
      if (
        id === undefined ||
        index === undefined ||
        onSubmitSuccess === undefined
      )
        return;

      onSubmitSuccess(data, id, index);
    };

    useEffect(() => {
      if (isEditing && item) {
        reset({
          brand_persona: item.brand_persona ?? '',
          creative_communication_angle: item.creative_communication_angle ?? '',
          digital_key_touch_points: item.digital_key_touch_points ?? '',
          features_and_benefits: item.features_and_benefits ?? '',
        });
      }
    }, [isEditing, reset, item]);

    useImperativeHandle(
      ref,
      () => ({
        submitForm: () => handleSubmit(onSubmit)(),
      }),
      [handleSubmit, onSubmit],
    );

    const handleSelected = (id: number) => {
      if (!project || !user || !item || !onSelectSuccess) return;
      const projectSlug = project.slug;

      triggerGTMEvent({
        event: `Select Opportunity Among Competition`,
        eventCategory: `Button Select Opportunity Among Competition Click`,
        eventAction: 'Click',
        eventLabel: 'Opportunity Among Competition',
        userId: user.email,
        data: { type: isSelected ? 'Unselect' : 'Select', ...item },
      });

      mutateSelected(
        { id, projectSlug },
        {
          onSuccess: ({ data }) => {
            onSelectSuccess(data.is_selected && data.is_edited);
            refetchDiffArchetype?.();
          },
        },
      );
    };

    return (
      <div className="flex w-full flex-col pb-35 transition duration-500">
        <div className="px-35 py-15 text-center">
          {isFetching ? (
            <h1 className="mx-auto mb-14 h-20 w-3/12 animate-pulse rounded-full bg-soft-purple-redx" />
          ) : (
            <CardTitle
              id={id ?? 1}
              index={index}
              isEdited={isEdited}
              label="Opportunity"
              totalData={totalData ?? 1}
            />
          )}
        </div>
        <div
          className={`grid h-full min-h-350 grid-cols-2 gap-24 p-24 text-black-redx md:grid-cols-1 ${
            isEditing && isSelected ? '!bg-transparent' : ''
          } ${
            isSelected
              ? 'border-t-2 border-blue-redx bg-softer-purple-redx'
              : 'border-t-1 border-stroke-redx'
          }`}
        >
          {fieldsToDisplay.map(({ name, label }) => (
            <Container key={name}>
              <Label value={label} />
              {isEditing ? (
                <Controller
                  control={control}
                  name={name as keyof OpportunitySchemaType}
                  render={({ field }) => (
                    <TextAreaInput
                      {...field}
                      ref={
                        name === 'brand_persona' ? brandPersonaRef : undefined
                      }
                      autoComplete="off"
                      className="overflow-custom mt-1 block w-full resize-none !text-14"
                      containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                      error={
                        errors[name as keyof OpportunitySchemaType]?.message
                      }
                      placeholder={`Input the ${label.toLowerCase()} here`}
                    />
                  )}
                />
              ) : (
                <Content
                  isFetching={isFetching}
                  value={item?.[name as keyof OpportunitySchemaType] || ''}
                />
              )}
            </Container>
          ))}
        </div>
        <div className="flex w-full justify-end px-1 pt-15">
          {id &&
          id !== undefined &&
          id !== -1 &&
          isCanSelect &&
          index !== undefined ? (
            <SelectButton
              hasOne={isDisabledSelectButton && isSelected}
              index={index}
              isEditing={isEditing ?? false}
              isLoading={isUpdatingSelected}
              isSelected={isSelected ?? false}
              onSelect={() => handleSelected(id)}
              section="opportunity-among-competition"
            />
          ) : null}
        </div>
      </div>
    );
  },
);

export default memo(Card);
