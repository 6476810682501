import { Button } from '@headlessui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import InputDropdown from '@/Components/InputDropdown';
import InputLabel from '@/Components/InputLabel';
import ModalCenter from '@/Components/Modals/Center';
import TextInput from '@/Components/TextInput';
import { useRedHubUserOption, useSubmitUser } from '@/Hooks/Administrator';
import { useUserContext } from '@/Hooks/Administrator/useUserContext';
import type { AdministratorUserResponse } from '@/Types/Administrator';
import { AddUser, type AddUserSchema } from '@/Types/Administrator/schema';
import { cn } from '@/Utils/cn';

import SuccessUpdateAccessToast from './SuccessUpdateAccessToast';

interface AddNewModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const fieldsToDisplay = [
  { name: 'name', label: 'Name', placeholder: 'Choose name here' },
  {
    name: 'email',
    label: 'Redcomm Email',
    placeholder: 'Employee email appears here',
  },
  {
    name: 'reason',
    label: 'Reason',
    placeholder: 'Input the reason here',
  },
];

const AddNewModal: React.FC<AddNewModalProps> = ({ isOpen, onClose }) => {
  const { handleRefetch: refetch } = useUserContext();
  const { mutate, isPending } = useSubmitUser();
  const { data, refetch: refetchUserOption } = useRedHubUserOption({
    isAdmin: false,
  });
  const handleClose = () => {
    onClose();
  };

  const { handleSubmit, control, reset, watch, setValue } =
    useForm<AddUserSchema>({
      resolver: zodResolver(AddUser),
      defaultValues: {
        email: '',
        name: 0,
        reason: '',
      },
    });

  const selectedName = watch('name');

  const onSubmit = (dataForm: AddUserSchema) => {
    const selectedRedHubUser = data?.data.find(
      (item) => item.email === dataForm.email,
    );
    if (selectedRedHubUser) {
      mutate(
        {
          avatar: selectedRedHubUser.avatar,
          email: dataForm.email,
          name: selectedRedHubUser.name,
          reason: dataForm?.reason ?? '',
          redHubId: selectedRedHubUser.id,
          whatsAppNumber: selectedRedHubUser.whatsapp_number,
        },
        {
          onSuccess: () => {
            toast.custom((t) => (
              <SuccessUpdateAccessToast
                message={`Invitation to <b>${selectedRedHubUser.name}</b> successfully sent.`}
                t={t}
              />
            ));
            refetch(true);
            handleClose();
          },
        },
      );
    }
  };

  useEffect(() => {
    if (!isOpen) {
      reset({
        email: '',
        name: 0,
        reason: '',
      });
    } else {
      refetchUserOption();
    }
  }, [isOpen]);

  const handleOnChangeUser = (id: AdministratorUserResponse['id']) => {
    const selectedRedHubUser = data?.data.find((item) => item.id === id);
    if (selectedRedHubUser) {
      setValue('email', selectedRedHubUser.email);
    }
  };

  return (
    <ModalCenter isOpen={isOpen} onClose={handleClose}>
      <div className="relative w-886 p-35 md:w-full">
        <h1 className="text-24 font-semibold leading-32 text-black-redx md:text-18">
          Add new internal user
        </h1>

        <div className="mt-24 grid grid-cols-2 gap-24 md:grid-cols-1">
          {fieldsToDisplay.map((field) => (
            <div key={field.name}>
              <InputLabel
                disabled={field.name === 'email'}
                value={field.label}
              />

              <Controller
                control={control}
                name={field.name as keyof AddUserSchema}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  if (field.name === 'name') {
                    return (
                      <InputDropdown
                        containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                        disabled={false}
                        error={error?.message}
                        list={
                          data?.data.map((item) => {
                            return {
                              title: item.name,
                              value: item.id,
                            };
                          }) ?? []
                        }
                        modelValue={value ?? ''}
                        multiple={false}
                        onChange={(value) => {
                          handleOnChangeUser(+value);
                          onChange(value);
                        }}
                        placeholder={field.placeholder}
                        withFilter
                      />
                    );
                  }
                  return (
                    <TextInput
                      autoComplete="off"
                      className="overflow-custom mt-1 block w-full resize-none !text-14"
                      containerClassName={cn(
                        'border-b-2 border-soft-purplestroke-redx text-black-redx',
                        field.name === 'email' &&
                          '!border-b-1 !border-placeholder-redx',
                      )}
                      disabled={field.name === 'email'}
                      error={error?.message}
                      onChange={onChange}
                      placeholder={field.placeholder}
                      rows={3}
                      value={value}
                    />
                  );
                }}
              />
            </div>
          ))}
        </div>

        <div className="mt-24 flex items-center justify-end gap-16 gap-x-10 md:flex-col">
          <Button
            className="rounded-8 border-1 border-blue-redx px-16 py-8 text-blue-redx md:w-full"
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            className={cn(
              'rounded-8  border-1  px-16 py-8 text-white md:w-full',
              selectedName === 0
                ? 'opacity-50 cursor-not-allowed bg-placeholder-redx'
                : 'cursor-pointer bg-blue-purple-redx',
            )}
            disabled={isPending || selectedName === 0}
            onClick={() => handleSubmit(onSubmit)()}
          >
            Add User
          </Button>
        </div>
      </div>
    </ModalCenter>
  );
};

export default AddNewModal;
