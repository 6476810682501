import { Button } from '@headlessui/react';
import { Icon } from '@iconify/react';
import moment from 'moment';
import React, { useMemo } from 'react';

import ModalCenter from '@/Components/Modals/Center';
import { useAdminUserContext } from '@/Hooks/Administrator';
import type { AdministratorUserResponse } from '@/Types/Administrator';
import { cn } from '@/Utils/cn';

interface DetailModalProps {
  selectedUser: AdministratorUserResponse | undefined;
  isOpen: boolean;
  onClose: () => void;
}

const DetailModal: React.FC<DetailModalProps> = ({
  isOpen,
  onClose,
  selectedUser,
}) => {
  const { handleUpdateStatus } = useAdminUserContext();
  const formatDate = (dateString?: string) => {
    if (!dateString) return '-';
    return moment(dateString).format('DD-MM-YYYY │ HH:mm');
  };

  const userDetails = useMemo(
    () => [
      { label: 'Name', value: selectedUser?.name, colSpan: false },
      {
        label: 'Redcomm Email',
        value: selectedUser?.email,
        colSpan: false,
      },

      {
        label: 'Status',
        value: selectedUser?.deleted_at ? 'Disabled' : 'Active',
        colSpan: false,
      },
    ],
    [selectedUser],
  );

  const dates = useMemo(
    () => [
      {
        label: 'Request Date',
        value: formatDate(selectedUser?.request_date),
      },
      {
        label: 'Activation Date',
        value: formatDate(
          selectedUser?.request_activation_date ??
            selectedUser?.email_verified_at,
        ),
      },
      {
        label: 'Admin Role Activation Date',
        value: formatDate(selectedUser?.request_activation_admin_date),
      },
    ],
    [selectedUser],
  );

  const handleClose = () => {
    onClose();
  };

  if (!selectedUser) return null;

  return (
    <ModalCenter isOpen={isOpen} onClose={handleClose}>
      <div className="relative w-886 p-35 md:w-full">
        {/* Title */}
        <h1 className="text-24 font-semibold leading-32 text-black-redx md:text-18">
          Admin Detail
        </h1>

        {/* User Details */}
        <div className="mt-24 grid grid-cols-2 items-start gap-24 md:grid-cols-1">
          {userDetails.map((item, index) => (
            <div key={index} className={item.colSpan ? 'col-span-2' : ''}>
              <p className="text-14 font-semibold text-black-redx">
                {item.label}
              </p>
              <p className="text-14 text-black-redx">{item.value}</p>
            </div>
          ))}
        </div>

        {/* Date Section */}
        <div className="mt-24">
          <p className="text-14 font-semibold text-black-redx">Date</p>
          <div className="mt-8 flex max-w-fit flex-col space-y-4">
            {dates.map((date, index) => (
              <div
                key={index}
                className="border-b flex items-center justify-between gap-8 border-gray-300 pb-4"
              >
                <span className="text-14 text-black-redx">{date.label} </span>
                <span className="min-w-200 text-14 text-black-redx">
                  : {date.value}
                </span>
              </div>
            ))}
          </div>
        </div>

        {/* Buttons */}
        <div
          className={cn(
            'mt-24 flex items-center gap-16 gap-x-10 md:flex-col',
            selectedUser.email === 'developer.ai@redcomm.co.id'
              ? 'justify-end'
              : 'justify-between',
          )}
        >
          {selectedUser.email !== 'developer.ai@redcomm.co.id' && (
            <Button
              className="inline-flex cursor-pointer gap-4"
              onClick={() => handleUpdateStatus([selectedUser.id], 'revoke')}
            >
              <p className="font-semibold text-error-redx">Remove as Admin</p>
              <Icon
                className="size-24 text-error-redx"
                icon="lucide:user-round-cog"
              />
            </Button>
          )}
          <Button
            className="rounded-8 bg-blue-redx px-16 py-8 text-white md:w-full"
            onClick={handleClose}
          >
            Close
          </Button>
        </div>
      </div>
    </ModalCenter>
  );
};

export default DetailModal;
