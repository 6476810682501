import { useContext } from 'react';

import { AdminUserListContext } from '@/Pages/Administrator/Context/admin-user-list-context';

export const useAdminUserContext = () => {
  const context = useContext(AdminUserListContext);
  if (!context)
    throw new Error(
      'useAdminUserContext must be used within a AdminUserListProvider',
    );
  return context;
};
