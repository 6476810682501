/* eslint-disable consistent-return */
const DB_NAME = 'REDX_DB';
const DB_VERSION = 1;

const openDb = (storeName: string): Promise<IDBDatabase> => {
  return new Promise((resolve, reject) => {
    const request: IDBOpenDBRequest = indexedDB.open(DB_NAME, DB_VERSION);

    request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
      const db = (event.target as IDBOpenDBRequest).result as IDBDatabase;

      if (!db.objectStoreNames.contains(storeName)) {
        db.createObjectStore(storeName, { keyPath: 'id' });
      }
    };

    request.onsuccess = (event: Event) =>
      resolve((event.target as IDBOpenDBRequest).result as IDBDatabase);
    request.onerror = (event: Event) =>
      reject((event.target as IDBOpenDBRequest).error);
  });
};

export const saveDataToIndexedDb = async (
  storeName: string,
  data: any,
): Promise<void> => {
  const db = await openDb(storeName);

  if (!db.objectStoreNames.contains(storeName)) {
    return;
  }

  const transaction = db.transaction(storeName, 'readwrite');
  const store = transaction.objectStore(storeName);

  store.put(data);

  return new Promise((resolve, reject) => {
    transaction.oncomplete = () => resolve();
    transaction.onerror = () => reject(transaction.error);
  });
};

export const getDataFromIndexedDb = async (
  storeName: string,
  id: number,
): Promise<any> => {
  const db = await openDb(storeName);
  const transaction = db.transaction(storeName, 'readonly');
  const store = transaction.objectStore(storeName);

  return new Promise((resolve, reject) => {
    const request = store.get(id);
    request.onsuccess = () => resolve(request.result);
    request.onerror = () => reject(request.error);
  });
};

export const getAllDataFromIndexedDb = async (
  storeName: string,
): Promise<any[]> => {
  const db = await openDb(storeName);
  const transaction = db.transaction(storeName, 'readonly');
  const store = transaction.objectStore(storeName);

  return new Promise((resolve, reject) => {
    const request = store.getAll();
    request.onsuccess = () => resolve(request.result);
    request.onerror = () => reject(request.error);
  });
};

export const updateDataInIndexedDb = async (
  storeName: string,
  data: any,
): Promise<void> => {
  return saveDataToIndexedDb(storeName, data);
};

export const deleteDataFromIndexedDb = async (
  storeName: string,
  id: number,
): Promise<void> => {
  const db = await openDb(storeName);
  const transaction = db.transaction(storeName, 'readwrite');
  const store = transaction.objectStore(storeName);

  store.delete(id);
  return new Promise((resolve, reject) => {
    transaction.oncomplete = () => resolve();
    transaction.onerror = () => reject(new Error(transaction.error?.message));
  });
};

export const clearStoreInIndexedDb = async (
  storeName: string,
): Promise<void> => {
  const db = await openDb(storeName);

  // Cek apakah store tersedia
  if (!db.objectStoreNames.contains(storeName)) {
    return;
  }

  const transaction = db.transaction(storeName, 'readwrite');
  const store = transaction.objectStore(storeName);
  store.clear();

  return new Promise((resolve, reject) => {
    transaction.oncomplete = () => resolve();
    transaction.onerror = () => reject(transaction.error);
  });
};
export const clearAllIndexedDb = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.deleteDatabase(DB_NAME);

    request.onsuccess = () => resolve();
    request.onerror = () => reject(request.error);
  });
};
