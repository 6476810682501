import { z } from 'zod';

export const AudienceBehavioralTrends = z.object({
  current_trends: z.string().min(2, 'Current trends is required'),
  consumption_behaviors: z.string().min(2, 'Consumption behavior is required'),
});

export type AudienceBehavioralTrendsSchema = z.infer<
  typeof AudienceBehavioralTrends
>;
