import { Icon } from '@iconify/react/dist/iconify.js';
import React, { useContext, useMemo } from 'react';

import ModalCenter from '@/Components/Modals/Center';
import { ProjectContext } from '@/Context/ProjectContext';
import { useHistoryStatus } from '@/Hooks/useHistoryStatus';

type SectionLabel =
  | 'Product Research'
  | 'Market Research: Industry'
  | 'Target Audience'
  | 'Audience Archetype'
  | 'Key Insight'
  | 'Challenge & Communication Task'
  | 'Competitor Analysis'
  | 'Opportunity Among Competition'
  | 'One-Page Strategy'
  | 'Key Touch Point'
  | 'Creative Idea'
  | 'Idea Alignment'
  | 'Idea Personalization'
  | 'Idea Expansion'
  | 'Searchability Content'
  | 'Discoverability Content'
  | 'Credibility Content'
  | 'Selected Content Ideas';

const ChangingImpactModal: React.FC<{
  section: string;
  isRegenerating: boolean;
  isOpen: boolean;
  onClose: () => void;
  handleSubmit: () => void;
  visibleSections: SectionLabel[];
}> = ({
  isOpen,
  isRegenerating,
  section,
  onClose,
  handleSubmit,
  visibleSections,
}) => {
  const [state] = useContext(ProjectContext);
  const isEnabledHistoryStatus = useMemo(
    () => state.isEnabledHistoryStatus,
    [state.isEnabledHistoryStatus],
  );
  const project = useMemo(() => state.project, [state.project]);

  const {
    isMarketResearchDemand,
    isMarketResearchTrend,
    isProductResearchProductDescription,
    isProductResearchMainFeature,
    isProductResearchMarcommHighlight,
    isAudienceArchetypeComplete,
    isKeyInsightsComplete,
    isCompetitorAnalysisComplete,
    isOpportunityAmongCompetitionComplete,
    isChallengeAndTaskOfTheBrand,
    isOnePageStrategy,
    isKeyTouchPoint,
    isTargetAudienceAudienceInsight,
    isTargetAudienceBehavioralTrend,
    isTargetAudienceMediaConsumptionPattern,
    isTargetAudiencePerceptionAnalysis,
    isIdeaAlignment,
    isIdeaPersonalization,
    isIdeaExpansion,
    credibilityObjective,
    searchabilityObjective,
    discoverabilityObjective,
  } = useHistoryStatus(project, isEnabledHistoryStatus);

  const sections = useMemo(
    () => [
      {
        icon: 'lucide:package-search',
        label: 'Product Research',
        status:
          isProductResearchProductDescription.status ||
          isProductResearchMainFeature.status ||
          isProductResearchMarcommHighlight.status,
      },
      {
        icon: 'lucide:file-line-chart',
        label: 'Market Research: Industry',
        status: isMarketResearchDemand.status && isMarketResearchTrend.status,
      },
      {
        icon: 'lucide:users-round',
        label: 'Target Audience',
        status:
          isTargetAudienceAudienceInsight.status ||
          isTargetAudienceBehavioralTrend.status ||
          isTargetAudienceMediaConsumptionPattern.status ||
          isTargetAudiencePerceptionAnalysis.status,
      },
      {
        icon: 'lucide:book-user',
        label: 'Audience Archetype',
        status: isAudienceArchetypeComplete.status,
      },
      {
        icon: 'lucide:lightbulb',
        label: 'Key Insight',
        status: isKeyInsightsComplete.status,
      },
      {
        icon: 'lucide:puzzle',
        label: 'Challenge & Communication Task',
        status: isChallengeAndTaskOfTheBrand.status,
      },
      {
        icon: 'lucide:sword',
        label: 'Competitor Analysis',
        status: isCompetitorAnalysisComplete.status,
      },
      {
        icon: 'lucide:split',
        label: 'Opportunity Among Competition',
        status: isOpportunityAmongCompetitionComplete.status,
      },
      {
        icon: 'lucide:goal',
        label: 'One-Page Strategy',
        status: isOnePageStrategy.status,
      },
      {
        icon: 'lucide:key-square',
        label: 'Key Touch Point',
        status: isKeyTouchPoint.status,
      },
      {
        icon: 'lucide:arrows-up-from-line',
        label: 'Idea Alignment',
        status: isIdeaAlignment.status,
      },
      {
        icon: 'lucide:person-standing',
        label: 'Idea Personalization',
        status: isIdeaPersonalization.status,
      },
      {
        icon: 'lucide:expand',
        label: 'Idea Expansion',
        status: isIdeaExpansion.status,
      },
      {
        icon: 'lucide:text-cursor-input',
        label: 'Searchability Content',
        status:
          isKeyTouchPoint.status &&
          isIdeaExpansion.status &&
          searchabilityObjective.status &&
          project.submissions[0].selected_framework === 'sdrcp',
      },
      {
        icon: 'lucide:trending-up',
        label: 'Discoverability Content',
        status:
          isKeyTouchPoint.status &&
          isIdeaExpansion.status &&
          discoverabilityObjective.status &&
          project.submissions[0].selected_framework === 'sdrcp',
      },
      {
        icon: 'lucide:handshake',
        label: 'Credibility Content',
        status:
          isKeyTouchPoint.status &&
          isIdeaExpansion.status &&
          credibilityObjective.status &&
          project.submissions[0].selected_framework === 'sdrcp',
      },
      {
        icon: 'lucide:book-heart',
        label: 'Selected Content Ideas',
        status: false,
      },
    ],
    [
      isProductResearchProductDescription,
      isProductResearchMainFeature,
      isProductResearchMarcommHighlight,
      isTargetAudienceAudienceInsight,
      isTargetAudienceBehavioralTrend,
      isTargetAudienceMediaConsumptionPattern,
      isTargetAudiencePerceptionAnalysis,
      isAudienceArchetypeComplete,
      isKeyInsightsComplete,
      isChallengeAndTaskOfTheBrand,
      isCompetitorAnalysisComplete,
      isOpportunityAmongCompetitionComplete,
      isOnePageStrategy,
      isKeyTouchPoint,
      isIdeaAlignment,
      isIdeaPersonalization,
      credibilityObjective,
      discoverabilityObjective,
      searchabilityObjective,
      project.submissions[0].selected_framework,
    ],
  );

  const filteredSections = sections.filter(
    (sec) => sec.status && visibleSections.includes(sec.label as SectionLabel),
  );

  const btnDisabledClass = isRegenerating
    ? 'bg-placeholder-redx border-placeholder-redx cursor-not-allowed'
    : `bg-blue-redx border-blue-redx cursor-pointer`;

  return (
    <ModalCenter hasButtonClose={false} isOpen={isOpen} onClose={onClose}>
      <div className="relative max-w-4xl">
        <div className="flex transition-transform duration-500">
          <div className="flex w-full shrink-0 flex-col gap-35 p-35 md:gap-24 md:p-24">
            <div className="flex flex-col gap-16">
              <h1 className="text-32 font-semibold leading-56 text-black-redx md:text-16 md:leading-relaxed">
                Changing may impact other areas
              </h1>
              <p className="text-16 font-normal leading-24 text-black-redx  md:text-14 md:leading-relaxed">
                Updating this page will affect other sections, as listed below.
                The system will update the related sections, potentially
                adjusting your previous results in part or in full.
              </p>
            </div>
            <div className="flex flex-col flex-wrap gap-16 ">
              <h3 className="border-b-1 border-b-stroke-redx pb-4 text-16 font-bold leading-24 text-black-redx md:leading-relaxed">
                Impacted Sections
              </h3>
              <div className="flex max-h-250 flex-col flex-wrap gap-x-8 gap-y-4 overflow-scroll scrollbar-hide md:flex-nowrap">
                {filteredSections.map((section, index) => (
                  <div key={index} className="inline-flex items-center gap-4">
                    <Icon
                      className=" size-16 font-light md:size-14"
                      icon={section.icon}
                      style={{
                        transform:
                          section.icon === 'lucide:arrows-up-from-line'
                            ? 'rotate(90deg)'
                            : 'none',
                      }}
                    />

                    <span className="text-16 font-normal leading-20 md:text-14">
                      {section.label}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className="inline-flex w-full justify-end gap-16 md:flex md:flex-col-reverse md:gap-8">
              <button
                className="min-w-200 rounded-8 border-1 border-blue-redx bg-transparent p-16 font-semibold text-blue-redx md:p-8"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                className={`min-w-200 p-16 ${btnDisabledClass} rounded-8 font-semibold text-white md:p-8`}
                disabled={isRegenerating}
                id={`btn-regenerate-${section}`}
                onClick={handleSubmit}
              >
                {isRegenerating ? 'Loading...' : 'Proceed Anyway'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </ModalCenter>
  );
};

export default ChangingImpactModal;
