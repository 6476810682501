import type { RefObject } from 'react';
import { createContext } from 'react';

import type { RequestUserResponse } from '@/Types/Administrator';

export interface RequestListProps {
  isLoading: boolean;
  isFetchingNextPage: boolean;
  records: RequestUserResponse[];
  page: number;
  selectedSortBy: string;
  hasNextPage: boolean;
  updateStatus: { id: RequestUserResponse['id'][]; status: string };
  observerRef: RefObject<HTMLDivElement>;
  checkAll: boolean;
  checkPerRow: RequestUserResponse['id'][];
  setTargetRef: (ref: HTMLDivElement | null) => void;
  setPage: (page: number) => void;
  setShortBy: (sortBy: string) => void;
  setCheckAll: (value: boolean) => void;
  setCheckPerRow: (id: RequestUserResponse['id'], status: boolean) => void;
  handleUpdateStatus: (id: RequestUserResponse['id'][], status: string) => void;
  setSearch: (search: string) => void;
  handleRefetch: (isReset: boolean) => void;
}

export const RequestListContext = createContext<RequestListProps | undefined>(
  undefined,
);
