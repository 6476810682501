import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { Icon } from '@iconify/react/dist/iconify.js';
import { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { useNavigate, useSearchParams } from 'react-router-dom';

import TabAdminList from './Components/AdminList/Tab';
import TabRequestList from './Components/RequestList/Tab';
import TabRoleList from './Components/RoleList/Tab';
import TabUserList from './Components/UserList/Tab';

const ADMIN_TABS = [
  { name: 'Request List', component: <TabRequestList /> },
  { name: 'User List', component: <TabUserList /> },
  { name: 'Role List', component: <TabRoleList /> },
  { name: 'Admin List', component: <TabAdminList /> },
];

const Index = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const tab = searchParams.get('tab');
    if (tab) {
      const tabIndex = ADMIN_TABS.findIndex(
        (t) => t.name.toLowerCase().replace(' ', '-') === tab.toLowerCase(),
      );
      if (tabIndex !== -1) {
        setActiveTab(tabIndex);
      }
    }
  }, [searchParams]);

  return (
    <>
      <div className="pt-73">
        <div className="mx-auto flex max-w-7xl flex-col sm:px-6 md:max-w-sm lg:px-8">
          <div className="mt-20 flex w-full flex-row justify-between gap-14 md:flex-col">
            <div className="flex grow flex-col gap-16">
              <div className="gap-36 inline-flex items-center md:gap-8">
                <Icon
                  className="size-24"
                  icon="lucide:arrow-left"
                  onClick={() => navigate('/projects')}
                />
                <h1 className="leading-36 text-32 font-semibold text-black-redx sm:text-[24px] sm:leading-32">
                  Administrator Page
                </h1>
              </div>
              <p className="text-16 font-normal leading-20 text-grey-redx">
                You can manage all existing user and requested user here
              </p>
            </div>
          </div>
          <div className="mt-37">
            <TabGroup
              className=" pb-10"
              onChange={setActiveTab}
              selectedIndex={activeTab}
            >
              <TabList className="border-grey-200 flex w-full overflow-x-auto border-b-1 scrollbar-hide">
                {ADMIN_TABS.map((tab, index) => (
                  <Tab
                    key={index}
                    className="w-full text-15 text-black-redx 
           data-[selected]:border-b-3 data-[selected]:border-blue-redx 
           data-[selected]:font-bold data-[selected]:text-blue-redx 
           data-[focus]:outline-0 data-[focus]:outline-transparent 
            [&:has(:focus-visible)]:ring-0"
                    onClick={() => setActiveTab(index)}
                  >
                    <div className="-mb-px text-sm inline-flex w-full min-w-250 items-center justify-center gap-8 whitespace-nowrap pb-12 font-medium focus:outline-none">
                      {tab.name}
                    </div>
                  </Tab>
                ))}
              </TabList>
              <TabPanels className="content-section py-24">
                {ADMIN_TABS.map((tab, index) => (
                  <TabPanel key={index}>{tab.component}</TabPanel>
                ))}
              </TabPanels>
            </TabGroup>
          </div>
        </div>
      </div>
      <Toaster
        containerStyle={{
          bottom: 40,
          left: 40,
        }}
        position="bottom-center"
        toastOptions={{
          duration: 5000,
        }}
      />
    </>
  );
};

export default Index;
