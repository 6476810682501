import { useState } from 'react';

import Checkbox from '@/Components/Checkbox';

interface TableColumn {
  title: string;
  subtitle?: string;
  onCheckboxChange?: (value: boolean) => void;
}

interface TableProps {
  hasCheckbox?: boolean;
  columns: TableColumn[];
  children: React.ReactNode;
  onCheckboxChange?: (value: boolean) => void;
}

const Table = ({
  columns,
  children,
  hasCheckbox,
  onCheckboxChange,
}: TableProps) => {
  const [checked, setChecked] = useState(false);
  return (
    <table className="border z-40 w-full table-auto border-collapse border-gray-200">
      {/* Header */}
      <thead className="sticky top-0 z-10 bg-gray-100 text-left text-gray-600">
        <tr>
          {hasCheckbox && (
            <th className="w-[5%] px-16 py-24 text-center">
              <Checkbox
                checked={checked}
                onChange={(value) => {
                  setChecked(value);
                  onCheckboxChange?.(value);
                }}
              />
            </th>
          )}
          {columns.map((col, index) => (
            <th
              key={index}
              className={`min-w-[calc(100%/${columns.length})] max-w-full px-16 py-24`}
            >
              <div className="whitespace-nowrap text-14 font-semibold leading-20 text-black-redx">
                {col.title}
              </div>
              {col.subtitle && (
                <div className="mt-8 whitespace-nowrap text-12 font-semibold leading-16 text-grey-redx">
                  {col.subtitle}
                </div>
              )}
            </th>
          ))}
        </tr>
      </thead>

      {/* Body (Dijadikan Slot/Children) */}
      <tbody>{children}</tbody>
    </table>
  );
};

export default Table;
