import { useMemo } from 'react';

import Table from '@/Components/Table';
import { useUserContext } from '@/Hooks/Administrator/useUserContext';

import DetailModal from './DetailModal';
import GrantConfirmationModal from './GrantConfirmationModal';
import RevokeConfirmationModal from './RevokeConfirmationModal';
import TableRowRequestList from './TableRow';

const TableRequestList = () => {
  const {
    records,
    hasNextPage,
    isFetchingNextPage,
    updateStatus,
    isLoading,
    activeFilter,
    setTargetRef,
    handleUpdateStatus,
  } = useUserContext();

  const COLUMNS = useMemo(() => {
    if (activeFilter === 'disabled') {
      return [
        { title: '' },
        { title: 'Name' },
        { title: 'Email', subtitle: 'Redhub ID' },
        { title: 'Request Date' },
        { title: 'Deactivate Date' },
        { title: 'Status' },
        { title: '' },
      ];
    }

    return [
      { title: '' },
      { title: 'Name' },
      { title: 'Email', subtitle: 'Redhub ID' },
      { title: 'Request Date' },
      { title: 'Activation Date' },
      { title: 'Status' },
      { title: '' },
    ];
  }, [activeFilter]);

  const isDetail = updateStatus.status === 'detail';
  const isRevoke = updateStatus.status === 'revoke';
  const isGrant = updateStatus.status === 'grant';

  const selectedUser =
    updateStatus.id.length === 1 ? updateStatus.id[0] : undefined;

  if (!isLoading && records.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center">
        <img
          alt="Empty-amico 1.png"
          className="size-400"
          src="assets/img/Empty-amico 1.png"
        />
        <div className="flex flex-col items-center justify-items-center gap-20">
          <h2 className="text-32 font-semibold leading-[36px] text-black-redx md:text-24">
            Data not found
          </h2>
          <p className="text-center text-16 font-normal leading-20 text-grey-redx">
            There’s currently no information available here.
          </p>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="border max-h-[calc(100vh-300px)] w-full overflow-auto  scrollbar-hide md:max-w-sm">
        <Table columns={COLUMNS}>
          {records.map((record) => (
            <TableRowRequestList key={record.id} row={record} />
          ))}
        </Table>
        {hasNextPage && (
          <div
            ref={(el) => {
              if (el) {
                setTargetRef(el);
              }
            }}
            className="p-24 text-center"
          >
            {isFetchingNextPage ? (
              <p className="font-medium">Loading more data...</p>
            ) : null}
          </div>
        )}
      </div>
      <RevokeConfirmationModal
        isOpen={isRevoke}
        onClose={() => handleUpdateStatus([], '')}
        selectedUser={records.find((record) => record.id === selectedUser)}
      />
      <GrantConfirmationModal
        isOpen={isGrant}
        onClose={() => handleUpdateStatus([], '')}
        selectedUser={records.find((record) => record.id === selectedUser)}
      />
      <DetailModal
        isOpen={isDetail}
        onClose={() => handleUpdateStatus([], '')}
        selectedUser={records.find((record) => record.id === selectedUser)}
      />
    </>
  );
};

export default TableRequestList;
