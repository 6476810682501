import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  saveRouteNext,
  saveSubInformation,
} from '@/Context/Actions/project-actions';
import { ProjectContext } from '@/Context/ProjectContext';
import type { RouteOption } from '@/Context/Reducer/project-reducer';
import type { HistoryStatus } from '@/Types/history';
import type { ArchetypeOpportunity } from '@/Types/OpportunityAmongCompetition';
import type { ProjectProps } from '@/Types/Projects';

import { useDifferentArchetypes } from '../AudienceArchetype';

interface UseCtaParams {
  isKeyInsightsComplete: HistoryStatus | undefined;
  isChallengeAndTaskOfTheBrand: HistoryStatus | undefined;
  isOnePageStrategy: HistoryStatus | undefined;
  isOpportunityAmongCompetitionComplete: HistoryStatus | undefined;
  archetypesArray: ArchetypeOpportunity[];
  routeOptionValue: RouteOption;
  activeArchetype: ArchetypeOpportunity | null;
  totalSelected: number;
  isEditor: boolean;
  project: ProjectProps;
  handleRefetchSelectedData: () => void;
  setShowViewInfoModal: React.Dispatch<React.SetStateAction<boolean>>;
  setRouteOption: React.Dispatch<React.SetStateAction<RouteOption>>;
  setErrorTabIndex: React.Dispatch<React.SetStateAction<number[]>>;
}

const useCtaAction = ({
  isKeyInsightsComplete,
  isChallengeAndTaskOfTheBrand,
  isOnePageStrategy,
  isOpportunityAmongCompetitionComplete,
  archetypesArray,
  isEditor,
  routeOptionValue,
  project,
  totalSelected,
  activeArchetype,
  setRouteOption,
  setShowViewInfoModal,
  handleRefetchSelectedData,
  setErrorTabIndex,
}: UseCtaParams) => {
  const [, dispatch] = useContext(ProjectContext);
  const navigate = useNavigate();

  const projectSlug = project.slug;

  const { data: differentArchetypes } = useDifferentArchetypes(project.slug);

  // Memoize routeOptionValue to avoid unnecessary re-render
  const memoizedRouteOptionValue = useMemo(
    () => ({ ...routeOptionValue }),
    [routeOptionValue],
  );

  const isDiffOnePage =
    differentArchetypes?.is_different_archetype.one_pages ?? false;
  const isDiffKeyInsight =
    differentArchetypes?.is_different_archetype.key_insight ?? false;
  const isDiffChallenge =
    differentArchetypes?.is_different_archetype.challenges ?? false;
  const isSelectedChallenges =
    differentArchetypes?.is_selected_content.challenges ?? false;
  const isSelectedKeyInsight =
    differentArchetypes?.is_selected_content.key_insight ?? false;

  const hasSelectedOpportunity = (archetypesArray: ArchetypeOpportunity[]) => {
    setErrorTabIndex([]);
    const indicesWithoutSelectedIdea = archetypesArray
      .map((section, index) => {
        const hasSelectedIdea = section.opportunities.some(
          (idea) => idea.is_selected,
        );
        return hasSelectedIdea ? -1 : index;
      })
      .filter((index) => index !== -1);

    if (indicesWithoutSelectedIdea.length) {
      setErrorTabIndex(indicesWithoutSelectedIdea);
      const namesWithoutSelectedKeyInsight = indicesWithoutSelectedIdea
        .map((index) => `Archetype ${index + 1}`)
        .join(', ');
      dispatch(
        saveSubInformation(
          `{text-error-redx} Please select opportunity for ${namesWithoutSelectedKeyInsight}`,
        ),
      );
      return true;
    }
    dispatch(saveSubInformation(``));
    return false;
  };

  const handleEditorClick = useCallback(() => {
    if (
      activeArchetype?.is_querying ||
      isOpportunityAmongCompetitionComplete?.isQuerying
    ) {
      dispatch(
        saveSubInformation(
          `{text-error-redx} Please wait, Idea is still generating`,
        ),
      );
      return;
    }

    if (!isKeyInsightsComplete?.status || isDiffKeyInsight) {
      dispatch(
        saveSubInformation(
          `{text-error-redx} Please generate Key Insight first.`,
        ),
      );
      return;
    }

    if (isKeyInsightsComplete?.status && !isSelectedKeyInsight) {
      dispatch(
        saveSubInformation(
          `{text-error-redx} Please choose Key Insight first.`,
        ),
      );
      return;
    }

    if (!isChallengeAndTaskOfTheBrand?.status || isDiffChallenge) {
      dispatch(
        saveSubInformation(
          `{text-error-redx} Please generate Challenge & Communication Task first.`,
        ),
      );
      return;
    }

    if (isChallengeAndTaskOfTheBrand?.status && !isSelectedChallenges) {
      dispatch(
        saveSubInformation(
          `{text-error-redx} Please choose Challenge & Communication Task first.`,
        ),
      );
      return;
    }

    if (isOnePageStrategy?.isQuerying) {
      dispatch(
        saveSubInformation(
          `{text-error-redx} Please wait, one page is still generating`,
        ),
      );

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
    }

    if (
      isOnePageStrategy?.isError ||
      (isOnePageStrategy?.status &&
        !isDiffOnePage &&
        !isOnePageStrategy?.isRegenerated &&
        !activeArchetype?.has_regenerate_per_archetype_one_pages &&
        !activeArchetype?.is_diff_opportunities)
    ) {
      navigate(`/${projectSlug}/one-page-strategy`);
      return;
    }

    const hasError = hasSelectedOpportunity(archetypesArray);

    if (!hasError) handleRefetchSelectedData();
  }, [
    memoizedRouteOptionValue,
    isDiffOnePage,
    isOnePageStrategy,
    isOpportunityAmongCompetitionComplete,
    isKeyInsightsComplete,
    isChallengeAndTaskOfTheBrand,
    isDiffKeyInsight,
    isDiffChallenge,
    isSelectedChallenges,
    activeArchetype,
    archetypesArray,
  ]);

  const handleNonEditorClick = useCallback(() => {
    if (
      activeArchetype?.is_querying ||
      isOpportunityAmongCompetitionComplete?.isQuerying
    ) {
      dispatch(
        saveSubInformation(
          `{text-error-redx} Please wait, Idea is still generating`,
        ),
      );
      return;
    }

    if (
      !isOnePageStrategy?.status ||
      isDiffOnePage ||
      isOnePageStrategy?.isRegenerated
    ) {
      setShowViewInfoModal(true);
      return;
    }

    navigate(`/${projectSlug}/one-page-strategy`);
  }, [
    activeArchetype?.is_querying,
    isOnePageStrategy,
    isOpportunityAmongCompetitionComplete,
    isDiffOnePage,
  ]);

  useEffect(() => {
    if (isEditor) {
      memoizedRouteOptionValue.isInactive =
        !isOnePageStrategy?.isError &&
        (!isChallengeAndTaskOfTheBrand?.status ||
          !isKeyInsightsComplete?.status ||
          !isSelectedChallenges ||
          !isSelectedKeyInsight ||
          isOnePageStrategy?.isQuerying ||
          isOpportunityAmongCompetitionComplete?.isQuerying ||
          !isOpportunityAmongCompetitionComplete?.status ||
          totalSelected === 0);

      if (
        !isOnePageStrategy?.isError &&
        isOnePageStrategy?.status &&
        !isOnePageStrategy?.isRegenerated &&
        !isDiffOnePage &&
        (activeArchetype?.has_regenerate_one_page ||
          activeArchetype?.has_regenerate_per_archetype_one_pages ||
          activeArchetype?.is_diff_opportunities)
      ) {
        memoizedRouteOptionValue.label = 'Update Other Pages ';
        memoizedRouteOptionValue.isGenerate = true;
      } else if (
        isOnePageStrategy?.isError ||
        (isOnePageStrategy?.status &&
          !isDiffOnePage &&
          !isOnePageStrategy?.isRegenerated &&
          !activeArchetype?.has_regenerate_one_page &&
          !activeArchetype?.has_regenerate_per_archetype_one_pages &&
          !activeArchetype?.is_diff_opportunities)
      ) {
        memoizedRouteOptionValue.label = 'Next to One-Page Strategy';
        memoizedRouteOptionValue.isGenerate = false;
      } else {
        memoizedRouteOptionValue.label = 'Generate One-Page Strategy';
        memoizedRouteOptionValue.isGenerate = true;
      }

      memoizedRouteOptionValue.onClick = handleEditorClick;
    } else {
      memoizedRouteOptionValue.label = 'Next to One-Page Strategy';
      memoizedRouteOptionValue.isGenerate = false;
      memoizedRouteOptionValue.isInactive =
        !isOnePageStrategy?.status ||
        isOnePageStrategy?.isQuerying ||
        isOnePageStrategy?.isRegenerated ||
        isDiffOnePage;

      memoizedRouteOptionValue.onClick = handleNonEditorClick;
    }

    setRouteOption(memoizedRouteOptionValue);
    dispatch(saveRouteNext(memoizedRouteOptionValue));
  }, [
    isDiffOnePage,
    isOnePageStrategy,
    activeArchetype,
    isEditor,
    isChallengeAndTaskOfTheBrand,
    isKeyInsightsComplete,
    isOpportunityAmongCompetitionComplete,
    totalSelected,
    isSelectedChallenges,
    isSelectedKeyInsight,
    isDiffKeyInsight,
  ]);
};

export { useCtaAction };
