import { Button } from '@headlessui/react';
import { Icon } from '@iconify/react';
import moment from 'moment';
import React, { useMemo } from 'react';

import ModalCenter from '@/Components/Modals/Center';
import { useRoleContext } from '@/Hooks/Administrator/useRoleContext';
import type { AdministratorRoleResponse } from '@/Types/Administrator';

interface DetailModalProps {
  selectedRole: AdministratorRoleResponse | undefined;
  isOpen: boolean;
  onClose: () => void;
}

const DetailModal: React.FC<DetailModalProps> = ({
  isOpen,
  onClose,
  selectedRole,
}) => {
  const { handleUpdateStatus } = useRoleContext();
  const formatDate = (dateString?: string) => {
    if (!dateString) return '-';
    return moment(dateString).format('DD-MM-YYYY │ HH:mm');
  };

  const roleDetail = useMemo(
    () => [
      { label: 'Role Name', value: selectedRole?.name, colSpan: false },
      {
        label: 'Display Role Name',
        value: selectedRole?.display_name,
        colSpan: false,
      },
      {
        label: 'Date',
        value: `Activation Date : ${formatDate(selectedRole?.created_at)}`,
        colSpan: false,
      },
      {
        label: 'Status',
        value: 'Active',
        colSpan: false,
      },
    ],
    [selectedRole],
  );

  const handleClose = () => {
    onClose();
  };

  if (!selectedRole) return null;

  return (
    <ModalCenter isOpen={isOpen} onClose={handleClose}>
      <div className="relative w-886 p-35 md:w-full">
        {/* Title */}
        <h1 className="text-24 font-semibold leading-32 text-black-redx md:text-18">
          Role Detail
        </h1>

        {/* User Details */}
        <div className="mt-24 grid grid-cols-2 items-start gap-24 md:grid-cols-1">
          {roleDetail.map((item, index) => (
            <div key={index} className={item.colSpan ? 'col-span-2' : ''}>
              <p className="text-14 font-semibold text-black-redx">
                {item.label}
              </p>
              <p className="text-14 text-black-redx">{item.value}</p>
            </div>
          ))}
        </div>

        {/* Buttons */}
        <div className="mt-24 flex items-center  justify-between gap-16 gap-x-10 md:flex-col">
          <Button
            className="inline-flex cursor-pointer gap-4"
            onClick={() => handleUpdateStatus(selectedRole.id, 'edit')}
          >
            <p className="font-semibold text-blue-redx">Edit</p>
            <Icon className="size-24 text-blue-redx" icon="lucide:pen-line" />
          </Button>
          <Button
            className="rounded-8 bg-blue-redx px-16 py-8 text-white md:w-full"
            onClick={handleClose}
          >
            Close
          </Button>
        </div>
      </div>
    </ModalCenter>
  );
};

export default DetailModal;
