import { zodResolver } from '@hookform/resolvers/zod';
import { forwardRef, memo, useEffect, useImperativeHandle } from 'react';
import { Controller, useForm } from 'react-hook-form';

import CardTitle from '@/Components/CardTitle';
import CardInput from '@/Components/Projects/SliderCard/CardInput';
import SelectButton from '@/Components/Projects/SliderCard/SelectButton';
import { useUpdateSelectedArchetype } from '@/Hooks/AudienceArchetype';
import type { Archetype } from '@/Types/AudienceArchetype';
import { ArchetypeSchema, type Schema } from '@/Types/AudienceArchetype/schema';
import type { ProjectProps } from '@/Types/Projects';
import type { UserResponse } from '@/Types/user';
import { triggerGTMEvent } from '@/Utils/gtm';

export interface CardHandles {
  submitForm: () => void;
}

const fieldsToDisplay = [
  { name: 'archetype_name', label: 'Archetype Name' },
  {
    name: 'demographics',
    label: 'Demographics',
  },
  {
    name: 'occupation',
    label: 'Occupation',
  },
  {
    name: 'lifestyle',
    label: 'Lifestyle',
  },
  {
    name: 'behavior',
    label: 'Behavior',
  },
  {
    name: 'needs_and_challenges',
    label: 'Needs and Challenges',
  },
  {
    name: 'potential_buying_motives',
    label: 'Potential Buying Motives',
  },
  {
    name: 'current_trends',
    label: 'Current Trends',
  },
  {
    name: 'source_of_information',
    label: 'Source of Information',
  },
  {
    name: 'online_search_behavior',
    label: 'Online Search Behavior',
  },
  {
    name: 'purchase_frequency',
    label: 'Purchase Frequency',
  },
  {
    name: 'preferred_sales_channels',
    label: 'Preferred Sales Channels',
  },
];

const Card = forwardRef<
  CardHandles,
  {
    user?: UserResponse;
    id?: number;
    isDisabledSelectButton?: boolean;
    isEdited?: boolean;
    project?: ProjectProps;
    totalData?: number;
    totalSelected?: number;
    isFetching?: boolean;
    item?: Archetype;
    index?: number;
    isEditing?: boolean;
    isSelected?: boolean;
    isCanSelect?: boolean;
    onSubmitSuccess?: (data: Schema, id: number, index: number) => void;
    onSelectSuccess?: (isEdited: boolean, isNeedRegenerate: boolean) => void;
    refetchDiffArchetype?: () => void;
  }
>(
  (
    {
      user,
      id,
      project,
      totalData,
      item,
      isDisabledSelectButton = false,
      isEdited = false,
      isFetching = true,
      totalSelected = 0,
      index = 0,
      isSelected = false,
      isEditing = false,
      isCanSelect = false,
      onSubmitSuccess,
      onSelectSuccess,
      refetchDiffArchetype,
    },
    ref,
  ) => {
    const { mutate: mutateSelected, isPending: isUpdatingSelected } =
      useUpdateSelectedArchetype();

    const { handleSubmit, reset, control } = useForm<Schema>({
      resolver: zodResolver(ArchetypeSchema),
      defaultValues: {
        archetype_number: item?.archetype_number.toString() ?? '',
        archetype_name: item?.archetype_name ?? '',
        behavior: item?.behavior ?? '',
        current_trends: item?.current_trends ?? '',
        demographics: item?.demographics ?? '',
        lifestyle: item?.lifestyle ?? '',
        needs_and_challenges: item?.needs_and_challenges ?? '',
        occupation: item?.occupation ?? '',
        online_search_behavior: item?.online_search_behavior ?? '',
        potential_buying_motives: item?.potential_buying_motives ?? '',
        preferred_sales_channels: item?.preferred_sales_channels ?? '',
        purchase_frequency: item?.purchase_frequency ?? '',
        source_of_information: item?.source_of_information ?? '',
      },
      mode: 'all',
    });

    const onSubmit = (data: Schema) => {
      if (
        id === undefined ||
        index === undefined ||
        onSubmitSuccess === undefined
      )
        return;

      onSubmitSuccess(data, id, index);
    };

    useEffect(() => {
      if (isEditing) {
        reset({
          archetype_number: item?.archetype_number.toString() ?? '',
          archetype_name: item?.archetype_name ?? '',
          behavior: item?.behavior ?? '',
          current_trends: item?.current_trends ?? '',
          demographics: item?.demographics ?? '',
          lifestyle: item?.lifestyle ?? '',
          needs_and_challenges: item?.needs_and_challenges ?? '',
          occupation: item?.occupation ?? '',
          online_search_behavior: item?.online_search_behavior ?? '',
          potential_buying_motives: item?.potential_buying_motives ?? '',
          preferred_sales_channels: item?.preferred_sales_channels ?? '',
          purchase_frequency: item?.purchase_frequency ?? '',
          source_of_information: item?.source_of_information ?? '',
        });
      }
    }, [isEditing, reset, item]);

    useImperativeHandle(
      ref,
      () => ({
        submitForm: () => {
          handleSubmit(onSubmit)();
        },
      }),
      [handleSubmit, onSubmit],
    );

    const handleSelected = (id: number) => {
      if (
        project === undefined ||
        user === undefined ||
        onSelectSuccess === undefined
      )
        return;

      const projectSlug = project.slug;

      triggerGTMEvent({
        event: `Select Audience Archetype`,
        eventCategory: `Button Select Audience Archetype Click`,
        eventAction: 'Click',
        eventLabel: 'Audience Archetype',
        userId: user.email,
        data: { type: isSelected ? 'Unselect' : 'Select', ...item },
      });

      mutateSelected(
        { id, projectSlug },
        {
          onSuccess: (res) => {
            const {
              is_idea_alignment_and_one_page_need_regenerate: isNeedRegenerate,
              data,
            } = res;
            onSelectSuccess(data.is_edited, isNeedRegenerate);
            refetchDiffArchetype?.();
          },
        },
      );
    };

    return (
      <div className="flex w-full flex-col pb-35 transition duration-500">
        <div className="px-35 py-15 text-center">
          {isFetching ? (
            <h1 className="mx-auto mb-14 h-20 w-3/12 animate-pulse rounded-full bg-soft-purple-redx" />
          ) : (
            <CardTitle
              id={id ?? 1}
              index={index}
              isEdited={isEdited}
              label="Audience Archetype"
              totalData={totalData ?? 1}
            />
          )}
        </div>
        <div
          className={`mb-16 grid grid-cols-3 gap-24 p-24 text-black-redx transition duration-500 md:grid-cols-1 ${
            isEditing && isSelected ? '!bg-transparent' : ''
          } ${
            isSelected
              ? 'border-t-2 border-blue-redx bg-softer-purple-redx'
              : 'border-t-1 border-stroke-redx'
          }`}
        >
          {fieldsToDisplay.map(({ name, label }) => (
            <Controller
              key={name}
              control={control}
              name={name as keyof Schema}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <CardInput
                    defaultValue={item?.[name as keyof Archetype] || ''}
                    error={error?.message}
                    isEditing={isEditing ?? false}
                    isFetching={isFetching}
                    onChange={onChange}
                    placeholder={`Input the ${label} here`}
                    title={label}
                    value={value}
                  />
                );
              }}
            />
          ))}
        </div>
        <div className="flex w-full justify-end px-1 pt-15">
          {id && id !== -1 && isCanSelect && (
            <SelectButton
              hasOne={isDisabledSelectButton && isSelected}
              index={index ?? 0}
              isDisabled={totalSelected === 3 && !isSelected}
              isEditing={isEditing ?? false}
              isLoading={isUpdatingSelected}
              isSelected={isSelected ?? false}
              onSelect={() => handleSelected(id)}
              section="audience-archetype"
            />
          )}
        </div>
      </div>
    );
  },
);

export default memo(Card);
