import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from '@headlessui/react';
import { Icon } from '@iconify/react/dist/iconify.js';

import Checkbox from '@/Components/Checkbox';
import { useRequestUserContext } from '@/Hooks/Administrator';
import type { RequestUserResponse } from '@/Types/Administrator';

interface TableRowProps {
  row: RequestUserResponse;
}

const TableRowRequestList = ({ row }: TableRowProps) => {
  const { checkPerRow, setCheckPerRow, handleUpdateStatus } =
    useRequestUserContext();

  return (
    <tr>
      <td className="border-b-1 border-stroke-redx px-16 py-30 text-center">
        <Checkbox
          checked={!!checkPerRow.find((id) => id === row.id)}
          onChange={(value) => setCheckPerRow(row.id, value)}
        />
      </td>

      <td className="border-b-1 border-stroke-redx px-16 py-30">
        <div className="flex flex-col items-start gap-8">
          <p className="text-14 font-normal leading-16">{row.email}</p>
        </div>
      </td>
      <td className="border-b-1 border-stroke-redx px-16 py-30">
        <p className="break-words text-14 font-normal leading-16">
          {row.requested_by_user?.name || row.name}
        </p>
      </td>
      <td className="border-b-1 border-stroke-redx px-16 py-30 text-14 font-normal leading-16">
        <p>
          {new Date(row.created_at).toLocaleDateString('id-ID', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          })}
        </p>
        <p>
          {new Date(row.created_at)
            .toLocaleTimeString('id-ID', {
              hour: '2-digit',
              minute: '2-digit',
            })
            .replace('.', ':')}
        </p>
      </td>
      <td className="border-b-1 border-stroke-redx px-16 py-30 text-14 font-normal leading-16">
        {row.project?.name}
      </td>
      <td className="border-b-1 border-stroke-redx px-16 py-30 text-14 font-normal leading-20">
        {row.reason?.length > 100
          ? `${row.reason?.substring(0, 100)}...`
          : row.reason}
      </td>

      <td className="border-b-1 border-stroke-redx px-16 py-30 text-center">
        <Menu as="div" className="relative inline-block text-left">
          <MenuButton className="gap-x-1.5 inline-flex w-full items-center justify-center rounded-md bg-white px-12 py-8 text-14 font-semibold leading-20 text-black-redx shadow-sm ring-1 ring-inset ring-stroke-redx hover:bg-gray-50 md:text-12">
            Action
            <Icon
              className="ml-6 size-19 text-black-redx"
              icon="lucide:chevron-down"
            />
          </MenuButton>
          <Transition
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <MenuItems className="absolute right-0 z-10 mt-4 flex w-155 flex-col gap-8 rounded-md bg-white p-16 shadow-lg ring-1 ring-black/5 focus:outline-none">
              <MenuItem>
                {({ focus }) => (
                  <button
                    className={`p-4 text-left text-14 leading-20 ${focus ? 'bg-gray-100' : ''} text-[#33B249]`}
                    onClick={() => handleUpdateStatus([row.id], 'approved')}
                  >
                    Accept
                  </button>
                )}
              </MenuItem>
              <MenuItem>
                {({ focus }) => (
                  <button
                    className={`p-4 text-left text-14 leading-20 ${focus ? 'bg-gray-100' : ''} text-error-redx`}
                    onClick={() => handleUpdateStatus([row.id], 'rejected')}
                  >
                    Reject
                  </button>
                )}
              </MenuItem>
            </MenuItems>
          </Transition>
        </Menu>
      </td>
    </tr>
  );
};

export default TableRowRequestList;
