import type { ReactNode } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useRoleLists } from '@/Hooks/Administrator/useRoleList';
import type { AdministratorRoleResponse } from '@/Types/Administrator';

import { RoleListContext } from './role-list-context';

export const SORT_BY = [
  { name: 'Name (A - Z)', value: 'name-asc' },
  { name: 'Name (Z - A)', value: 'name-desc' },
  { name: 'Date (Newest - Oldest)', value: 'created-desc' },
  { name: 'Date (Oldest - Newest)', value: 'created-asc' },
];

export const RoleListProvider = ({ children }: { children: ReactNode }) => {
  const [records, setRecords] = useState<AdministratorRoleResponse[]>([]);
  const [curPage, setCurPage] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [keyLine, setKeyFilterLine] = useState(+new Date());
  const observerRef = useRef<HTMLDivElement | null>(null);

  const [isObserverSet, setIsObserverSet] = useState(false);

  const setTargetRef = useCallback((ref: HTMLDivElement | null) => {
    if (ref) {
      observerRef.current = ref;
      setIsObserverSet(true); // Tandai bahwa observer telah di-set
    }
  }, []);

  const [checkPerRow, setCheckPerRow] = useState<
    AdministratorRoleResponse['id'][]
  >([]);

  const [selectedSortBy, setSelectedSortBy] = useState(SORT_BY[2].value);
  const [sortedProjects, setSortedProjects] = useState<
    AdministratorRoleResponse[]
  >([]);
  const [updateStatus, setUpdateStatus] = useState<{
    id: AdministratorRoleResponse['id'];
    status: string;
  }>({
    id: 0,
    status: '',
  });
  const [dataForm, setDataForm] = useState<{
    name: AdministratorRoleResponse['name'];
    displayName: AdministratorRoleResponse['display_name'];
  }>({
    name: '',
    displayName: '',
  });

  const {
    data,
    isPending,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useRoleLists({
    sort: selectedSortBy,
    search,
    date: keyLine,
    pageParam: curPage,
  });

  const removeDuplicates = (data: AdministratorRoleResponse[]) => {
    const uniqueMap = new Map();
    data.forEach((item) => {
      uniqueMap.set(item.id, item); // Jika id sudah ada, update dengan item terbaru
    });
    return Array.from(uniqueMap.values());
  };

  useEffect(() => {
    if (data?.pages) {
      const newLists = data.pages.flatMap((page) => page.data);

      setRecords((prev) => {
        let updatedRecords;
        if (search) {
          updatedRecords = newLists;
        } else {
          updatedRecords = removeDuplicates([...prev, ...newLists]);
        }

        return updatedRecords;
      });
    }
  }, [data?.pages, search]);

  useEffect(() => {
    const sorted = [...records];

    if (selectedSortBy === 'name-asc') {
      sorted.sort((a, b) => (a.name || '').localeCompare(b.name || ''));
    } else if (selectedSortBy === 'name-desc') {
      sorted.sort((a, b) => (b.name || '').localeCompare(a.name || ''));
    } else if (selectedSortBy === 'created-asc') {
      sorted.sort(
        (a, b) =>
          new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
      );
    } else if (selectedSortBy === 'created-desc') {
      sorted.sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
      );
    }

    setSortedProjects(sorted);
  }, [selectedSortBy, records]);

  useEffect(() => {
    setRecords([]);
    setKeyFilterLine(+new Date());
  }, []);

  const updatePage = useCallback(
    (newPage: number) => {
      setCurPage(newPage);
    },
    [setCurPage],
  );

  const handleShortBy = useCallback(
    (sortBy: string) => {
      setSelectedSortBy(sortBy);
    },
    [setSelectedSortBy],
  );

  const updateSearch = useCallback(
    (search: string) => {
      setSearch(search);
    },
    [setSearch],
  );

  const handleUpdateStatus = useCallback(
    (id: AdministratorRoleResponse['id'], status: string) => {
      setUpdateStatus({ id, status });
    },
    [],
  );

  const handleDataForm = useCallback(
    (
      name: AdministratorRoleResponse['name'],
      displayName: AdministratorRoleResponse['display_name'],
    ) => {
      setDataForm({ name, displayName });
    },
    [],
  );

  const handleRefetch = useCallback((isReset: boolean) => {
    if (isReset) {
      setUpdateStatus({ id: 0, status: '' });
      setCheckPerRow([]);
      setRecords([]);
    }
    setKeyFilterLine(+new Date());
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const target = entries[0];
        if (target.isIntersecting && hasNextPage && !isFetchingNextPage) {
          const lastPage = data?.pages[data.pages.length - 1];
          if (lastPage?.next === 999999999) {
            return;
          }
          fetchNextPage();
        }
      },
      {
        root: null, // Gunakan viewport sebagai root
        rootMargin: '0px',
        threshold: 0.1, // Callback dipicu saat 10% elemen loader terlihat
      },
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data?.pages,
    isObserverSet,
  ]);

  const value = useMemo(
    () => ({
      dataForm,
      updateStatus,
      hasNextPage,
      isFetchingNextPage,
      observerRef,
      records: sortedProjects,
      page: curPage,
      selectedSortBy,
      checkPerRow,
      isLoading: isPending || isLoading || isFetchingNextPage,
      setPage: updatePage,
      setSearch: updateSearch,
      setShortBy: handleShortBy,
      handleRefetch,
      handleUpdateStatus,
      handleDataForm,
      setTargetRef,
    }),
    [
      dataForm,
      updateStatus,
      hasNextPage,
      records,
      curPage,
      isPending,
      isLoading,
      isFetchingNextPage,
      observerRef,
      selectedSortBy,
      checkPerRow,
      sortedProjects,
      updatePage,
      handleUpdateStatus,
      handleDataForm,
      handleRefetch,
      updateSearch,
      handleShortBy,
      setTargetRef,
    ],
  );

  return (
    <RoleListContext.Provider value={value}>
      {children}
    </RoleListContext.Provider>
  );
};
