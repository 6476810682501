import React from 'react';
import toast from 'react-hot-toast';

import ModalCenter from '@/Components/Modals/Center';
import { useSubmitRole } from '@/Hooks/Administrator';
import { useRoleContext } from '@/Hooks/Administrator/useRoleContext';
import type { AdministratorRoleResponse } from '@/Types/Administrator';
import { cn } from '@/Utils/cn';

import SuccessUpdateAccessToast from './SuccessUpdateAccessToast';

interface UpdateConfirmModalProps {
  selectedRole: AdministratorRoleResponse | null;
  isOpen: boolean;
  onClose: () => void;
}

const UpdateConfirmationModal: React.FC<UpdateConfirmModalProps> = ({
  selectedRole,
  isOpen,
  onClose,
}) => {
  const {
    handleRefetch: refetch,
    handleUpdateStatus,
    dataForm,
    handleDataForm,
  } = useRoleContext();
  const { mutate, isPending } = useSubmitRole();
  const handleClose = () => {
    onClose();
  };

  const handleSubmit = () => {
    mutate(
      {
        ...(selectedRole?.id && { id: selectedRole.id }),
        name: dataForm.name,
        displayName: dataForm.displayName,
      },
      {
        onSuccess: () => {
          toast.custom((t) => (
            <SuccessUpdateAccessToast
              message="Role information has been successfully updated."
              t={t}
            />
          ));
          handleDataForm('', '');
          handleUpdateStatus(0, '');
          refetch(true);
        },
      },
    );
  };

  return (
    <ModalCenter isOpen={isOpen} onClose={handleClose}>
      <div className="max-w-lg p-35">
        <h1 className="text-24 font-semibold leading-32 text-black-redx md:text-18">
          Update this role?
        </h1>
        <p className="mt-16 text-14 font-normal leading-20 text-grey-redx">
          Your changes will automatically apply to all users with this role.
          Make sure the details are correct before proceeding.
        </p>

        <div className="mt-24 flex items-center justify-end gap-16 gap-x-10 md:flex-col">
          <button
            className="rounded-8 border-1 border-blue-redx px-16 py-8 text-blue-redx md:w-full"
            onClick={() =>
              selectedRole?.id !== undefined &&
              handleUpdateStatus(selectedRole.id, 'edit')
            }
          >
            Edit Again
          </button>
          <button
            className={cn(
              'rounded-8  border-1 bg-blue-redx px-16 py-8 text-white md:w-full',
              isPending ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer',
            )}
            disabled={isPending}
            onClick={handleSubmit}
          >
            Yes, Update
          </button>
        </div>
      </div>
    </ModalCenter>
  );
};

export default UpdateConfirmationModal;
