import { zodResolver } from '@hookform/resolvers/zod';
import { Icon } from '@iconify/react';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import NotDataFound from '@/Components/NotDataFound';
import EditButton from '@/Components/Projects/EditButton';
import SourcesList from '@/Components/Projects/SourcesList';
import { saveSubInformation } from '@/Context/Actions/project-actions';
import { ProjectContext } from '@/Context/ProjectContext';
import { useUpdateTargetAudience } from '@/Hooks/TargetAudience';
import type { SourcesType } from '@/Types/Projects';
import type {
  BehavioralTrendsProps,
  TargetAudienceItem,
} from '@/Types/TargetAudience';
import {
  AudienceBehavioralTrends,
  type AudienceBehavioralTrendsSchema,
} from '@/Types/TargetAudience/schema-audience-behavioral-trends';
import type { UserResponse } from '@/Types/user';
import { triggerGTMEvent } from '@/Utils/gtm';

import {
  createDifferenceChecker,
  jsonToMarkdown,
  markdownToJson,
  sanitizeContent,
} from '../Utils/utils';
import SectionData from './SectionData';

interface ContentBehavioralTrendSectionProps {
  id?: number;
  message: string;
  activeSection?: string;
  projectSlug?: string;
  user?: UserResponse;
  refetch?: () => void;
  isEditor?: boolean;
  isLoading?: boolean;
  isAudienceArchetypeQuerying?: boolean;
  content?: BehavioralTrendsProps;
  previousPayloadData?: BehavioralTrendsProps;
  sources?: SourcesType[];
  onEditingChange?: (isEditing: boolean) => void;
  setShowAlertGenerateModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ContentBehavioralTrendSection = ({
  id,
  message,
  projectSlug,
  activeSection,
  refetch,
  user,
  isAudienceArchetypeQuerying = false,
  isEditor = false,
  isLoading = false,
  content = {
    current_trends: [],
    consumption_behaviors: [],
  },
  previousPayloadData = {
    current_trends: [],
    consumption_behaviors: [],
  },
  sources = [],
  onEditingChange,
  setShowAlertGenerateModal,
}: ContentBehavioralTrendSectionProps) => {
  const navigate = useNavigate();
  const [, dispatch] = useContext(ProjectContext);
  const { mutate: updateTargetAudience } =
    useUpdateTargetAudience<BehavioralTrendsProps>();

  const [behavioralTrends, setBehavioralTrends] =
    useState<BehavioralTrendsProps>({
      current_trends: content.current_trends,
      consumption_behaviors: content.consumption_behaviors,
    });

  // Handler untuk mengupdate bagian tertentu
  const updateBehavioralTrends = (
    key: keyof BehavioralTrendsProps,
    value: TargetAudienceItem[],
  ) => {
    setBehavioralTrends((prev) => ({ ...prev, [key]: value }));
  };

  const [isEditing, setIsEditing] = useState(false);
  const isUpdating = false;

  const { setValue, getValues } = useForm<AudienceBehavioralTrendsSchema>({
    resolver: zodResolver(AudienceBehavioralTrends),
    defaultValues: {
      current_trends: '',
      consumption_behaviors: '',
    },
    mode: 'all',
  });

  const setDefaultValue = () => {
    setBehavioralTrends({
      current_trends: content.current_trends,
      consumption_behaviors: content.consumption_behaviors,
    });

    setValue('current_trends', jsonToMarkdown(content?.current_trends ?? []));
    setValue(
      'consumption_behaviors',
      jsonToMarkdown(content?.consumption_behaviors ?? []),
    );
  };

  useEffect(() => {
    setDefaultValue();
  }, [content]);

  const toggleIsEditing = () => {
    setIsEditing((prev) => {
      const newEditingState = !prev;
      if (onEditingChange) {
        onEditingChange(newEditingState);
      }
      return newEditingState;
    });
  };

  const handleContentChange = (
    section: 'consumption_behaviors' | 'current_trends',
    updatedContent: string,
  ) => {
    const json = markdownToJson(updatedContent);
    setValue(section, jsonToMarkdown(json));
    updateBehavioralTrends(section, json);
  };

  const handleSave = () => {
    if (!user || !id || !projectSlug || !activeSection) return;
    if (isAudienceArchetypeQuerying) {
      dispatch(
        saveSubInformation(
          '{text-error-redx} Please wait audience archetype is generating.',
        ),
      );

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    const formattedContent: BehavioralTrendsProps = {
      current_trends: markdownToJson(getValues('current_trends')),
      consumption_behaviors: markdownToJson(getValues('consumption_behaviors')),
    };

    triggerGTMEvent({
      event: `Edit Behavioral Trends`,
      eventCategory: `Edit Target Audience Behavioral Trends Click`,
      eventAction: 'Click',
      eventLabel: 'Behavioral Trends',
      userId: user.email,
      data: JSON.stringify(formattedContent),
    });

    const hasDifference = createDifferenceChecker<BehavioralTrendsProps>(
      ['current_trends', 'consumption_behaviors'],
      sanitizeContent,
    );

    // Panggil fungsi untuk membandingkan data saat ini dengan data sebelumnya
    const isDifferent = hasDifference(formattedContent, previousPayloadData);

    updateTargetAudience(
      {
        payload: formattedContent,
        historyId: id,
        projectSlug,
        activeSection,
        isDifferenceChecker: !isDifferent,
      },
      {
        onSuccess: () => {
          isDifferent && setShowAlertGenerateModal(true);
          toggleIsEditing();
          refetch?.();
        },
      },
    );
  };

  const handleCancelEdit = () => {
    setDefaultValue();
    toggleIsEditing();
  };

  if (message === 'No data found') {
    return (
      <NotDataFound
        buttonLabel="Go to Submission"
        description="Click the button below to generate a product research in Submission page."
        onClick={() => navigate(`/${projectSlug}/submission`)}
        title="No Data Found."
      />
    );
  }

  return (
    <>
      <div className="flex flex-col gap-24 text-black-redx">
        <SectionData
          content={behavioralTrends?.current_trends ?? []}
          isEditing={isEditing}
          isLoading={isLoading}
          onChange={(html) => handleContentChange('current_trends', html)}
          title="Current Trends"
        />
        <SectionData
          content={behavioralTrends?.consumption_behaviors ?? []}
          isEditing={isEditing}
          isLoading={isLoading}
          onChange={(html) =>
            handleContentChange('consumption_behaviors', html)
          }
          title="Consumption Behavior"
        />
      </div>

      <div className="my-24 flex w-full items-center justify-end gap-x-15">
        {isEditor &&
          (isEditing ? (
            <>
              <button
                className="inline-flex cursor-pointer items-center gap-x-4 text-error-redx"
                onClick={handleCancelEdit}
              >
                <span className="text-15 font-semibold">Cancel</span>
                <Icon className="size-20" icon="lucide:x" />
              </button>
              <button
                className={`inline-flex items-center gap-x-4 ${
                  isUpdating ? 'text-gray-600' : 'text-blue-redx'
                } cursor-pointer`}
                disabled={isUpdating}
                id="btn-edit-audience-insight"
                onClick={handleSave}
              >
                <span className="text-15 font-semibold">Save</span>
                <Icon className="size-20" icon="lucide:save" />
              </button>
            </>
          ) : (
            <EditButton toggleEditing={toggleIsEditing} />
          ))}
      </div>

      <div className="mr-1 flex w-full pb-60">
        {!isLoading && sources && <SourcesList sources={sources} />}
        {isLoading && (
          <div className="inline-flex gap-10">
            {Array.from({ length: 3 }).map((_, index) => (
              <div
                key={index}
                className="full flex h-65 min-w-363 animate-pulse items-center gap-x-12 rounded-10 border-1 border-soft-purple-redx bg-soft-purple-redx px-15 py-12"
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default ContentBehavioralTrendSection;
