import { RequestListProvider } from '../../Context/request-list-provider';
import CtaActions from './CtaAction';
import FilterAndSort from './FilterAndSort';
import TableRequestList from './Table';

const TabRequestList = () => {
  return (
    <RequestListProvider>
      <div className="flex size-full grow flex-col gap-24">
        <FilterAndSort />
        <TableRequestList />
      </div>
      <CtaActions />
    </RequestListProvider>
  );
};
export default TabRequestList;
