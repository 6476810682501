/* eslint-disable react/no-danger */
import { Icon } from '@iconify/react';
import toast from 'react-hot-toast';

interface FailedToastProps {
  message: string;
  t: {
    id: string;
    visible: boolean;
  };
}

const FailedToast = ({ message, t }: FailedToastProps) => {
  return (
    <div
      className="mb-4 inline-flex items-center rounded-lg bg-danger-redx px-20 py-10 text-10 text-white"
      style={{
        opacity: t.visible ? 1 : 0,
        transition: 'opacity 100ms ease-in-out',
      }}
    >
      <p
        className="leading-22 mr-10 text-16"
        dangerouslySetInnerHTML={{ __html: message }}
      />
      <Icon
        className="size-15 cursor-pointer"
        icon="mdi:close"
        onClick={() => toast.remove()}
      >
        X
      </Icon>
    </div>
  );
};

export default FailedToast;
