import Cookies from 'js-cookie';
import _ from 'lodash';
import React, { memo, useCallback, useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';

import ProjectFooter from '@/Components/TopMenu/ProjectFooter';
import ProjectHead from '@/Components/TopMenu/ProjectHead';
import Sidebar from '@/Components/TopMenu/Sidebar';
import { ModalRegenerateProvider } from '@/Context/ModalRegenerate';
import { ProjectContext } from '@/Context/ProjectContext';
import { SidebarProvider } from '@/Context/SidebarContext';
import { useProjectDetail } from '@/Hooks/Project';
import { useUser } from '@/Hooks/useUser';
import AuthenticatedLayout from '@/Layouts/AuthenticatedLayout';

import { saveProjectData } from '../Context/Actions/project-actions';

interface ProjectLayoutProps {
  children: React.ReactNode;
}

// eslint-disable-next-line react/prop-types
const ProjectLayout: React.FC<ProjectLayoutProps> = memo(({ children }) => {
  const navigate = useNavigate();
  const [, dispatch] = React.useContext(ProjectContext);
  const params = useParams<{ project_slug: string }>();
  const {
    data: project,
    isPending,
    refetch,
  } = useProjectDetail(params?.project_slug ?? '');

  const { data: user, isLoading: userLoading } = useUser();

  // Handle project validation and dispatch
  useEffect(() => {
    if (!isPending && _.isEmpty(project)) {
      navigate('/404');
    }

    if (
      !isPending &&
      project?.owner.id !== user?.id &&
      !project?.users.find(({ id }) => id === user?.id)
    ) {
      Cookies.set('project_found', 'false', { expires: 1 / 2880 });
      Cookies.set('project_owner_name', project?.owner.name || '', {
        expires: 1 / 2880,
      });
      Cookies.set('project_owner_email', project?.owner.email || '', {
        expires: 1 / 2880,
      });
      navigate(`/projects`);
    }

    if (project) {
      dispatch(saveProjectData(project));
    }
  }, [project, isPending, userLoading]);

  const renderContent = useCallback(() => {
    if (isPending || _.isEmpty(project)) return null;

    return (
      <>
        <Sidebar project={project} />
        <div className="ml-0 size-full max-h-full lg:pl-[25%]">
          <div className="z-10 w-full px-20 pb-40 pt-73">
            <ProjectHead onRefetchProject={refetch} project={project} />
            <div className="pb-80 lg:pb-0">{children}</div>
            <ProjectFooter />
          </div>
        </div>
      </>
    );
  }, [isPending, project, refetch, children]);

  return (
    <SidebarProvider>
      <ModalRegenerateProvider>
        <AuthenticatedLayout project={project}>
          {renderContent()}
        </AuthenticatedLayout>
      </ModalRegenerateProvider>

      <Toaster
        containerStyle={{
          bottom: 120,
          left: 240,
        }}
        position="bottom-center"
        toastOptions={{
          duration: 5000,
        }}
      />
    </SidebarProvider>
  );
});

export default ProjectLayout;
