import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  saveRouteNext,
  saveSubInformation,
} from '@/Context/Actions/project-actions';
import { ProjectContext } from '@/Context/ProjectContext';
import type { RouteOption } from '@/Context/Reducer/project-reducer';
import type { ProjectProps } from '@/Types/Projects';

import { useHistoryStatus } from '../useHistoryStatus';

interface UseCtaParams {
  isError: boolean;
  isEditor: boolean;
  isEditing: boolean;
  isEdited: boolean;
  project: ProjectProps;
  routeOptionValue: RouteOption;
  message: string;
  activeSection: string;
  setShowViewInfoModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowRegenerateModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const useCtaAction = ({
  isError,
  isEditor,
  isEditing,
  project,
  isEdited,
  routeOptionValue,
  activeSection,
  message,
  setShowViewInfoModal,
  setShowRegenerateModal,
}: UseCtaParams) => {
  const {
    isTargetAudienceAudienceInsight,
    isTargetAudienceBehavioralTrend,
    isTargetAudienceMediaConsumptionPattern,
    isTargetAudiencePerceptionAnalysis,
    isMarketResearchDemand,
    isMarketResearchTrend,
    isKeyInsightsComplete,
  } = useHistoryStatus(project);
  const projectSlug = project.slug;

  const [, dispatch] = useContext(ProjectContext);
  const navigate = useNavigate();

  const memoizedRouteOptionValue = useMemo(
    () => ({ ...routeOptionValue }),
    [routeOptionValue],
  );

  const handleEditorClick = useCallback(() => {
    if (isMarketResearchDemand.isQuerying || isMarketResearchTrend.isQuerying) {
      dispatch(
        saveSubInformation(
          '{text-error-redx} Please wait market research is generating.',
        ),
      );

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    if (isEditing) {
      dispatch(saveSubInformation('{text-error-redx} Please do save first.'));

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    if (
      isTargetAudienceAudienceInsight.isQuerying ||
      isTargetAudienceMediaConsumptionPattern.isQuerying ||
      isTargetAudienceBehavioralTrend.isQuerying ||
      isTargetAudiencePerceptionAnalysis.isQuerying
    ) {
      dispatch(
        saveSubInformation(
          '{text-error-redx} Please wait target audience is generating.',
        ),
      );

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    if (
      (!isTargetAudienceAudienceInsight.status &&
        !isTargetAudienceAudienceInsight.isQuerying &&
        !isTargetAudienceAudienceInsight.isError) ||
      (!isTargetAudienceMediaConsumptionPattern.status &&
        !isTargetAudienceMediaConsumptionPattern.isQuerying &&
        !isTargetAudienceMediaConsumptionPattern.isError) ||
      (!isTargetAudienceBehavioralTrend.status &&
        !isTargetAudienceBehavioralTrend.isQuerying &&
        !isTargetAudienceBehavioralTrend.isError) ||
      (!isTargetAudiencePerceptionAnalysis.status &&
        !isTargetAudiencePerceptionAnalysis.isQuerying &&
        !isTargetAudiencePerceptionAnalysis.isError)
    ) {
      dispatch(
        saveSubInformation(
          `{text-error-redx} You don't have generated Target Information data.`,
        ),
      );

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    if (isEdited) {
      setShowRegenerateModal(true);
      return;
    }

    navigate(`/${projectSlug}/target-audience`);
  }, [
    isTargetAudienceAudienceInsight.status,
    isTargetAudienceMediaConsumptionPattern.status,
    isTargetAudiencePerceptionAnalysis.status,
    isTargetAudienceBehavioralTrend.status,
    isEditing,
    isEdited,
    isMarketResearchDemand.isQuerying,
    isMarketResearchTrend.isQuerying,
    isTargetAudienceAudienceInsight.isQuerying,
    isTargetAudienceMediaConsumptionPattern.isQuerying,
    isTargetAudienceBehavioralTrend.isQuerying,
    isTargetAudiencePerceptionAnalysis.isQuerying,
  ]);

  const handleNonEditorClick = useCallback(() => {
    if (
      !isTargetAudienceAudienceInsight.status ||
      !isTargetAudienceMediaConsumptionPattern.status ||
      !isTargetAudienceBehavioralTrend.status ||
      !isTargetAudiencePerceptionAnalysis.status
    ) {
      setShowViewInfoModal(true);
      return;
    }

    if (isMarketResearchDemand.isQuerying || isMarketResearchTrend.isQuerying) {
      dispatch(
        saveSubInformation(
          '{text-error-redx} Please wait market research is generating.',
        ),
      );

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    if (
      isTargetAudienceAudienceInsight.isQuerying ||
      isTargetAudienceMediaConsumptionPattern.isQuerying ||
      isTargetAudienceBehavioralTrend.isQuerying ||
      isTargetAudiencePerceptionAnalysis.isQuerying
    ) {
      dispatch(
        saveSubInformation(
          '{text-error-redx} Please wait target audience is generating.',
        ),
      );

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    navigate(`/${projectSlug}/target-audience`);
  }, [
    isTargetAudienceAudienceInsight.status,
    isTargetAudienceMediaConsumptionPattern.status,
    isTargetAudienceBehavioralTrend.status,
    isTargetAudiencePerceptionAnalysis.status,
    isTargetAudienceAudienceInsight.isQuerying,
    isTargetAudienceMediaConsumptionPattern.isQuerying,
    isTargetAudienceBehavioralTrend.isQuerying,
    isTargetAudiencePerceptionAnalysis.isQuerying,
    isEditing,
    isMarketResearchDemand.isQuerying,
    isMarketResearchTrend.isQuerying,
  ]);

  useEffect(() => {
    memoizedRouteOptionValue.isActive = message !== 'No data found';
    const hasErrors = [
      isTargetAudienceAudienceInsight,
      isTargetAudienceMediaConsumptionPattern,
      isTargetAudienceBehavioralTrend,
      isTargetAudiencePerceptionAnalysis,
      isMarketResearchDemand,
      isMarketResearchTrend,
    ].some((research) => research.isError);

    const isQuerying = [
      isTargetAudienceAudienceInsight,
      isTargetAudienceMediaConsumptionPattern,
      isTargetAudienceBehavioralTrend,
      isTargetAudiencePerceptionAnalysis,
      isMarketResearchDemand,
      isMarketResearchTrend,
    ].some((research) => research.isQuerying);

    memoizedRouteOptionValue.isInactive = hasErrors
      ? false
      : !isTargetAudienceAudienceInsight.status ||
        !isTargetAudienceMediaConsumptionPattern.status ||
        !isTargetAudienceBehavioralTrend.status ||
        !isTargetAudiencePerceptionAnalysis.status ||
        isQuerying ||
        isEditing;

    if (isEditor) {
      if (isEdited) {
        memoizedRouteOptionValue.label = 'Update Other Pages';
        memoizedRouteOptionValue.isGenerate = true;
      }
      memoizedRouteOptionValue.onClick = handleEditorClick;
    } else {
      memoizedRouteOptionValue.onClick = handleNonEditorClick;
    }

    dispatch(saveRouteNext(memoizedRouteOptionValue));
  }, [
    isError,
    isKeyInsightsComplete.isQuerying,
    isTargetAudienceAudienceInsight.status,
    isTargetAudienceAudienceInsight.isQuerying,
    isTargetAudienceMediaConsumptionPattern.status,
    isTargetAudienceMediaConsumptionPattern.isQuerying,
    isTargetAudienceBehavioralTrend.status,
    isTargetAudienceBehavioralTrend.isQuerying,
    isTargetAudiencePerceptionAnalysis.status,
    isTargetAudiencePerceptionAnalysis.isQuerying,
    isEditing,
    isEdited,
    isMarketResearchDemand.isQuerying,
    isMarketResearchTrend.isQuerying,
    activeSection,
    message,
  ]);
};

export { useCtaAction };
