/* eslint-disable @typescript-eslint/no-throw-literal */
import axios from 'axios';
import Cookies from 'js-cookie';

const removeTrailingSlash = (url: string): string => url.replace(/\/+$/, '');

// Create an instance of axios
const apiGo = axios.create({
  baseURL: '/api-go/v1/api/',
  withCredentials: true,
});

// Add a request interceptor to include the Bearer token and default headers
apiGo.interceptors.request.use(
  (config) => {
    const token = Cookies.get('access_token');
    config.headers['Content-Type'] = 'application/json';

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// Add a response interceptor for error handling
apiGo.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      Cookies.remove('access_token');
      Cookies.remove('access_token_ai');
      if (window.location.pathname !== '/login') {
        localStorage.setItem('redirect_url', window.location.href);
        window.location.href = '/login';
      }
    }

    if (error.response && error.response.status === 404) {
      window.location.href = '/404';
    }

    return Promise.reject(error);
  },
);

export const goFetcher = {
  get: (url: string) => apiGo.get(removeTrailingSlash(url)),
  post: (url: string, data: any) => apiGo.post(removeTrailingSlash(url), data),
  put: (url: string, data: any) => apiGo.put(removeTrailingSlash(url), data),
  delete: (url: string, data: any) =>
    apiGo.delete(removeTrailingSlash(url), { data }),
  patch: (url: string, data: any) =>
    apiGo.patch(removeTrailingSlash(url), data),
};
export const defaultQueryFn = async ({ queryKey }: any) => {
  try {
    const { data } = await apiGo.get(queryKey[0], { params: queryKey[1] });
    return data;
  } catch (err) {
    if (axios.isAxiosError(err)) throw err.response;
    throw err;
  }
};

export default apiGo;
