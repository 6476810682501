import { useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

import { ProjectContext } from '@/Context/ProjectContext';
import AuthenticatedLayout from '@/Layouts/AuthenticatedLayout';

import Index from './Index';

const Wrapper = () => {
  const [state] = useContext(ProjectContext);
  const user = useMemo(() => state.user, [state.user]);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!user || user?.role?.name !== 'admin') {
      navigate('/projects');
      return;
    }

    setLoading(false);
  }, [user]);

  if (loading) {
    return (
      <AuthenticatedLayout>
        <div className="flex items-center justify-center">
          <div className="flex items-center space-x-9">
            <div className="size-25 animate-spin rounded-full border-1 border-blue-500 border-t-transparent" />
            <p className="text-lg font-semibold text-gray-600">Loading...</p>
          </div>
        </div>
      </AuthenticatedLayout>
    );
  }

  return (
    <>
      <Helmet>
        <title>Administrator</title>
      </Helmet>
      <AuthenticatedLayout>
        <Index />
      </AuthenticatedLayout>
    </>
  );
};

export default Wrapper;
