import Table from '@/Components/Table';
import { useRoleContext } from '@/Hooks/Administrator/useRoleContext';

import DetailModal from './DetailModal';
import FormModal from './FormModal';
import TableRowRequestList from './TableRow';
import UpdateConfirmationModal from './UpdateConfirmationModal';

const COLUMNS = [
  { title: 'Name', subtitle: 'Display Name' },
  { title: 'Activation Date' },
  { title: 'Status' },
  { title: '' },
];

const TableList = () => {
  const {
    records,
    hasNextPage,
    isFetchingNextPage,
    updateStatus,
    isLoading,
    setTargetRef,
    handleUpdateStatus,
  } = useRoleContext();

  const isDetail = updateStatus.status === 'detail';
  const isEdit = updateStatus.status === 'edit';
  const isConfirmEdit = updateStatus.status === 'confirm_edit';

  if (!isLoading && records.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center">
        <img
          alt="Empty-amico 1.png"
          className="size-400"
          src="assets/img/Empty-amico 1.png"
        />
        <div className="flex flex-col items-center justify-items-center gap-20">
          <h2 className="text-32 font-semibold leading-[36px] text-black-redx md:text-24">
            Data not found
          </h2>
          <p className="text-center text-16 font-normal leading-20 text-grey-redx">
            There’s currently no information available here.
          </p>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="border max-h-[calc(100vh-300px)] w-full overflow-auto scrollbar-hide md:max-w-sm">
        <Table columns={COLUMNS}>
          {records.map((record) => (
            <TableRowRequestList key={record.id} row={record} />
          ))}
        </Table>
        {hasNextPage && (
          <div
            ref={(el) => {
              if (el) {
                setTargetRef(el);
              }
            }}
            className="p-24 text-center"
          >
            {isFetchingNextPage ? (
              <p className="font-medium">Loading more data...</p>
            ) : null}
          </div>
        )}
      </div>
      <DetailModal
        isOpen={isDetail}
        onClose={() => handleUpdateStatus(0, '')}
        selectedRole={records.find((record) => record.id === updateStatus.id)}
      />
      <FormModal
        isOpen={isEdit}
        onClose={() => handleUpdateStatus(0, '')}
        selectedRole={
          records.find((record) => record.id === updateStatus.id) ?? null
        }
        title="Edit Role"
      />
      <UpdateConfirmationModal
        isOpen={isConfirmEdit}
        onClose={() => handleUpdateStatus(0, '')}
        selectedRole={
          records.find((record) => record.id === updateStatus.id) ?? null
        }
      />
    </>
  );
};

export default TableList;
