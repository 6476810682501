import { zodResolver } from '@hookform/resolvers/zod';
import { Icon } from '@iconify/react';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import NotDataFound from '@/Components/NotDataFound';
import EditButton from '@/Components/Projects/EditButton';
import SourcesList from '@/Components/Projects/SourcesList';
import { saveSubInformation } from '@/Context/Actions/project-actions';
import { ProjectContext } from '@/Context/ProjectContext';
import { useUpdateTargetAudience } from '@/Hooks/TargetAudience';
import type { SourcesType } from '@/Types/Projects';
import type {
  PerceptionAnalysisProps,
  TargetAudienceItem,
} from '@/Types/TargetAudience';
import {
  AudiencePerceptionAnalysis,
  type AudiencePerceptionAnalysisSchema,
} from '@/Types/TargetAudience/schema-audience-perception-analysis';
import type { UserResponse } from '@/Types/user';
import { triggerGTMEvent } from '@/Utils/gtm';

import {
  createDifferenceChecker,
  jsonToMarkdown,
  markdownToJson,
  sanitizeContent,
} from '../Utils/utils';
import SectionData from './SectionData';

interface ContentPerceptionAnalysisSectionProps {
  id?: number;
  message?: string;
  activeSection?: string;
  projectSlug?: string;
  user?: UserResponse;
  refetch?: () => void;
  isEditor?: boolean;
  isLoading?: boolean;
  isAudienceArchetypeQuerying?: boolean;
  content?: PerceptionAnalysisProps;
  previousPayloadData?: PerceptionAnalysisProps;
  sources?: SourcesType[];
  onEditingChange?: (isEditing: boolean) => void;
  setShowAlertGenerateModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ContentPerceptionAnalysisSection = ({
  id,
  projectSlug,
  activeSection,
  refetch,
  message,
  user,
  isAudienceArchetypeQuerying = false,
  isEditor = false,
  isLoading = false,
  content = {
    brand_perception: [],
    sector_perception: [],
    product_category_perception: [],
  },
  previousPayloadData = {
    brand_perception: [],
    sector_perception: [],
    product_category_perception: [],
  },
  sources = [],
  onEditingChange,
  setShowAlertGenerateModal,
}: ContentPerceptionAnalysisSectionProps) => {
  const navigate = useNavigate();
  const [, dispatch] = useContext(ProjectContext);
  const { mutate: updateTargetAudience } =
    useUpdateTargetAudience<PerceptionAnalysisProps>();

  const [perceptionAnalysis, setPerceptionAnalysis] =
    useState<PerceptionAnalysisProps>({
      brand_perception: content.brand_perception,
      product_category_perception: content.product_category_perception,
      sector_perception: content.sector_perception,
    });

  // Handler untuk mengupdate bagian tertentu
  const updatePerceptionAnalysis = (
    key: keyof PerceptionAnalysisProps,
    value: TargetAudienceItem[],
  ) => {
    setPerceptionAnalysis((prev) => ({ ...prev, [key]: value }));
  };

  const [isEditing, setIsEditing] = useState(false);
  const isUpdating = false;

  const { setValue, getValues } = useForm<AudiencePerceptionAnalysisSchema>({
    resolver: zodResolver(AudiencePerceptionAnalysis),
    defaultValues: {
      brand_perception: '',
      product_category_perception: '',
      sector_perception: '',
    },
    mode: 'all',
  });

  const setDefaultValue = () => {
    setPerceptionAnalysis({
      brand_perception: content.brand_perception,
      product_category_perception: content.product_category_perception,
      sector_perception: content.sector_perception,
    });

    setValue(
      'brand_perception',
      jsonToMarkdown(content?.brand_perception ?? []),
    );
    setValue(
      'product_category_perception',
      jsonToMarkdown(content?.product_category_perception ?? []),
    );
    setValue(
      'sector_perception',
      jsonToMarkdown(content?.sector_perception ?? []),
    );
  };

  useEffect(() => {
    setDefaultValue();
  }, [content]);

  const toggleIsEditing = () => {
    setIsEditing((prev) => {
      const newEditingState = !prev;
      if (onEditingChange) {
        onEditingChange(newEditingState);
      }
      return newEditingState;
    });
  };

  const handleContentChange = (
    section:
      | 'product_category_perception'
      | 'sector_perception'
      | 'brand_perception',
    updatedContent: string,
  ) => {
    const json = markdownToJson(updatedContent);
    setValue(section, jsonToMarkdown(json));
    updatePerceptionAnalysis(section, json);
  };

  const handleSave = () => {
    if (!user || !id || !projectSlug || !activeSection) return;
    if (isAudienceArchetypeQuerying) {
      dispatch(
        saveSubInformation(
          '{text-error-redx} Please wait audience archetype is generating.',
        ),
      );

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    const formattedContent: PerceptionAnalysisProps = {
      product_category_perception: markdownToJson(
        getValues('product_category_perception'),
      ),
      sector_perception: markdownToJson(getValues('sector_perception')),
      brand_perception: markdownToJson(getValues('brand_perception')),
    };

    triggerGTMEvent({
      event: `Edit Perception Analysis`,
      eventCategory: `Edit Target Audience Perception Analysis Click`,
      eventAction: 'Click',
      eventLabel: 'Perception Analysis',
      userId: user.email,
      data: JSON.stringify(formattedContent),
    });

    const hasDifference = createDifferenceChecker<PerceptionAnalysisProps>(
      ['product_category_perception', 'sector_perception', 'brand_perception'],
      sanitizeContent,
    );

    // Panggil fungsi untuk membandingkan data saat ini dengan data sebelumnya
    const isDifferent = hasDifference(formattedContent, previousPayloadData);

    updateTargetAudience(
      {
        payload: formattedContent,
        historyId: id,
        projectSlug,
        activeSection,
        isDifferenceChecker: !isDifferent,
      },
      {
        onSuccess: () => {
          isDifferent && setShowAlertGenerateModal(true);
          toggleIsEditing();
          refetch?.();
        },
      },
    );
  };

  const handleCancelEdit = () => {
    setDefaultValue();
    toggleIsEditing();
  };

  if (message === 'No data found') {
    return (
      <NotDataFound
        buttonLabel="Go to Submission"
        description="Click the button below to generate a product research in Submission page."
        onClick={() => navigate(`/${projectSlug}/submission`)}
        title="No Data Found."
      />
    );
  }

  return (
    <>
      <div className="flex flex-col gap-24 text-black-redx">
        <SectionData
          content={perceptionAnalysis?.brand_perception ?? []}
          isEditing={isEditing}
          isLoading={isLoading}
          onChange={(html) => handleContentChange('brand_perception', html)}
          title="Brand Perception"
        />
        <SectionData
          content={perceptionAnalysis?.sector_perception ?? []}
          isEditing={isEditing}
          isLoading={isLoading}
          onChange={(html) => handleContentChange('sector_perception', html)}
          title="Sector Perception"
        />
        <SectionData
          content={perceptionAnalysis?.product_category_perception ?? []}
          isEditing={isEditing}
          isLoading={isLoading}
          onChange={(html) =>
            handleContentChange('product_category_perception', html)
          }
          title="Product Category Perception"
        />
      </div>

      <div className="my-24 flex w-full items-center justify-end gap-x-15">
        {isEditor &&
          (isEditing ? (
            <>
              <button
                className="inline-flex cursor-pointer items-center gap-x-4 text-error-redx"
                onClick={handleCancelEdit}
              >
                <span className="text-15 font-semibold">Cancel</span>
                <Icon className="size-20" icon="lucide:x" />
              </button>
              <button
                className={`inline-flex items-center gap-x-4 ${
                  isUpdating ? 'text-gray-600' : 'text-blue-redx'
                } cursor-pointer`}
                disabled={isUpdating}
                id="btn-edit-audience-insight"
                onClick={handleSave}
              >
                <span className="text-15 font-semibold">Save</span>
                <Icon className="size-20" icon="lucide:save" />
              </button>
            </>
          ) : (
            <EditButton toggleEditing={toggleIsEditing} />
          ))}
      </div>

      <div className="mr-1 flex w-full pb-60">
        {!isLoading && sources && <SourcesList sources={sources} />}
        {isLoading && (
          <div className="inline-flex gap-10">
            {Array.from({ length: 3 }).map((_, index) => (
              <div
                key={index}
                className="full flex h-65 min-w-363 animate-pulse items-center gap-x-12 rounded-10 border-1 border-soft-purple-redx bg-soft-purple-redx px-15 py-12"
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default ContentPerceptionAnalysisSection;
