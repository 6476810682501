/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { Fragment, useEffect, useState } from 'react';

import type { SubmissionStatusResponse } from '@/Types/Submission';

const Breadcrumb: React.FC<{
  list: string[];
  index: number;
  submissionStatus: SubmissionStatusResponse;
  onBreadCrumbClick: (index: number) => void;
}> = ({ list, index, onBreadCrumbClick, submissionStatus }) => {
  const [activeSection, setActiveSection] = useState(list[index]);

  const routeMap: Record<number, string> = {
    0: 'is_product_information_complete',
    1: 'is_market_information_complete',
    2: 'is_audience_information_complete',
    3: 'is_competitor_information_complete',
    4: 'is_objective_information_complete',
  };

  useEffect(() => {
    setActiveSection(list[index]);
  }, [index]);

  return (
    <nav aria-label="Breadcrumb" className="flex justify-between pb-24">
      <ol className="mb-3 inline-flex items-center gap-x-8 overflow-scroll  text-12 font-normal text-grey-redx scrollbar-hide sm:mb-0">
        {list.map((section, sectionIndex) => {
          const routeName = routeMap[sectionIndex];
          const routePrevious =
            sectionIndex - 1 >= 0 ? routeMap[sectionIndex - 1] : false;
          const previousStatus = routePrevious
            ? submissionStatus[routePrevious as keyof SubmissionStatusResponse]
            : false;

          return (
            <Fragment key={sectionIndex}>
              <li>
                <div
                  className={`flex items-center text-nowrap ${
                    activeSection === section
                      ? 'font-semibold text-blue-redx'
                      : sectionIndex > index
                        ? 'text-grey-redx'
                        : 'text-blue-redx'
                  } ${
                    submissionStatus[
                      routeName as keyof SubmissionStatusResponse
                    ] ||
                    activeSection === section ||
                    previousStatus
                      ? 'cursor-pointer'
                      : 'cursor-not-allowed'
                  }`}
                  onClick={
                    activeSection === section || previousStatus
                      ? () => onBreadCrumbClick(sectionIndex)
                      : () => {}
                  }
                >
                  {section}
                </div>
              </li>
              {sectionIndex !== list.length - 1 && <span>/</span>}
            </Fragment>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
