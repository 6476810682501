import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  saveRouteNext,
  saveSubInformation,
} from '@/Context/Actions/project-actions';
import { ProjectContext } from '@/Context/ProjectContext';
import type { RouteOption } from '@/Context/Reducer/project-reducer';
import type { HistoryStatus } from '@/Types/history';
import type { OnePageStrategy } from '@/Types/OnePage/one-page-strategy';
import type { ProjectProps } from '@/Types/Projects';
import { removeAllArchetypesBasedOnSectionFromLocalStorage } from '@/Utils/persist-progress';

import { useDifferentArchetypes } from '../AudienceArchetype';
import { useGenerate } from '../useGenerate';

interface UseCtaParams {
  isOnePageStrategy: HistoryStatus | undefined;
  isKeyTouchPoint: HistoryStatus | undefined;
  content: OnePageStrategy;
  message: string;
  isEditor: boolean;
  isEdited: boolean;
  isEditing: boolean;
  project: ProjectProps;
  routeOptionValue: RouteOption;
  activeSection: string;
  listArchetypeKeyTouchPoints: number[];
  setShowRegenerateModal: React.Dispatch<React.SetStateAction<boolean>>;
  setModalFramework: React.Dispatch<React.SetStateAction<boolean>>;
  setShowViewInfoModal: React.Dispatch<React.SetStateAction<boolean>>;
  setRouteOption: React.Dispatch<React.SetStateAction<RouteOption>>;
}

const useCtaAction = ({
  isOnePageStrategy,
  isKeyTouchPoint,
  content,
  message,
  isEditor,
  isEditing,
  isEdited,
  project,
  routeOptionValue,
  activeSection,
  listArchetypeKeyTouchPoints,
  setShowRegenerateModal,
  setModalFramework,
  setShowViewInfoModal,
  setRouteOption,
}: UseCtaParams) => {
  const [, dispatch] = useContext(ProjectContext);
  const navigate = useNavigate();
  const projectSlug = project.slug;

  const { data } = useDifferentArchetypes(project.slug);
  const { mutate: generate } = useGenerate();

  const memoizedRouteOptionValue = useMemo(
    () => ({ ...routeOptionValue }),
    [routeOptionValue],
  );

  const handleEditorClick = useCallback(async () => {
    if (isOnePageStrategy?.isQuerying) {
      dispatch(
        saveSubInformation(
          '{text-error-redx} Please wait one page is generating.',
        ),
      );

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    if (isEditing) {
      dispatch(saveSubInformation('{text-error-redx} Please do save first.'));

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    if (isKeyTouchPoint?.isQuerying) {
      dispatch(
        saveSubInformation(
          `{text-error-redx} Please wait, key touch point is still generating`,
        ),
      );

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    if (isEdited) {
      setShowRegenerateModal(true);
      return;
    }

    if (
      (data?.is_different_archetype.key_touch_points &&
        isKeyTouchPoint?.status) ||
      isKeyTouchPoint?.isRegenerated ||
      listArchetypeKeyTouchPoints.length > 0
    ) {
      dispatch(
        saveRouteNext({ ...memoizedRouteOptionValue, isDisabled: true }),
      );
      removeAllArchetypesBasedOnSectionFromLocalStorage(
        project.slug,
        project.submissions[0].selected_framework,
      );
      generate(
        {
          payload: {
            generate_more: '',
            regenerate_prompt: '',
            ...(listArchetypeKeyTouchPoints.length > 0 && {
              status: 'regenerate per archetype from one page',
              archetype_id: listArchetypeKeyTouchPoints,
            }),
          },
          projectSlug: project.slug,
          section: 'key-touch-point',
        },
        {
          onSuccess: () => {
            navigate(`/${project.slug}/key-touch-point`);
          },
        },
      );
      dispatch(
        saveRouteNext({ ...memoizedRouteOptionValue, isDisabled: false }),
      );
      return;
    }

    if (!isKeyTouchPoint?.status) {
      setModalFramework(true);
      return;
    }

    navigate(`/${projectSlug}/key-touch-point`);
  }, [
    listArchetypeKeyTouchPoints.length,
    isEditing,
    memoizedRouteOptionValue,
    isOnePageStrategy,
    isKeyTouchPoint,
    data?.is_different_archetype.key_touch_points,
    isEdited,
  ]);

  const handleNonEditorClick = useCallback(() => {
    if (!isKeyTouchPoint?.status || isKeyTouchPoint?.isRegenerated) {
      setShowViewInfoModal(true);
      return;
    }

    if (isOnePageStrategy?.isQuerying) {
      dispatch(
        saveSubInformation(
          '{text-error-redx} Please wait one page is generating.',
        ),
      );

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    navigate(`/${projectSlug}/key-touch-point`);
  }, [isEditing, isKeyTouchPoint, isOnePageStrategy]);

  const selectedArchetypeNumbers = useMemo(() => {
    return (
      content?.selected_audience_archetypes
        ?.filter((item) =>
          listArchetypeKeyTouchPoints.includes(item.archetype_id),
        )
        .map((item) => item.onepage_content.archetype_number) || []
    );
  }, [content.selected_audience_archetypes, listArchetypeKeyTouchPoints]);

  useEffect(() => {
    const showKeyTouchPoint =
      (import.meta.env.VITE_SHOW_KEY_TOUCH_POINT ?? 'true') === 'true';

    memoizedRouteOptionValue.isDisabled = isOnePageStrategy?.isError;
    memoizedRouteOptionValue.isActive =
      message !== 'No record found' && showKeyTouchPoint;
    memoizedRouteOptionValue.isInactive =
      isOnePageStrategy?.isQuerying ||
      isEditing ||
      !isOnePageStrategy?.status ||
      isKeyTouchPoint?.isQuerying;

    if (isEditor) {
      if (
        isKeyTouchPoint?.status &&
        !isKeyTouchPoint?.isRegenerated &&
        !data?.is_different_archetype.key_touch_points &&
        listArchetypeKeyTouchPoints.length === 0 &&
        !isEdited
      ) {
        memoizedRouteOptionValue.isGenerate = false;
        memoizedRouteOptionValue.label = 'Next to Key Touch Point';
      } else if (
        isKeyTouchPoint?.status &&
        !isKeyTouchPoint?.isRegenerated &&
        !data?.is_different_archetype.key_touch_points &&
        (listArchetypeKeyTouchPoints.length || isEdited)
      ) {
        memoizedRouteOptionValue.isGenerate = true;

        if (isEdited) {
          memoizedRouteOptionValue.label = 'Update Other Pages';
        } else {
          memoizedRouteOptionValue.label = `Generate Key Touch Point`;
        }
      } else {
        memoizedRouteOptionValue.isGenerate = true;
        memoizedRouteOptionValue.label = 'Generate Key Touch Point';
      }

      memoizedRouteOptionValue.onClick = handleEditorClick;
    } else {
      memoizedRouteOptionValue.isInactive =
        !isKeyTouchPoint?.status ||
        isKeyTouchPoint?.isQuerying ||
        isKeyTouchPoint?.isRegenerated;

      memoizedRouteOptionValue.label = 'Next to Key Touch Point';
      memoizedRouteOptionValue.isGenerate = false;
      memoizedRouteOptionValue.onClick = handleNonEditorClick;
    }

    setRouteOption(memoizedRouteOptionValue);
    dispatch(saveRouteNext(memoizedRouteOptionValue));
  }, [
    activeSection,
    isEditor,
    isEditing,
    listArchetypeKeyTouchPoints,
    isOnePageStrategy,
    isKeyTouchPoint,
    isEdited,
    data?.is_different_archetype.key_touch_points,
    message,
    selectedArchetypeNumbers,
  ]);
};

export { useCtaAction };
