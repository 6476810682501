import { useInfiniteQuery } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { RoleListResponse } from '@/Types/Administrator';

interface RoleListProps {
  pageParam: number;
  search: string;
  sort: string;
  date: number;
}

const getLists = async ({ pageParam, search, sort }: RoleListProps) => {
  try {
    const params = new URLSearchParams({
      next: String(pageParam),
    });
    if (search) params.append('search', search);
    if (sort) params.append('sort', sort);

    const { data } = await goFetcher.get(
      `/administrators/role-lists?limit=10&${params.toString()}`,
    );

    return data as RoleListResponse;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'An error occurred');
  }
};

const useRoleLists = ({ search = '', date, sort }: RoleListProps) => {
  return useInfiniteQuery({
    queryKey: ['roleList', search, date, sort],
    queryFn: ({ pageParam = 0 }) => getLists({ pageParam, search, date, sort }),
    getNextPageParam: (lastPage) => lastPage.next ?? false,
    initialPageParam: 0,
  });
};

export { useRoleLists };
