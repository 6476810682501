import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from '@headlessui/react';
import { Icon } from '@iconify/react/dist/iconify.js';
import { useEffect, useState } from 'react';

import { useRequestUserContext } from '@/Hooks/Administrator';
import { cn } from '@/Utils/cn';

import { SORT_BY } from '../../Context/request-list-provider';

const FilterAndSort = () => {
  const { selectedSortBy, setShortBy, setSearch, records, isLoading } =
    useRequestUserContext();
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [searchInput, setSearchInput] = useState('');

  // Debounce search value
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(searchInput);
    }, 300); // 300ms debounce time

    return () => {
      clearTimeout(handler);
    };
  }, [searchInput]);

  useEffect(() => {
    setSearch(debouncedSearch);
  }, [debouncedSearch]);

  const handleShortBy = (sortBy: string) => {
    setShortBy(sortBy);
  };

  return (
    <div className="my-20 flex items-center gap-20">
      <div className="grow">
        <div className="flex flex-row items-center rounded-8 border-1 border-stroke-redx bg-white px-16 py-2">
          <Icon
            className="mx-2 text-20 font-normal leading-24 text-black-redx"
            icon="lucide:search"
          />
          <input
            className="w-full border-none text-16 font-normal leading-20 text-grey-redx focus:outline-none focus:ring-0"
            onChange={(e) => {
              setSearchInput(e.target.value);
            }}
            placeholder="Search name / email here"
            type="text"
          />
        </div>
      </div>
      <div className="">
        <div className="text-right">
          {/* dropdown sort by */}
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <MenuButton
                className="gap-x-1.5 inline-flex w-full items-center justify-center rounded-md bg-white p-10 text-16 font-semibold leading-16 text-black-redx shadow-sm ring-1 ring-inset ring-stroke-redx hover:bg-gray-50 md:text-12"
                disabled={!records.length || isLoading}
              >
                Sort by
                <Icon
                  className="ml-6 size-19 text-black-redx"
                  icon="lucide:arrow-down-wide-narrow"
                />
              </MenuButton>
            </div>

            <Transition
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <MenuItems className="absolute right-0 z-20 mt-2 w-233 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                <div className="py-1">
                  {SORT_BY.map((sortBy, index) => (
                    <MenuItem key={index}>
                      {({ focus }) => (
                        <button
                          className={cn(
                            'w-full text-left',
                            focus
                              ? 'bg-gray-100 text-black-redx'
                              : 'text-black-redx',
                            'block px-10 py-5 text-12',
                            selectedSortBy === sortBy.value
                              ? 'font-semibold'
                              : 'font-normal',
                          )}
                          disabled={!records.length || isLoading}
                          onClick={() => {
                            handleShortBy(sortBy.value);
                          }}
                        >
                          {sortBy.name}
                        </button>
                      )}
                    </MenuItem>
                  ))}
                </div>
              </MenuItems>
            </Transition>
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default FilterAndSort;
