import { useMutation } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';

interface ProductResearchProps {
  isContentSame: boolean;
  content: string;
  id: number;
  projectSlug: string;
  activeSection: string;
}
const ProductResearch = async ({
  content,
  id,
  projectSlug,
  activeSection,
  isContentSame,
}: ProductResearchProps) => {
  try {
    const endpoints: Record<string, string> = {
      product_description: `/projects/${projectSlug}/product-research/product-description/update`,
      main_features: `/projects/${projectSlug}/product-research/main-feature/update`,
      marcomm_highlights: `/projects/${projectSlug}/product-research/marcomm-highlight/update`,
    };

    const url = endpoints[activeSection];

    if (!url) {
      throw new Error('Invalid section provided');
    }

    const { data } = await goFetcher.put(`${url}/${id}`, {
      content,
      is_content_same: isContentSame,
    });

    return data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useUpdateProductResearch = () => {
  return useMutation<any, Error, ProductResearchProps>({
    mutationFn: ProductResearch,
  });
};

export { useUpdateProductResearch };
