import { useContext } from 'react';

import { RoleListContext } from '@/Pages/Administrator/Context/role-list-context';

export const useRoleContext = () => {
  const context = useContext(RoleListContext);
  if (!context)
    throw new Error('useRoleContext must be used within a RoleListContext');
  return context;
};
