import PrimaryButton from '@/Components/PrimaryButton';

interface ProjectTitleProps {
  onSetIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProjectTitle = ({ onSetIsOpen }: ProjectTitleProps) => {
  return (
    <div className="mt-20 flex w-full flex-row justify-between gap-14 md:flex-col">
      <div className="flex grow flex-col gap-8">
        <h1 className="text-32 font-semibold leading-[36px] text-black-redx sm:text-[24px] sm:leading-[28px]">
          Your AInstein Projects
        </h1>
        <p className="text-16 font-normal leading-20 text-grey-redx">
          Create, search, and choose your marketing project here.
        </p>
      </div>
      <PrimaryButton
        className="w-[187px] bg-blue-purple-redx md:w-full"
        disabled={false}
        id="btn-show-form-create-project"
        onClick={() => onSetIsOpen(true)}
        style={{ height: 'fit-content' }}
      >
        Create Project
      </PrimaryButton>
    </div>
  );
};

export default ProjectTitle;
