import React from 'react';
import toast from 'react-hot-toast';

import ModalCenter from '@/Components/Modals/Center';
import {
  useAdminUserContext,
  useUpdateAdminAccessRequest,
} from '@/Hooks/Administrator';
import type { AdministratorUserResponse } from '@/Types/Administrator';
import { cn } from '@/Utils/cn';

import SuccessUpdateAccessToast from './SuccessUpdateAccessToast';

interface RevokeConfirmationModalProps {
  selectedUser: AdministratorUserResponse | undefined;
  isOpen: boolean;
  onClose: () => void;
}

const RevokeConfirmationModal: React.FC<RevokeConfirmationModalProps> = ({
  selectedUser,
  isOpen,
  onClose,
}) => {
  const {
    handleRefetch: refetch,
    updateStatus,
    handleUpdateStatus,
  } = useAdminUserContext();
  const { mutate, isPending } = useUpdateAdminAccessRequest();
  const handleClose = () => {
    if (updateStatus.id === 1) {
      handleUpdateStatus([updateStatus.id], 'detail');
      return;
    }
    onClose();
  };

  const onSubmit = () => {
    if (updateStatus.id > 0) {
      mutate(
        {
          ids: [updateStatus.id],
          status: 'revoked',
        },
        {
          onSuccess: () => {
            toast.custom((t) => (
              <SuccessUpdateAccessToast
                message={`Admin access for <b>${selectedUser?.email}</b> has been successfully removed.`}
                t={t}
              />
            ));
            handleUpdateStatus([], '');
            refetch(true);
          },
        },
      );
    }
  };

  return (
    <ModalCenter isOpen={isOpen} onClose={handleClose}>
      <div className="max-w-lg p-35">
        <h1 className="text-24 font-semibold leading-32 text-black-redx md:text-18">
          Revoke access for {selectedUser?.name} as admin?
        </h1>
        <p className="mt-16 text-14 font-normal leading-20 text-grey-redx">
          This user will no longer have admin privileges but will still have
          Ainstein user access. You can reassign the role anytime.
        </p>

        <div className="mt-24 flex items-center justify-end gap-16 gap-x-10 md:flex-col">
          <button
            className="rounded-8 border-1 border-blue-redx px-16 py-8 text-blue-redx md:w-full"
            onClick={handleClose}
          >
            Close
          </button>
          <button
            className={cn(
              'rounded-8  border-1 bg-error-redx px-16 py-8 text-white md:w-full',
              isPending ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer',
            )}
            disabled={isPending}
            onClick={onSubmit}
          >
            Remove as Admin
          </button>
        </div>
      </div>
    </ModalCenter>
  );
};

export default RevokeConfirmationModal;
