import type { ReactNode } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';

import { useRequestLists, useUpdateRequestList } from '@/Hooks/Administrator';
import type { RequestUserResponse } from '@/Types/Administrator';

import SuccessUpdateAccessToast from '../Components/RequestList/SuccessUpdateAccessToast';
import { RequestListContext } from './request-list-context';

export const SORT_BY = [
  { name: 'Name (A - Z)', value: 'name-asc' },
  { name: 'Name (Z - A)', value: 'name-desc' },
  { name: 'Date (Newest - Oldest)', value: 'created-desc' },
  { name: 'Date (Oldest - Newest)', value: 'created-asc' },
];

export const RequestListProvider = ({ children }: { children: ReactNode }) => {
  const { mutate } = useUpdateRequestList();
  const [records, setRecords] = useState<RequestUserResponse[]>([]);
  const [curPage, setCurPage] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [keyLine, setKeyFilterLine] = useState(+new Date());
  const observerRef = useRef<HTMLDivElement | null>(null);
  const [checkAll, setCheckAll] = useState(false);
  const [checkPerRow, setCheckPerRow] = useState<RequestUserResponse['id'][]>(
    [],
  );
  const [selectedSortBy, setSelectedSortBy] = useState(SORT_BY[2].value);
  const [sortedProjects, setSortedProjects] = useState<RequestUserResponse[]>(
    [],
  );
  const [updateStatus, setUpdateStatus] = useState<{
    id: RequestUserResponse['id'][];
    status: string;
  }>({
    id: [],
    status: '',
  });

  const [isObserverSet, setIsObserverSet] = useState(false);

  const setTargetRef = useCallback((ref: HTMLDivElement | null) => {
    if (ref) {
      observerRef.current = ref;
      setIsObserverSet(true); // Tandai bahwa observer telah di-set
    }
  }, []);

  const {
    data,
    isPending,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useRequestLists({
    sort: selectedSortBy,
    search,
    date: keyLine,
    pageParam: curPage,
    activeFilter: 'pending',
  });

  const removeDuplicates = (data: RequestUserResponse[]) => {
    const uniqueMap = new Map();
    data.forEach((item) => {
      uniqueMap.set(item.id, item); // Jika id sudah ada, update dengan item terbaru
    });
    return Array.from(uniqueMap.values());
  };

  useEffect(() => {
    if (data?.pages) {
      const newLists = data.pages.flatMap((page) => page.data);

      setRecords((prev) => {
        let updatedRecords;
        if (search) {
          updatedRecords = newLists;
        } else {
          updatedRecords = removeDuplicates([...prev, ...newLists]);
        }

        if (checkAll) {
          setCheckPerRow(updatedRecords.map((record) => record.id));
        }

        return updatedRecords;
      });
    }
  }, [data, search]);

  useEffect(() => {
    const sorted = [...records];

    if (selectedSortBy === 'name-asc') {
      sorted.sort((a, b) => (a.name || '').localeCompare(b.name || ''));
    } else if (selectedSortBy === 'name-desc') {
      sorted.sort((a, b) => (b.name || '').localeCompare(a.name || ''));
    } else if (selectedSortBy === 'created-asc') {
      sorted.sort(
        (a, b) =>
          new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
      );
    } else if (selectedSortBy === 'created-desc') {
      sorted.sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
      );
    }

    setSortedProjects(sorted); // Update state untuk ditampilkan
  }, [selectedSortBy, records]);

  useEffect(() => {
    setRecords([]);
    setKeyFilterLine(+new Date());
  }, []);

  const updatePage = useCallback(
    (newPage: number) => {
      setCurPage(newPage);
    },
    [setCurPage],
  );

  const handleShortBy = useCallback(
    (sortBy: string) => {
      setSelectedSortBy(sortBy);
    },
    [setSelectedSortBy],
  );

  const handleCheckAll = useCallback(
    (status: boolean) => {
      setCheckAll(status);
      setCheckPerRow(status ? records.map((record) => record.id) : []);
    },
    [setCheckAll, setCheckPerRow, records],
  );

  const handleCheckPerRow = useCallback(
    (id: RequestUserResponse['id'], status: boolean) => {
      setCheckPerRow((prev) => {
        if (status) {
          return prev.includes(id) ? prev : [...prev, id];
        }
        return prev.filter((itemId) => itemId !== id);
      });
    },
    [setCheckPerRow],
  );

  const updateSearch = useCallback(
    (search: string) => {
      setSearch(search);
    },
    [setSearch],
  );

  const handleRefetch = useCallback((isReset: boolean) => {
    if (isReset) {
      setUpdateStatus({ id: [], status: '' });
      setCheckPerRow([]);
      setRecords([]);
    }

    setKeyFilterLine(+new Date());
  }, []);

  const handleUpdateStatus = useCallback(
    (id: RequestUserResponse['id'][], status: string) => {
      if (status === 'approved') {
        mutate(
          {
            ids: id,
            status,
            reason: '',
          },
          {
            onSuccess: () => {
              if (id.length > 1) {
                toast.custom((t) => (
                  <SuccessUpdateAccessToast
                    message={`Request for <b>${id.length} users</b> successfully accepted.`}
                    t={t}
                  />
                ));
              } else {
                const selectedUser = records.find(
                  (record) => record.id === id[0],
                );
                toast.custom((t) => (
                  <SuccessUpdateAccessToast
                    message={`Request for <b>${selectedUser?.email} users</b> successfully accepted.`}
                    t={t}
                  />
                ));
              }

              handleRefetch(true);
            },
          },
        );
      } else {
        setUpdateStatus({ id, status });
      }
    },
    [records],
  );

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const target = entries[0];
        if (target.isIntersecting && hasNextPage && !isFetchingNextPage) {
          const lastPage = data?.pages[data.pages.length - 1];
          if (lastPage?.next === 999999999) {
            return;
          }
          fetchNextPage();
        }
      },
      {
        root: null, // Gunakan viewport sebagai root
        rootMargin: '0px',
        threshold: 0.1, // Callback dipicu saat 10% elemen loader terlihat
      },
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data?.pages,
    isObserverSet,
  ]);

  const value = useMemo(
    () => ({
      updateStatus,
      hasNextPage,
      isFetchingNextPage,
      observerRef,
      records: sortedProjects,
      page: curPage,
      selectedSortBy,
      checkAll,
      checkPerRow,
      isLoading: isPending || isLoading || isFetchingNextPage,
      setPage: updatePage,
      setSearch: updateSearch,
      setShortBy: handleShortBy,
      handleRefetch,
      handleUpdateStatus,
      setCheckAll: handleCheckAll,
      setCheckPerRow: handleCheckPerRow,
      setTargetRef,
    }),
    [
      updateStatus,
      hasNextPage,
      records,
      curPage,
      isPending,
      isLoading,
      isFetchingNextPage,
      observerRef,
      selectedSortBy,
      checkAll,
      checkPerRow,
      sortedProjects,
      updatePage,
      handleUpdateStatus,
      handleRefetch,
      updateSearch,
      handleShortBy,
      handleCheckAll,
      handleCheckPerRow,
      setTargetRef,
    ],
  );

  return (
    <RequestListContext.Provider value={value}>
      {children}
    </RequestListContext.Provider>
  );
};
