/* eslint-disable no-control-regex */
const cleanContent = (content: string): string[] => {
  // Bersihkan simbol, angka, dan format yang tidak relevan
  return content
    .replace(/<[^>]+>/g, '') // Hapus semua tag HTML/XML apapun
    .replace(/[#*~[\]()\-+=`><]/g, '') // Hapus simbol Markdown
    .replace(/[^a-zA-Z\s.]/g, '') // Hapus karakter non-alfabet kecuali spasi dan titik
    .replace(/\s+/g, ' ') // Ganti banyak spasi dengan satu spasi
    .replace(/[\x00-\x1F\x7F-\x9F]/g, '') // Hapus karakter kontrol dan karakter non-printable
    .toLowerCase() // Konversi ke huruf kecil
    .split(/[.!?]\s+/) // Pecah berdasarkan tanda titik, tanda seru, atau tanda tanya
    .map((line) => line.trim()) // Hilangkan spasi tambahan
    .filter((line) => line.length > 0); // Hilangkan string kosong
};

const cleanContentAsText = (content: string): string => {
  return content
    .replace(/<[^>]+>/g, '') // Hapus semua tag HTML/XML apapun
    .replace(/[#*~[\]()\-+=`><]/g, '') // Hapus simbol Markdown
    .replace(/[^a-zA-Z\s.]/g, '') // Hapus karakter non-alfabet kecuali spasi dan titik
    .replace(/\s+/g, ' ') // Ganti banyak spasi dengan satu spasi
    .replace(/[\x00-\x1F\x7F-\x9F]/g, '') // Hapus karakter kontrol dan karakter non-printable
    .trim() // Hilangkan spasi tambahan
    .toLowerCase(); // Konversi ke huruf kecil
};

export { cleanContent, cleanContentAsText };
