import { useNavigate } from 'react-router-dom';

import PrimaryButton from '@/Components/PrimaryButton';
import AuthenticatedLayout from '@/Layouts/AuthenticatedLayout';

export const NotFound = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/projects`);
  };
  return (
    <AuthenticatedLayout>
      <div className="mx-auto flex min-h-screen flex-col items-center justify-center md:max-w-300">
        <div className="my-6">
          <img
            alt="Confused Character"
            className="mx-auto h-300 w-auto"
            src="/assets/img/404-error.png"
          />
        </div>
        <div className="mt-6 text-center">
          <div className="mb-6">
            <h1 className="text-30 font-semibold">Page not found...</h1>
            <p className="py-20 text-gray-400">
              Sorry, the page you were looking for is either removed or
              doesn&apos;t exist.
              <br />
              Please contact your teammates or our support team for help.
            </p>
          </div>

          <PrimaryButton
            className="border-1 bg-blue-redx px-30 py-10 text-18 font-semibold hover:bg-blue-redx active:bg-blue-redx"
            onClick={handleClick}
          >
            Go to Project List
          </PrimaryButton>
        </div>
      </div>
    </AuthenticatedLayout>
  );
};
