import { Icon } from '@iconify/react/dist/iconify.js';
import markerSDK from '@marker.io/browser';
import Cookies from 'js-cookie';
import type { PropsWithChildren } from 'react';
import { memo, useContext, useEffect } from 'react';
import { clarity } from 'react-microsoft-clarity';
import { useLocation, useNavigate } from 'react-router-dom';

import Profile from '@/Components/TopMenu/Profile';
import { saveProjectData, saveUser } from '@/Context/Actions/project-actions';
import { ProjectContext } from '@/Context/ProjectContext';
import { useSidebarContext } from '@/Context/SidebarContext';
import { useUser } from '@/Hooks/useUser';
import type { ProjectProps } from '@/Types/Projects';

interface AuthenticatedLayoutProps extends PropsWithChildren {
  project?: ProjectProps;
}

const Authenticated = memo(
  ({ project, children }: AuthenticatedLayoutProps) => {
    const { data: user, isSuccess } = useUser();
    const navigate = useNavigate();
    const [, dispatch] = useContext(ProjectContext);
    const { isSidebarOpen, closeSidebar, showSidebar } = useSidebarContext();
    const location = useLocation();

    useEffect(() => {
      const initializeMarker = async () => {
        try {
          // Load the Marker.io widget

          const widget = await markerSDK.loadWidget({
            // TODO: convert into ENV
            project:
              import.meta.env.VITE_MARKER_PROJECT_CODE ??
              '66d527ce6c3f5b2807cff0c3',
          });

          widget.setReporter({
            email: user?.email ?? 'ai@ainstein.com',
            fullName: user?.name ?? 'ainstein',
          });

          // Optional: Show the widget or configure further as needed
          widget.show();
        } catch (error) {
          // console.error('Failed to load Marker.io widget:', error);
        }
      };

      initializeMarker();

      const projectCode = import.meta.env.VITE_CLARITY_PROJECT_CODE;
      clarity.init(projectCode);

      if (clarity.hasStarted()) {
        clarity.identify('USER_ID', {
          userProperty: user?.email ?? 'ai@ainstein.com',
        });
      }
    }, [user]);

    useEffect(() => {
      const removeOverflowHidden = () => {
        if (document.body.style.overflow === 'hidden') {
          document.body.style.overflow = ''; // Remove the overflow hidden style
        }
      };
      removeOverflowHidden();

      const handlePopState = () => {
        removeOverflowHidden();
      };

      window.addEventListener('popstate', handlePopState);

      return () => {
        window.removeEventListener('popstate', handlePopState);
      };
    }, []);

    useEffect(() => {
      project && dispatch(saveProjectData(project));
      user && dispatch(saveUser(user));
    }, [project, user]);

    const handleLogout = () => {
      // Remove the access_token cookie
      Cookies.remove('access_token');

      // Redirect the user to the login page or another appropriate page
      navigate('/login', { replace: true });
    };

    const handleBackToProject = () => {
      navigate('/projects', { replace: true });
    };

    const shouldShowMenuIcon = location.pathname !== '/projects';

    if (!isSuccess) return null;

    if ((!user && isSuccess) || !Cookies.get('access_token')) {
      handleLogout();
    }

    return (
      <div className="size-full bg-white">
        <header className="fixed left-0 top-0 z-40 flex w-full items-center justify-between bg-gradient-to-r from-[#00C2DD] via-[#330DCC] to-[#610DCC] px-30 py-10">
          <div className="inline-flex flex-row items-center gap-10">
            {shouldShowMenuIcon && (
              <Icon
                className="mr-20 block size-24 cursor-pointer text-white lg:hidden"
                icon="lucide:menu"
                onClick={() => {
                  isSidebarOpen ? closeSidebar() : showSidebar();
                }}
              />
            )}
            <button
              className="h-35 w-auto cursor-pointer border-none bg-transparent p-0"
              onClick={handleBackToProject}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handleBackToProject();
                }
              }}
            >
              <img
                alt="AInstein.png"
                className="h-35 w-auto"
                src="/assets/img/AInstein.svg"
              />
            </button>
          </div>
          <Profile avatar={user?.avatar_url ?? ''} />
        </header>

        <main className="size-full">{children}</main>
      </div>
    );
  },
);

export default Authenticated;
