import { z } from 'zod';

export const AudienceInsights = z.object({
  passion_points: z.string().min(2, 'Passion points is required'),
  motivations: z.string().min(2, 'Motivations is required'),
  pain_points: z.string().min(2, 'Pain points is required'),
  expectations: z.string().min(2, 'Expectations is required'),
});

export type AudienceInsightsSchema = z.infer<typeof AudienceInsights>;
