/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Icon } from '@iconify/react';
import React, { useEffect, useRef, useState } from 'react';

import { useClickOutside } from '@/Hooks/useClickOutside';

interface IInputSelectItem {
  title: string;
  value: string;
  disabled?: boolean;
  isChecked?: boolean;
}

interface InputSelectProps {
  modelValue: string;
  list: IInputSelectItem[];
  disabled: boolean;
  placeholder: string;
  containerClassName?: string;
  error?: string;
  onChange: (value: string | number | (string | number)[]) => void;
}

const InputCheckboxDropdown: React.FC<InputSelectProps> = ({
  modelValue,
  list,
  disabled,
  placeholder,
  onChange,
  containerClassName = '',
  error = '',
}) => {
  const [dropdownActive, setDropdownActive] = useState(false);
  const elementRef = useRef<HTMLDivElement>(null);

  const isItemEmpty = !modelValue;

  const handleClickOutside = () => {
    setDropdownActive(false);
  };

  useClickOutside(elementRef, handleClickOutside);

  useEffect(() => {
    const modelValueItem = list.find(
      (listItem) => listItem.value === modelValue,
    );
    if (modelValueItem) {
      onChange(modelValueItem.value);
    }
  }, []);

  const handleChoose = (listItem: IInputSelectItem) => {
    const newModelValue = modelValue.length > 0 ? modelValue.split(', ') : [];
    const isItemSelected = newModelValue.find(
      (newModelValueItem) => listItem.value === newModelValueItem,
    );

    if (isItemSelected) {
      const newModelValueIndex = newModelValue.findIndex(
        (newModelValueItem) => listItem.value === newModelValueItem,
      );
      newModelValue.splice(newModelValueIndex, 1);
    } else {
      newModelValue.push(listItem.value);
    }

    onChange(newModelValue.join(', '));
  };

  return (
    <div className="relative">
      <div ref={elementRef} className="relative">
        <div
          className={`flex w-full items-center justify-between overflow-hidden bg-transparent ${
            disabled ? '' : 'cursor-pointer'
          } ${containerClassName} ${error ? '!border-red-500' : ''}`}
          onClick={() => !disabled && setDropdownActive(!dropdownActive)}
        >
          <div
            className={`mr-20 px-0 py-4 !text-14 ${
              isItemEmpty ? 'text-placeholder-redx' : ''
            }`}
          >
            {!isItemEmpty ? modelValue : placeholder}
          </div>
          {!disabled && (
            <Icon
              className={`h-w-24 w-24 text-black transition-all duration-300 ease-out ${
                dropdownActive ? 'rotate-180' : ''
              }`}
              icon="uil:angle-down"
            />
          )}
        </div>
        {dropdownActive && (
          <div
            className="absolute left-8 top-full z-50 mt-10 w-[97.5%] rounded-10 bg-white p-10"
            style={{
              boxShadow: '0px 0px 12px 0px #00000026',
            }}
          >
            <div
              ref={elementRef}
              className="overflow-custom max-h-120 w-full overflow-y-auto py-0"
            >
              {list.map((listItem, index) => (
                <div key={index} className="flex items-center">
                  <div className="flex flex-1 items-center">
                    <input
                      checked={
                        !!modelValue
                          .split(', ')
                          .find((itemItem) => itemItem === listItem.value)
                      }
                      className="!focus:border-transparent !focus:ring-0 mx-5 cursor-pointer rounded-4 border-1 text-blue-purple-redx outline outline-1 outline-offset-0 outline-stroke-redx"
                      disabled={listItem.disabled}
                      onChange={() => handleChoose(listItem)}
                      type="checkbox"
                    />
                    <div className="rounded-5 px-10 py-5 !text-14">
                      <div className="text-black text-opacity-80">
                        {listItem.title}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      {error && (
        <p className="absolute -bottom-20 text-12 text-red-500">{error}</p>
      )}
    </div>
  );
};

export default InputCheckboxDropdown;
