import { useMutation } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';

interface SubmitAdminUserProps {
  redhub_id: number;
  email: string;
  name: string;
  whatsapp_number: string;
  avatar: string;
}

const submitUser = async (users: SubmitAdminUserProps[]) => {
  const url = `/administrators/admin-user-lists/submit`;
  try {
    await goFetcher.post(url, { users });
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'An error occurred');
  }
};

const useAdminSubmitUser = () => {
  return useMutation<any, Error, SubmitAdminUserProps[]>({
    mutationFn: submitUser,
  });
};

export { useAdminSubmitUser };
