import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import GenerateNotification from '@/Components/Toast/GenerateNotification';
import { goFetcher } from '@/Services/axios-go';
import type { ProjectProps } from '@/Types/Projects';
import type { SectionList } from '@/Types/tabs';

const GenerateLoading: React.FC<{
  progress: number;
  project: ProjectProps;
  section: SectionList;
  estimateTimeFrom?: number;
  estimateTimeTo?: number;
  showEmailNotification: boolean;
}> = ({
  progress,
  project,
  section,
  estimateTimeFrom = 1,
  estimateTimeTo = 2,
  showEmailNotification,
}) => {
  const [emailNotification, setEmailNotification] = useState(false);

  useEffect(() => {
    setEmailNotification(!showEmailNotification);
  }, [showEmailNotification]);

  const handleEmailNotification = async () => {
    try {
      if (emailNotification === true) {
        setEmailNotification(false);
        const data = {
          submission_id: project.submissions[0].id,
          section: section.section,
          subsection: section.value,
        };
        await goFetcher.put(
          `/projects/${project.slug}/email-notification`,
          data,
        );
        toast.custom((t) => <GenerateNotification t={t} />);
      }
    } catch (error: any) {
      setEmailNotification(true);
      throw new Error(error.response.data.message);
    }
  };

  return (
    <div className="flex items-center justify-between pb-120 md:flex-col lg:gap-x-30 lg:py-35 lg:pr-40">
      <div className="size-300">
        <img
          alt="loading"
          className="size-full animate-bounce object-contain"
          src="/assets/img/ainstein-generating.png"
        />
      </div>
      <div className="flex-1 flex-col">
        <h1 className="mb-8 text-25 font-bold text-black-redx">
          AInstein is working hard on your output... Bear with him!
        </h1>
        <p className="mb-24 text-15 text-grey-redx">
          If you notice any bugs, we&apos;d love to hear about them! Just click
          the feedback button on the bottom-left side of this page.
        </p>
        <div className="mb-24 h-15 w-full rounded-full bg-stroke-redx">
          <div
            className="h-15 rounded-full bg-gradient-to-r from-[#00C2DD] via-[#330DCC] to-[#610DCC] py-2 pl-9 text-left text-12 font-semibold leading-none text-white"
            style={{ width: `${progress}%` }}
          >
            {progress}%
          </div>
        </div>
        <div className="flex w-full justify-between">
          <div className="text-15">
            Estimate Time:{' '}
            <span className="font-bold">
              {estimateTimeFrom}-{estimateTimeTo} minutes
            </span>
          </div>
          <div className="flex gap-x-24">
            {emailNotification && (
              <button
                className="text-15 font-semibold text-blue-redx"
                onClick={handleEmailNotification}
              >
                Notify me via Email
              </button>
            )}
            {/*
            // TODO: add generate browser notification
            <a
              href='#'
              className='text-15 text-blue-redx font-semibold'>
              Notify me via Browser
            </a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenerateLoading;
