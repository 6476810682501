import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';

interface ResponsiveNavLinkProps extends LinkProps {
  active?: boolean;
  className?: string;
}

const ResponsiveNavLink = ({
  active = false,
  className = '',
  children,
  ...props
}: ResponsiveNavLinkProps) => {
  return (
    <Link
      {...props}
      className={`flex w-full items-start border-l-4 py-2 pe-4 ps-3 ${
        active
          ? 'border-indigo-400 bg-indigo-50 text-indigo-700 focus:border-indigo-700 focus:bg-indigo-100 focus:text-indigo-800'
          : 'border-transparent hover:font-bold'
      } font-medium transition duration-150 ease-in-out focus:outline-none ${className}`}
    >
      {children}
    </Link>
  );
};

export default ResponsiveNavLink;
