import { Button, Transition } from '@headlessui/react';

import { useUserContext } from '@/Hooks/Administrator/useUserContext';

const CtaActions = () => {
  const { checkPerRow, handleUpdateStatus, activeFilter } = useUserContext();

  return (
    <div
      className="fixed inset-x-0 bottom-0 w-full bg-white shadow-md"
      style={{ boxShadow: '0px -4px 20px 0px rgba(0, 0, 0, 0.12)' }}
    >
      <Transition
        enter="transition-transform duration-300 ease-out"
        enterFrom="translate-y-full opacity-0"
        enterTo="translate-y-0 opacity-100"
        leave="transition-transform duration-300 ease-in"
        leaveFrom="translate-y-0 opacity-100"
        leaveTo="translate-y-full opacity-0"
        show={checkPerRow.length > 1}
      >
        <div className="mx-auto max-w-7xl bg-white px-14 py-20">
          <div className="flex size-full flex-row items-center justify-between gap-24 md:flex-col">
            <p className="leading-22 text-16 font-semibold text-black-redx">
              {checkPerRow.length} users selected
            </p>
            <div className="inline-flex items-center gap-24">
              {activeFilter === 'active' ? (
                <Button
                  className="rounded-8 border-1 border-error-redx px-16 py-8 text-error-redx"
                  onClick={() => handleUpdateStatus(checkPerRow, 'revoke')}
                >
                  Revoke Access
                </Button>
              ) : (
                <Button
                  className="rounded-8 border-1 border-success-redx px-16 py-8 text-success-redx"
                  onClick={() => handleUpdateStatus(checkPerRow, 'grant')}
                >
                  Active Access
                </Button>
              )}
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default CtaActions;
