import type { RefObject } from 'react';
import { createContext } from 'react';

import type { AdministratorRoleResponse } from '@/Types/Administrator';

export interface RoleListProps {
  isLoading: boolean;
  isFetchingNextPage: boolean;
  records: AdministratorRoleResponse[];
  page: number;
  selectedSortBy: string;
  hasNextPage: boolean;
  dataForm: {
    name: AdministratorRoleResponse['name'];
    displayName: AdministratorRoleResponse['display_name'];
  };
  updateStatus: { id: AdministratorRoleResponse['id']; status: string };
  observerRef: RefObject<HTMLDivElement>;
  setPage: (page: number) => void;
  setShortBy: (sortBy: string) => void;
  setTargetRef: (ref: HTMLDivElement | null) => void;
  handleUpdateStatus: (
    id: AdministratorRoleResponse['id'],
    status: string,
  ) => void;
  handleDataForm: (
    name: AdministratorRoleResponse['name'],
    displayName: AdministratorRoleResponse['display_name'],
  ) => void;
  setSearch: (search: string) => void;
  handleRefetch: (isReset: boolean) => void;
}

export const RoleListContext = createContext<RoleListProps | undefined>(
  undefined,
);
