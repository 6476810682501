import { isEqual } from 'lodash';
import { useCallback, useEffect } from 'react';

import {
  createDifferenceChecker,
  sanitizeContent,
} from '@/Pages/TargetAudience/Utils/utils';
import type { HistoryStatus } from '@/Types/history';
import type {
  AudienceInsightProps,
  AudienceInsightResponse,
  BehavioralTrendsProps,
  BehavioralTrendsResponse,
  MediaConsumptionPatternsProps,
  MediaConsumptionResponse,
  PerceptionAnalysisProps,
  PerceptionAnalysisResponse,
  SectionDataAudienceInsight,
  SectionDataBehavioralTrends,
  SectionDataMap,
  SectionDataMediaConsumption,
  SectionDataPerceptionAnalysis,
} from '@/Types/TargetAudience';
import {
  initializeGeneratingStates,
  initializeProgressStates,
} from '@/Utils/init-data';
import { getSectionProgressFromLocalStorage } from '@/Utils/persist-progress';

interface UseSectionDataParams {
  isAudienceArchetype: HistoryStatus;
  data:
    | AudienceInsightResponse
    | PerceptionAnalysisResponse
    | BehavioralTrendsResponse
    | MediaConsumptionResponse
    | undefined;
  activeSection: string;
  projectSlug: string;
  progressStates: Record<number, number>;
  sectionData: Record<
    keyof SectionDataMap,
    | SectionDataAudienceInsight
    | SectionDataPerceptionAnalysis
    | SectionDataBehavioralTrends
    | SectionDataMediaConsumption
  >;
  setGeneratingStates: React.Dispatch<
    React.SetStateAction<Record<number, boolean>>
  >;
  setProgressStates: React.Dispatch<
    React.SetStateAction<Record<number, number>>
  >;
  setSectionData: React.Dispatch<
    React.SetStateAction<
      Record<keyof SectionDataMap, SectionDataMap[keyof SectionDataMap]>
    >
  >;
}

export const useSectionData = ({
  isAudienceArchetype,
  data,
  activeSection,
  projectSlug,
  progressStates,
  setGeneratingStates,
  setProgressStates,
  setSectionData,
  sectionData,
}: UseSectionDataParams) => {
  // Create a difference checker with keys and sanitize function
  const hasDifferenceAudienceInsight = useCallback(
    createDifferenceChecker<AudienceInsightProps>(
      ['expectations', 'motivations', 'pain_points', 'passion_points'],
      sanitizeContent,
    ),
    [isAudienceArchetype.isRegenerated, isAudienceArchetype.status],
  );

  const hasDifferencePerception = useCallback(
    createDifferenceChecker<PerceptionAnalysisProps>(
      ['brand_perception', 'product_category_perception', 'sector_perception'],
      sanitizeContent,
    ),
    [isAudienceArchetype.isRegenerated, isAudienceArchetype.status],
  );

  const hasDifferenceBehavioral = useCallback(
    createDifferenceChecker<BehavioralTrendsProps>(
      ['consumption_behaviors', 'current_trends'],
      sanitizeContent,
    ),
    [isAudienceArchetype.isRegenerated, isAudienceArchetype.status],
  );

  const hasDifferenceMedia = useCallback(
    createDifferenceChecker<MediaConsumptionPatternsProps>(
      ['media_consumption_pattern'],
      sanitizeContent,
    ),
    [isAudienceArchetype.isRegenerated, isAudienceArchetype.status],
  );

  const sectionComparators: Record<
    keyof SectionDataMap,
    (current: any, previous: any) => boolean
  > = {
    audience_insight: hasDifferenceAudienceInsight,
    perception_analysis: hasDifferencePerception,
    behavioral_trends: hasDifferenceBehavioral,
    media_consumption_patterns: hasDifferenceMedia,
  };

  useEffect(() => {
    if (data) {
      const existingData = sectionData[activeSection as keyof SectionDataMap];

      if (data.is_querying) {
        const dataArray = [
          {
            id: data.history_id,
            ...data,
          },
        ];

        const initialStates = initializeGeneratingStates(dataArray);
        setGeneratingStates(initialStates);

        const savedProgress = getSectionProgressFromLocalStorage(
          projectSlug,
          activeSection,
        );

        const updatedProgressStates = initializeProgressStates(
          dataArray, // Data array yang sudah diubah
          savedProgress,
          progressStates,
        );

        setProgressStates((prev) => ({
          ...prev,
          ...updatedProgressStates,
        }));
      }

      const comparator =
        sectionComparators[activeSection as keyof SectionDataMap];

      const isDiffWithOrigin = comparator(
        data.content as unknown,
        data.origin_content as unknown,
      );

      if (!isEqual(existingData, data)) {
        setSectionData((prev) => ({
          ...prev,
          [activeSection]: {
            is_email_notification: data.is_email_notification,
            history_id: data.history_id ?? 0,
            content: data.content ?? '',
            sources: data.sources ?? [],
            total_regenerate: data.total_regenerate ?? 0,
            is_querying: data.is_querying,
            is_diff_with_origin: isDiffWithOrigin,
            is_edited: data.is_edited,
            is_error: data.is_error,
            message: data.message,
            previous_payload_data: data.previous_payload_data,
          },
        }));
      }
    }
  }, [data, activeSection, isAudienceArchetype]);
};
