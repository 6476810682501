import { useMutation } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { AdministratorUserResponse } from '@/Types/Administrator';
import type { ArchetypeResponse } from '@/Types/AudienceArchetype';

interface UpdateStatusProps {
  ids: AdministratorUserResponse['id'][];
  status: string;
}

const updateStatus = async ({ ids, status }: UpdateStatusProps) => {
  try {
    const url = `/administrators/user-lists/access-status`;
    const { data } = await goFetcher.post(url, {
      ids,
      status,
    });

    return data as ArchetypeResponse;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useAccessRequest = () => {
  return useMutation<any, Error, UpdateStatusProps>({
    mutationFn: updateStatus,
  });
};

export { useAccessRequest };
