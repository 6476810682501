import { Button } from '@headlessui/react';

import { useRoleContext } from '@/Hooks/Administrator/useRoleContext';
import type { AdministratorRoleResponse } from '@/Types/Administrator';

interface TableRowProps {
  row: AdministratorRoleResponse;
}

const TableRowRequestList = ({ row }: TableRowProps) => {
  const { handleUpdateStatus } = useRoleContext();

  return (
    <tr>
      <td className="border-b-1 border-stroke-redx px-16 py-30">
        <div className="flex w-155 max-w-155 flex-col items-start gap-8 ">
          <p className="break-words text-14 font-normal leading-16">
            {row.name}
          </p>
          <p className="whitespace-normal break-all text-12 font-normal leading-16 text-dark-stroke-redx">
            {row.display_name}
          </p>
        </div>
      </td>
      <td className="border-b-1 border-stroke-redx px-16 py-30">
        <p>
          {new Date(row.created_at).toLocaleDateString('id-ID', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          })}
        </p>
        <p>
          {new Date(row.created_at)
            .toLocaleTimeString('id-ID', {
              hour: '2-digit',
              minute: '2-digit',
            })
            .replace('.', ':')}
        </p>
      </td>
      <td className="border-b-1 border-stroke-redx px-16 py-30 text-14 font-normal leading-16">
        <p className="max-w-fit rounded-20 bg-light-g100-redx px-12 py-6 text-14 font-semibold leading-16 text-light-success-redx">
          Active
        </p>
      </td>

      <td className="border-b-1 border-stroke-redx px-16 py-30 text-center">
        <Button
          className="min-w-120 rounded-8 border-1 border-stroke-redx px-12 py-8 text-center text-14 font-semibold leading-20 text-black-redx"
          onClick={() => handleUpdateStatus(row.id, 'detail')}
        >
          Detail
        </Button>
      </td>
    </tr>
  );
};

export default TableRowRequestList;
