import { zodResolver } from '@hookform/resolvers/zod';
import { forwardRef, memo, useEffect, useImperativeHandle } from 'react';
import { Controller, useForm } from 'react-hook-form';

import CardTitle from '@/Components/CardTitle';
import Content from '@/Components/Content';
import Label from '@/Components/Label';
import SelectButton from '@/Components/Projects/SliderCard/SelectButton';
import TextAreaInput from '@/Components/TextAreaInput';
import { useUpdateSelectedIdeaExpansion } from '@/Hooks/IdeaExpansion';
import type { IdeaExpansionContent } from '@/Types/IdeaExpansion';
import {
  IdeaExpansion,
  type IdeaExpansionSchema,
} from '@/Types/IdeaExpansion/schema';
import type { ProjectProps } from '@/Types/Projects';
import type { UserResponse } from '@/Types/user';
import { triggerGTMEvent } from '@/Utils/gtm';

const fieldsToDisplay = [
  { name: 'idea_title', label: 'Idea Title' },
  {
    name: 'idea_description',
    label: 'Idea Description',
  },
  {
    name: 'reason_product_brand',
    label: 'Reason Product Brand',
  },
  {
    name: 'reason_audience_archetype',
    label: 'Reason Audience Archetype',
  },
  {
    name: 'campaign_example',
    label: 'Campaign Example',
  },
  {
    name: 'campaign_metrics',
    label: 'Campaign Metrics',
  },
  {
    name: 'preferred_influencer',
    label: 'Preferred Influencer',
  },
  {
    name: 'preferred_platform',
    label: 'Preferred Platform',
  },
];

export interface CardHandles {
  submitForm: () => void;
}
const Card = forwardRef<
  CardHandles,
  {
    user?: UserResponse;
    id?: number;
    project?: ProjectProps;
    totalData?: number;
    selectedNumber?: number;
    funnel?: string;
    isEdited?: boolean;
    isFetching?: boolean;
    item?: IdeaExpansionContent;
    index?: number;
    isEditing?: boolean;
    isSelected?: boolean;
    isCanSelect?: boolean;
    onSubmitSuccess?: (
      data: IdeaExpansionSchema,
      id: number,
      index: number,
    ) => void;
    onSelectSuccess?: (isShowPopUp: boolean) => void;
    refetchDiffArchetype?: () => void;
  }
>(
  (
    {
      selectedNumber = 0,
      funnel,
      user,
      id = 0,
      project,
      totalData,
      item,
      isEdited = false,
      isFetching = false,
      index = 0,
      isSelected = false,
      isEditing = false,
      isCanSelect = false,
      onSubmitSuccess,
      onSelectSuccess,
      refetchDiffArchetype,
    },
    ref,
  ) => {
    const { mutate: mutateSelected, isPending: isUpdatingSelected } =
      useUpdateSelectedIdeaExpansion();

    const { handleSubmit, reset, control } = useForm<IdeaExpansionSchema>({
      resolver: zodResolver(IdeaExpansion),
      defaultValues: {
        campaign_example: item?.campaign_example ?? '',
        campaign_metrics: item?.campaign_metrics ?? '',
        idea_description: item?.idea_description ?? '',
        idea_expansion_number: +(item?.idea_expansion_number ?? 0),
        idea_title: item?.idea_title ?? '',
        preferred_influencer: item?.preferred_influencer ?? '',
        preferred_platform: item?.preferred_platform ?? '',
        reason_audience_archetype: item?.reason_audience_archetype ?? '',
        reason_product_brand: item?.reason_product_brand ?? '',
      },
      mode: 'all',
    });

    const onSubmit = (data: IdeaExpansionSchema) => {
      if (
        id === undefined ||
        index === undefined ||
        onSubmitSuccess === undefined
      )
        return;

      onSubmitSuccess(data, id, index);
    };

    useEffect(() => {
      if (isEditing) {
        reset({
          campaign_example: item?.campaign_example ?? '',
          campaign_metrics: item?.campaign_metrics ?? '',
          idea_description: item?.idea_description ?? '',
          idea_expansion_number: +(item?.idea_expansion_number ?? 0),
          idea_title: item?.idea_title ?? '',
          preferred_influencer: item?.preferred_influencer ?? '',
          preferred_platform: item?.preferred_platform ?? '',
          reason_audience_archetype: item?.reason_audience_archetype ?? '',
          reason_product_brand: item?.reason_product_brand ?? '',
        });
      }
    }, [isEditing, reset, item]);

    useImperativeHandle(
      ref,
      () => ({
        submitForm: () => {
          handleSubmit(onSubmit)();
        },
      }),
      [handleSubmit, onSubmit],
    );

    const handleSelected = (id: number) => {
      if (
        project === undefined ||
        user === undefined ||
        funnel === undefined ||
        onSelectSuccess === undefined
      )
        return;

      const projectSlug = project.slug;

      triggerGTMEvent({
        event: `Select Idea Expansion`,
        eventCategory: `Button Select Idea Expansion Click`,
        eventAction: 'Click',
        eventLabel: 'Idea Expansion',
        userId: user.email,
        data: { type: isSelected ? 'Unselect' : 'Select', ...item },
      });

      mutateSelected(
        { id, projectSlug },
        {
          onSuccess: (data) => {
            onSelectSuccess(data.is_show_popup);
            refetchDiffArchetype?.();
          },
        },
      );
    };

    return (
      <div className="flex w-full flex-col pb-35 transition duration-500">
        <div className="px-35 py-15 text-center">
          {isFetching ? (
            <h1 className="mx-auto mb-14 h-20 w-3/12 animate-pulse rounded-full bg-soft-purple-redx" />
          ) : (
            <CardTitle
              funnel={`${funnel} ${selectedNumber}`}
              id={id ?? -1}
              index={index}
              isEdited={isEdited}
              label="Idea Expansion"
              totalData={totalData ?? 1}
            />
          )}
        </div>
        <div
          className={` grid h-full min-h-350 grid-cols-2 gap-15 p-24 pb-8 text-black-redx md:grid-cols-1 ${
            isEditing && isSelected ? '!bg-transparent' : ''
          } ${
            isSelected
              ? 'border-t-2 border-blue-redx bg-softer-purple-redx'
              : 'border-t-1 border-stroke-redx'
          }`}
        >
          {fieldsToDisplay.map(({ name, label }) => {
            return (
              <div key={name} className="flex flex-col gap-10">
                <Label value={label} />
                {isEditing ? (
                  <Controller
                    control={control}
                    name={name as keyof IdeaExpansionSchema}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextAreaInput
                        autoComplete="off"
                        className="overflow-custom mt-1 block w-full resize-none !text-14"
                        containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                        error={error?.message}
                        onChange={onChange}
                        placeholder={`Input the ${label} here`}
                        rows={2}
                        value={value}
                      />
                    )}
                  />
                ) : (
                  <Content
                    isFetching={isFetching}
                    value={item?.[name as keyof IdeaExpansionSchema] || ''}
                  />
                )}
              </div>
            );
          })}
        </div>
        <div className="flex w-full justify-end px-1 pt-15">
          {id !== -1 && isCanSelect && (
            <SelectButton
              hasOne={false}
              index={index}
              isEditing={isEditing}
              isLoading={isUpdatingSelected}
              isSelected={isSelected}
              onSelect={() => handleSelected(id)}
              section="idea-expansion"
            />
          )}
        </div>
      </div>
    );
  },
);

export default memo(Card);
