import { useCallback, useEffect } from 'react';

import type { GetDifferentResponse } from '@/Types/AudienceArchetype';
import type { HistoryStatus } from '@/Types/history';
import type {
  ArchetypeKeyInsight,
  ArchetypesState,
  KeyInsightResponse,
  PreviousData,
} from '@/Types/KeyInsight';
import type { SourcesType } from '@/Types/Projects';
import { calculateTotals } from '@/Utils/calculate-totals';
import {
  initializeGeneratingStates,
  initializeProgressStates,
  mapArchetypes,
} from '@/Utils/init-data';
import { getSectionProgressFromLocalStorage } from '@/Utils/persist-progress';

interface UseSectionDataHandlerProps {
  diffArch: GetDifferentResponse | undefined;
  isChallengeAndTaskOfTheBrand: HistoryStatus;
  data: KeyInsightResponse | undefined;
  sectionKey: string;
  projectSlug: string;
  progressStates: Record<number, number>;
  archetypes: ArchetypesState;
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
  setArchetypes: React.Dispatch<React.SetStateAction<ArchetypesState>>;
  setGeneratingStates: React.Dispatch<
    React.SetStateAction<Record<number, boolean>>
  >;
  setProgressStates: React.Dispatch<
    React.SetStateAction<Record<number, number>>
  >;
  setSources: React.Dispatch<React.SetStateAction<SourcesType[]>>;
  setTotalRegenerate: React.Dispatch<React.SetStateAction<number>>;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  setIsError: React.Dispatch<React.SetStateAction<boolean>>;
  setTotals: React.Dispatch<
    React.SetStateAction<{
      allSelected: number;
      allData: number;
    }>
  >;
}

export const useSectionDataHandler = ({
  diffArch,
  isChallengeAndTaskOfTheBrand,
  data,
  archetypes,
  sectionKey,
  projectSlug,
  progressStates,
  setTotalRegenerate,
  setGeneratingStates,
  setProgressStates,
  setSources,
  setArchetypes,
  setTotals,
  setActiveTab,
  setMessage,
  setIsError,
}: UseSectionDataHandlerProps) => {
  const checkChangesInKeyInsights = useCallback(
    ({
      data,
      previousResult,
    }: {
      data: ArchetypeKeyInsight[];
      previousResult: PreviousData[] | null;
    }) => {
      if (!Array.isArray(data) || !Array.isArray(previousResult)) {
        return [];
      }

      return data.map((current) => {
        const previous = previousResult.find(
          (prev) => prev.archetype_id === current.id,
        );

        if (
          !previous ||
          isChallengeAndTaskOfTheBrand.isRegenerated ||
          diffArch?.is_different_archetype.challenges
        ) {
          // Jika tidak ada data sebelumnya, anggap tidak ada perubahan
          return { archetype_id: current.id, isDifferent: false };
        }

        // Ambil semua key_insights yang dipilih di data saat ini
        const currentSelectedInsights = current.key_insights
          .filter((insight) => insight.is_selected)
          .map((insight) => insight.id);

        // Ambil semua key_insights yang dipilih di data sebelumnya
        const previousSelectedInsights =
          previous.selected_key_insights?.map(
            (insight) => insight.key_insight_id,
          ) || [];

        // Periksa apakah ada perbedaan dalam jumlah atau isi ID yang dipilih
        const isDifferent =
          currentSelectedInsights.length !== previousSelectedInsights.length ||
          !currentSelectedInsights.every((id) =>
            previousSelectedInsights.includes(id),
          );

        return {
          archetype_id: current.id,
          isDifferent,
        };
      });
    },
    [
      isChallengeAndTaskOfTheBrand.isRegenerated,
      diffArch?.is_different_archetype.challenges,
    ],
  );

  useEffect(() => {
    if (data?.data && Array.isArray(data.data)) {
      const mappedArchetypes = mapArchetypes(data.data, archetypes);

      const newGeneratingStates = initializeGeneratingStates(data.data);
      setGeneratingStates(newGeneratingStates);

      const savedProgress = getSectionProgressFromLocalStorage(
        projectSlug,
        sectionKey,
      );

      const updatedProgressStates = initializeProgressStates(
        data.data,
        savedProgress,
        progressStates,
      );

      setProgressStates((prev) => ({
        ...prev,
        ...updatedProgressStates,
      }));

      setSources(data.sources);
      setTotalRegenerate(data.total_regenerate);

      const result = calculateTotals(
        mappedArchetypes,
        'total_key_insights',
        'total_selected_key_insights',
      );

      const firstNonRegenerateTabIndex = data.data.findIndex(
        (item) => item.is_regenerate === false,
      );

      if (
        firstNonRegenerateTabIndex !== -1 &&
        data.data.some((item) => item.is_regenerate)
      ) {
        setActiveTab(firstNonRegenerateTabIndex);
      }

      // Tambahkan deklarasi changes sebelum digunakan
      const changes = checkChangesInKeyInsights({
        data: data.data,
        previousResult: data.previous_selected_key_insight,
      });

      const updatedArchetypes = { ...mappedArchetypes }; // Salin mappedArchetypes

      Object.keys(updatedArchetypes).forEach((archetypeId) => {
        const change = changes.find(
          (c) => c.archetype_id === Number(archetypeId),
        );

        updatedArchetypes[Number(archetypeId)].is_diff_key_insight =
          change?.isDifferent || false;
      });

      setMessage(data.message);
      setArchetypes(updatedArchetypes);
      setTotals(result);
      setIsError(data.is_error);
    }
  }, [
    data,
    isChallengeAndTaskOfTheBrand,
    diffArch?.is_different_archetype.challenges,
  ]);
};
