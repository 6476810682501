import React from 'react';
import toast from 'react-hot-toast';

import ModalCenter from '@/Components/Modals/Center';
import { useAccessRequest } from '@/Hooks/Administrator';
import { useUserContext } from '@/Hooks/Administrator/useUserContext';
import type { AdministratorUserResponse } from '@/Types/Administrator';
import { cn } from '@/Utils/cn';

import SuccessUpdateAccessToast from './SuccessUpdateAccessToast';

interface GrantConfirmationModalProps {
  selectedUser: AdministratorUserResponse | undefined;
  isOpen: boolean;
  onClose: () => void;
}

const GrantConfirmationModal: React.FC<GrantConfirmationModalProps> = ({
  selectedUser,
  isOpen,
  onClose,
}) => {
  const {
    handleRefetch: refetch,
    updateStatus,
    handleUpdateStatus,
  } = useUserContext();
  const { mutate, isPending } = useAccessRequest();
  const handleClose = () => {
    if (updateStatus.id.length === 1) {
      handleUpdateStatus(updateStatus.id, 'detail');
      return;
    }
    onClose();
  };

  const onSubmit = () => {
    if (updateStatus.id.length > 0) {
      mutate(
        {
          ids: updateStatus.id,
          status: 'grant',
        },
        {
          onSuccess: () => {
            if (updateStatus.id.length > 1) {
              toast.custom((t) => (
                <SuccessUpdateAccessToast
                  message={`Access for <b>${updateStatus.id.length} users</b> successfully activated.`}
                  t={t}
                />
              ));
            } else {
              toast.custom((t) => (
                <SuccessUpdateAccessToast
                  message={`Access for <b>${selectedUser?.email}</b> successfully activated.`}
                  t={t}
                />
              ));
            }
            handleUpdateStatus([], '');
            refetch(true);
          },
        },
      );
    }
  };

  return (
    <ModalCenter isOpen={isOpen} onClose={handleClose}>
      <div className="max-w-lg p-35">
        <h1 className="text-24 font-semibold leading-32 text-black-redx md:text-18">
          {updateStatus.id.length > 1
            ? `Reactivate access for ${updateStatus?.id?.length ?? 0} users?`
            : `Reactivate access for ${selectedUser?.name}?`}
        </h1>
        <p className="mt-16 text-14 font-normal leading-20 text-grey-redx">
          Reactivating this user will restore their account access and
          permissions. Make sure this action is intended.
        </p>

        <div className="mt-24 flex items-center justify-end gap-16 gap-x-10 md:flex-col">
          <button
            className="rounded-8 border-1 border-blue-redx px-16 py-8 text-blue-redx md:w-full"
            onClick={handleClose}
          >
            Close
          </button>
          <button
            className={cn(
              'rounded-8  border-1 bg-success-redx px-16 py-8 text-white md:w-full',
              isPending ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer',
            )}
            disabled={isPending}
            onClick={onSubmit}
          >
            Activate Access
          </button>
        </div>
      </div>
    </ModalCenter>
  );
};

export default GrantConfirmationModal;
