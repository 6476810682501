import type { ProjectProps } from '@/Types/Projects';
import type { UserResponse } from '@/Types/user';

import ProjectCard from './ProjectCard';

interface ProjectListsProps {
  projects: ProjectProps[];
  initialUsers: UserResponse[];
  isLoading: boolean;
  onRefetchLists: () => void;
}

const ProjectLists = ({
  projects,
  initialUsers,
  isLoading,
  onRefetchLists,
}: ProjectListsProps) => {
  if (isLoading) return null;

  if (projects.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center">
        <img
          alt="Empty-amico 1.png"
          className="size-400"
          src="assets/img/Empty-amico 1.png"
        />
        <div className="flex flex-col items-center justify-items-center gap-20">
          <h2 className="text-32 font-semibold leading-[36px] text-black-redx">
            Project not found...
          </h2>
          <p className="text-center text-16 font-normal leading-20 text-grey-redx">
            It looks like you haven&apos;t created a project yet.
            <br />
            Click ‘Create Project’ to start a new one or contact your teammates
            to invite you.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center gap-30">
      {projects.map((project, index) => (
        <ProjectCard
          key={project.id || index} // Use project ID if available, fallback to index
          initialUsers={initialUsers}
          onRefetchLists={onRefetchLists}
          project={project}
        />
      ))}
    </div>
  );
};
export default ProjectLists;
