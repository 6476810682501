import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  saveRouteNext,
  saveSubInformation,
} from '@/Context/Actions/project-actions';
import { ProjectContext } from '@/Context/ProjectContext';
import type { RouteOption } from '@/Context/Reducer/project-reducer';
import type { ProjectProps } from '@/Types/Projects';

import { useGenerate } from '../useGenerate';
import { useHistoryStatus } from '../useHistoryStatus';

interface UseCtaParams {
  message: string;
  isError: boolean;
  isEdited: boolean;
  isEditor: boolean;
  isEditing: boolean;
  project: ProjectProps;
  routeOptionValue: RouteOption;
  activeSection: string;
  setShowViewInfoModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowRegenerateModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const useCtaAction = ({
  message,
  isError,
  isEdited,
  isEditor,
  isEditing,
  project,
  routeOptionValue,
  activeSection,
  setShowViewInfoModal,
  setShowRegenerateModal,
}: UseCtaParams) => {
  const [, dispatch] = useContext(ProjectContext);
  const navigate = useNavigate();
  const projectSlug = project.slug;
  const { mutateAsync: generate } = useGenerate();

  const {
    isTargetAudienceAudienceInsight,
    isTargetAudienceBehavioralTrend,
    isTargetAudienceMediaConsumptionPattern,
    isTargetAudiencePerceptionAnalysis,
    isAudienceArchetypeComplete,
    isProductResearchMainFeature,
    isProductResearchMarcommHighlight,
    isProductResearchProductDescription,
    isMarketResearchDemand,
    isMarketResearchTrend,
  } = useHistoryStatus(project);

  const memoizedRouteOptionValue = useMemo(
    () => ({ ...routeOptionValue }),
    [routeOptionValue],
  );

  const handleEditorClick = useCallback(async () => {
    if (isAudienceArchetypeComplete.isQuerying) {
      dispatch(
        saveSubInformation(
          '{text-error-redx} Please wait audience archetype is generating.',
        ),
      );

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    if (
      isProductResearchMainFeature.isQuerying ||
      isProductResearchMarcommHighlight.isQuerying ||
      isProductResearchProductDescription.isQuerying
    ) {
      dispatch(
        saveSubInformation(
          '{text-error-redx} Please wait product research is generating.',
        ),
      );

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    if (isMarketResearchDemand.isQuerying || isMarketResearchTrend.isQuerying) {
      dispatch(
        saveSubInformation(
          '{text-error-redx} Please wait market research is generating.',
        ),
      );

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    if (
      isTargetAudienceAudienceInsight.isQuerying ||
      isTargetAudienceBehavioralTrend.isQuerying ||
      isTargetAudienceMediaConsumptionPattern.isQuerying ||
      isTargetAudiencePerceptionAnalysis.isQuerying
    ) {
      dispatch(
        saveSubInformation(
          '{text-error-redx} Please wait target audience is generating.',
        ),
      );

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    if (isEditing) {
      dispatch(saveSubInformation('{text-error-redx} Please do save first.'));

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    if (!isAudienceArchetypeComplete.isError) {
      if (
        !isAudienceArchetypeComplete.status ||
        isAudienceArchetypeComplete.isRegenerated
      ) {
        dispatch(
          saveRouteNext({ ...memoizedRouteOptionValue, isDisabled: true }),
        );
        await generate({
          projectSlug,
          section: 'audience-archetype',
          payload: { generate_more: '', regenerate_prompt: '' },
        });

        dispatch(
          saveRouteNext({ ...memoizedRouteOptionValue, isDisabled: false }),
        );
      }

      if (isEdited && !isAudienceArchetypeComplete.isRegenerated) {
        setShowRegenerateModal(true);
        return;
      }
    }

    navigate(`/${projectSlug}/audience-archetype`);
  }, [
    isEditing,
    isEdited,
    isAudienceArchetypeComplete,
    isTargetAudienceAudienceInsight,
    isTargetAudienceBehavioralTrend,
    isTargetAudienceMediaConsumptionPattern,
    isTargetAudiencePerceptionAnalysis,
    isProductResearchMainFeature,
    isProductResearchMarcommHighlight,
    isProductResearchProductDescription,
    isMarketResearchDemand,
    isMarketResearchTrend,
  ]);

  const handleNonEditorClick = useCallback(() => {
    if (
      !isAudienceArchetypeComplete.status ||
      isAudienceArchetypeComplete.isRegenerated
    ) {
      setShowViewInfoModal(true);
      return;
    }

    if (
      isTargetAudienceAudienceInsight.isQuerying ||
      isTargetAudienceBehavioralTrend.isQuerying ||
      isTargetAudienceMediaConsumptionPattern.isQuerying ||
      isTargetAudiencePerceptionAnalysis.isQuerying
    ) {
      dispatch(
        saveSubInformation(
          '{text-error-redx} Please wait target audience is generating.',
        ),
      );

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    navigate(`/${projectSlug}/audience-archetype`);
  }, [
    isEditing,
    isAudienceArchetypeComplete.isRegenerated,
    isAudienceArchetypeComplete.status,
    isTargetAudienceAudienceInsight.isQuerying,
    isTargetAudienceBehavioralTrend.isQuerying,
    isTargetAudienceMediaConsumptionPattern.isQuerying,
    isTargetAudiencePerceptionAnalysis.isQuerying,
  ]);

  useEffect(() => {
    memoizedRouteOptionValue.isActive = message !== 'No data found';
    memoizedRouteOptionValue.isInactive =
      isAudienceArchetypeComplete.isQuerying ||
      isTargetAudienceAudienceInsight.isQuerying ||
      isTargetAudienceBehavioralTrend.isQuerying ||
      isTargetAudienceMediaConsumptionPattern.isQuerying ||
      isTargetAudiencePerceptionAnalysis.isQuerying ||
      isProductResearchMainFeature.isQuerying ||
      isProductResearchMarcommHighlight.isQuerying ||
      isProductResearchProductDescription.isQuerying ||
      isMarketResearchDemand.isQuerying ||
      isMarketResearchTrend.isQuerying ||
      isEditing;

    if (isEditor) {
      if (isEdited) {
        memoizedRouteOptionValue.label = 'Update Other Pages';
        memoizedRouteOptionValue.isGenerate = true;
      } else if (
        !isAudienceArchetypeComplete.status &&
        !isAudienceArchetypeComplete.isError
      ) {
        memoizedRouteOptionValue.isGenerate = true;
        memoizedRouteOptionValue.label = 'Generate Audience Archetype';
      } else {
        memoizedRouteOptionValue.isGenerate = false;
        memoizedRouteOptionValue.label = 'Next to Audience Archetype';
      }

      memoizedRouteOptionValue.onClick = handleEditorClick;
    } else {
      memoizedRouteOptionValue.label = 'Next to Audience Archetype';
      memoizedRouteOptionValue.isGenerate = false;
      memoizedRouteOptionValue.onClick = handleNonEditorClick;
    }

    dispatch(saveRouteNext(memoizedRouteOptionValue));
  }, [
    activeSection,
    isEditor,
    isError,
    isEdited,
    isEditing,
    isAudienceArchetypeComplete,
    isTargetAudienceAudienceInsight,
    isTargetAudienceBehavioralTrend,
    isTargetAudienceMediaConsumptionPattern,
    isTargetAudiencePerceptionAnalysis,
    isProductResearchMainFeature,
    isProductResearchMarcommHighlight,
    isProductResearchProductDescription,
    isMarketResearchDemand,
    isMarketResearchTrend,
    message,
  ]);
};

export { useCtaAction };
