import { RoleListProvider } from '../../Context/role-list-provider';
import FilterAndSort from './FilterAndSort';
import TableList from './Table';

const TabUserList = () => {
  return (
    <RoleListProvider>
      <div className="relative flex flex-col gap-24">
        <FilterAndSort />
        <TableList />
      </div>
    </RoleListProvider>
  );
};
export default TabUserList;
