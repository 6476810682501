import React from 'react';
import toast from 'react-hot-toast';

import ModalCenter from '@/Components/Modals/Center';
import {
  useRequestUserContext,
  useUpdateRequestList,
} from '@/Hooks/Administrator';
import type { RequestUserResponse } from '@/Types/Administrator';
import { cn } from '@/Utils/cn';

import SuccessUpdateAccessToast from './SuccessUpdateAccessToast';

interface RegenerateInfoModalProps {
  selectedUser: RequestUserResponse | undefined;
  isOpen: boolean;
  onClose: () => void;
}

const RejectConfirmationModal: React.FC<RegenerateInfoModalProps> = ({
  selectedUser,
  isOpen,
  onClose,
}) => {
  const { handleRefetch: refetch, updateStatus } = useRequestUserContext();
  const { mutate, isPending } = useUpdateRequestList();
  const handleClose = () => {
    onClose();
  };

  const handleSubmit = () => {
    if (updateStatus.id.length > 0) {
      mutate(
        {
          ids: updateStatus.id,
          status: 'rejected',
          reason: '',
        },
        {
          onSuccess: () => {
            if (updateStatus.id.length > 1) {
              toast.custom((t) => (
                <SuccessUpdateAccessToast
                  message={`Request for <b>${updateStatus.id.length} users</b> successfully rejected.`}
                  t={t}
                />
              ));
            } else {
              toast.custom((t) => (
                <SuccessUpdateAccessToast
                  message={`Request for <b>${selectedUser?.email}</b> successfully rejected.`}
                  t={t}
                />
              ));
            }
            refetch(true);
            handleClose();
          },
        },
      );
    }
  };

  return (
    <ModalCenter isOpen={isOpen} onClose={handleClose}>
      <div className="max-w-lg p-35">
        <h1 className="text-24 font-semibold leading-32 text-black-redx md:text-18">
          Are you sure reject this request?
        </h1>
        {updateStatus.id.length > 1 ? (
          <p className="mt-16 text-14 font-normal leading-20 text-grey-redx">
            The request from <b>{updateStatus.id.length} users</b> will be
            rejected and this action cannot be undone.
          </p>
        ) : (
          <p className="leading-22 mt-16 text-16 text-grey-redx md:text-14">
            The request from <b>{selectedUser?.email}</b> will be rejected and
            this action cannot be undone.
          </p>
        )}

        <div className="mt-24 flex items-center justify-end gap-16 gap-x-10 md:flex-col">
          <button
            className="rounded-8 border-1 border-blue-redx px-16 py-8 text-blue-redx md:w-full"
            onClick={handleClose}
          >
            Close
          </button>
          <button
            className={cn(
              'rounded-8  border-1 bg-error-redx px-16 py-8 text-white md:w-full',
              isPending ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer',
            )}
            disabled={isPending}
            onClick={handleSubmit}
          >
            Reject Request
          </button>
        </div>
      </div>
    </ModalCenter>
  );
};

export default RejectConfirmationModal;
