import type { ReactNode } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';

import {
  useAdminUserList,
  useUpdateAdminAccessRequest,
} from '@/Hooks/Administrator';
import type {
  AdministratorUserResponse,
  RequestUserResponse,
} from '@/Types/Administrator';

import SuccessUpdateAccessToast from '../Components/RequestList/SuccessUpdateAccessToast';
import { AdminUserListContext } from './admin-user-list-context';

export const SORT_BY = [
  { name: 'Name (A - Z)', value: 'name-asc' },
  { name: 'Name (Z - A)', value: 'name-desc' },
  { name: 'Date (Newest - Oldest)', value: 'created-desc' },
  { name: 'Date (Oldest - Newest)', value: 'created-asc' },
];

export const AdminUserListProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { mutate } = useUpdateAdminAccessRequest();
  const [records, setRecords] = useState<AdministratorUserResponse[]>([]);
  const [curPage, setCurPage] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [keyLine, setKeyFilterLine] = useState(+new Date());
  const observerRef = useRef<HTMLDivElement | null>(null);

  const [isObserverSet, setIsObserverSet] = useState(false);

  const setTargetRef = useCallback((ref: HTMLDivElement | null) => {
    if (ref) {
      observerRef.current = ref;
      setIsObserverSet(true); // Tandai bahwa observer telah di-set
    }
  }, []);

  const [selectedSortBy, setSelectedSortBy] = useState(SORT_BY[2].value);
  const [sortedProjects, setSortedProjects] = useState<
    AdministratorUserResponse[]
  >([]);
  const [updateStatus, setUpdateStatus] = useState<{
    id: AdministratorUserResponse['id'];
    status: string;
  }>({
    id: 0,
    status: '',
  });

  const {
    data,
    isPending,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useAdminUserList({
    sort: selectedSortBy,
    search,
    date: keyLine,
    pageParam: curPage,
  });

  const removeDuplicates = (data: AdministratorUserResponse[]) => {
    const uniqueMap = new Map();
    data.forEach((item) => {
      uniqueMap.set(item.id, item); // Jika id sudah ada, update dengan item terbaru
    });
    return Array.from(uniqueMap.values());
  };

  useEffect(() => {
    if (data?.pages) {
      const newLists = data.pages.flatMap((page) => page.data);

      setRecords((prev) => {
        let updatedRecords;
        if (search) {
          updatedRecords = newLists;
        } else {
          updatedRecords = removeDuplicates([...prev, ...newLists]);
        }

        return updatedRecords;
      });
    }
  }, [data?.pages, search]);

  useEffect(() => {
    const sorted = [...records];

    switch (selectedSortBy) {
      case 'name-asc':
        sorted.sort((a, b) => (a.name || '').localeCompare(b.name || ''));
        break;
      case 'name-desc':
        sorted.sort((a, b) => (b.name || '').localeCompare(a.name || ''));
        break;
      case 'created-asc':
        sorted.sort((a, b) => {
          const aDate = a.request_activation_admin_date ?? a.created_at;
          const bDate = b.request_activation_admin_date ?? b.created_at;
          const aTime = aDate ? new Date(aDate).getTime() : 0;
          const bTime = bDate ? new Date(bDate).getTime() : 0;
          return aTime - bTime;
        });
        break;
      case 'created-desc':
        sorted.sort((a, b) => {
          const aDate = a.request_activation_admin_date ?? a.created_at;
          const bDate = b.request_activation_admin_date ?? b.created_at;
          const aTime = aDate ? new Date(aDate).getTime() : 0;
          const bTime = bDate ? new Date(bDate).getTime() : 0;
          return bTime - aTime;
        });
        break;
      default:
        break;
    }

    setSortedProjects(sorted); // Update state untuk ditampilkan
  }, [selectedSortBy, records]);

  useEffect(() => {
    setRecords([]);
    setSortedProjects([]);
    setKeyFilterLine(+new Date());
  }, []);

  const updatePage = useCallback(
    (newPage: number) => {
      setCurPage(newPage);
    },
    [setCurPage],
  );

  const handleShortBy = useCallback(
    (sortBy: string) => {
      setSelectedSortBy(sortBy);
    },
    [setSelectedSortBy],
  );

  const updateSearch = useCallback(
    (search: string) => {
      setSearch(search);
    },
    [setSearch],
  );

  const handleRefetch = useCallback((isReset: boolean) => {
    if (isReset) {
      setUpdateStatus({ id: 0, status: '' });
      setRecords([]);
    }

    setKeyFilterLine(+new Date());
  }, []);

  const handleUpdateStatus = useCallback(
    (id: RequestUserResponse['id'][], status: string) => {
      if (status === 'approved') {
        mutate(
          {
            ids: id,
            status,
          },
          {
            onSuccess: () => {
              if (id.length > 1) {
                toast.custom((t) => (
                  <SuccessUpdateAccessToast
                    message={`Request for <b>${id.length} users</b> successfully accepted.`}
                    t={t}
                  />
                ));
              } else {
                const selectedUser = records.find(
                  (record) => record.id === id[0],
                );
                toast.custom((t) => (
                  <SuccessUpdateAccessToast
                    message={`Request for <b>${selectedUser?.email} users</b> successfully accepted.`}
                    t={t}
                  />
                ));
              }

              handleRefetch(true);
              setUpdateStatus({ id: 0, status });
            },
          },
        );
      } else {
        setUpdateStatus({ id: id[0], status });
      }
    },
    [records],
  );

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const target = entries[0];
        if (target.isIntersecting && hasNextPage && !isFetchingNextPage) {
          const lastPage = data?.pages[data.pages.length - 1];
          if (lastPage?.next === 999999999) {
            return;
          }
          fetchNextPage();
        }
      },
      {
        root: null, // Gunakan viewport sebagai root
        rootMargin: '0px',
        threshold: 0.1, // Callback dipicu saat 10% elemen loader terlihat
      },
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data?.pages,
    isObserverSet,
  ]);

  const value = useMemo(
    () => ({
      updateStatus,
      hasNextPage,
      isFetchingNextPage,
      observerRef,
      records: sortedProjects,
      page: curPage,
      selectedSortBy,
      isLoading: isPending || isLoading || isFetchingNextPage,
      setPage: updatePage,
      setSearch: updateSearch,
      setShortBy: handleShortBy,
      handleRefetch,
      handleUpdateStatus,
      setTargetRef,
    }),
    [
      updateStatus,
      hasNextPage,
      records,
      curPage,
      isPending,
      isLoading,
      isFetchingNextPage,
      observerRef,
      selectedSortBy,
      sortedProjects,
      updatePage,
      handleUpdateStatus,
      handleRefetch,
      updateSearch,
      handleShortBy,
      setTargetRef,
    ],
  );

  return (
    <AdminUserListContext.Provider value={value}>
      {children}
    </AdminUserListContext.Provider>
  );
};
