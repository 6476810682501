import type { RefObject } from 'react';
import { createContext } from 'react';

import type { AdministratorUserResponse } from '@/Types/Administrator';

export interface UserListProps {
  isLoading: boolean;
  isFetchingNextPage: boolean;
  records: AdministratorUserResponse[];
  page: number;
  activeFilter: string;
  selectedSortBy: string;
  hasNextPage: boolean;
  updateStatus: { id: AdministratorUserResponse['id'][]; status: string };
  observerRef: RefObject<HTMLDivElement>;
  checkPerRow: AdministratorUserResponse['id'][];
  setPage: (page: number) => void;
  setTargetRef: (ref: HTMLDivElement | null) => void;
  setShortBy: (sortBy: string) => void;
  setCheckPerRow: (
    id: AdministratorUserResponse['id'],
    status: boolean,
  ) => void;
  handleActiveFilter: (activeFilter: string) => void;
  handleUpdateStatus: (
    id: AdministratorUserResponse['id'][],
    status: string,
  ) => void;
  setSearch: (search: string) => void;
  handleRefetch: (isReset: boolean) => void;
}

export const UserListContext = createContext<UserListProps | undefined>(
  undefined,
);
