/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
import { zodResolver } from '@hookform/resolvers/zod';
import { isEqual } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import EditedLabel from '@/Components/EditedLabel';
import TextAreaInput from '@/Components/TextAreaInput';
import { useDeleteCompetitor } from '@/Hooks/Competitor';
import type { CompetitorAnalysisContentData } from '@/Types/CompetitorAnalysis';
import type { CompetitorAnalysisSchema } from '@/Types/CompetitorAnalysis/schema';
import { CompetitorAnalysis } from '@/Types/CompetitorAnalysis/schema';
import type { UserResponse } from '@/Types/user';
import { triggerGTMEvent } from '@/Utils/gtm';

import Actions from './Actions';
import Container from './Container';
import Content from './Content';
import Label from './Label';

const fieldsToDisplay = [
  { name: 'brand_name', label: 'Brand Name', colSpan: false },
  { name: 'product_name', label: 'Product Name', colSpan: false },
  { name: 'communication_angle', label: 'Communication Angle', colSpan: false },
  { name: 'communication_idea', label: 'Communication Idea', colSpan: false },
  { name: 'communication_tone', label: 'Communication Tone', colSpan: false },
  { name: 'features_benefits', label: 'Features & Benefits', colSpan: true },
  { name: 'key_touch_points', label: 'Key Touch Points', colSpan: true },
  { name: 'opportunities', label: 'Opportunities', colSpan: true },
];

const Card: React.FC<{
  projectSlug?: string;
  user?: UserResponse;
  item?: CompetitorAnalysisContentData;
  index: number;
  isCanEdit?: boolean;
  isSubmitting?: boolean;
  isFetching?: boolean;
  refetch?: () => void;
  onUpdateChallenge?: (
    data: CompetitorAnalysisContentData,
    index: number,
  ) => void;
  isEditingCount?: number;
  setIsEditingCount?: React.Dispatch<React.SetStateAction<number>>;
}> = ({
  projectSlug,
  user,
  item,
  refetch,
  index,
  isSubmitting = false,
  isCanEdit = true,
  isFetching = false,
  onUpdateChallenge,
  isEditingCount = 0,
  setIsEditingCount,
}) => {
  const { mutate } = useDeleteCompetitor();
  const [isEditing, setIsEditing] = useState(false);
  const brandNameRef = useRef<HTMLTextAreaElement | null>(null);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CompetitorAnalysisSchema>({
    resolver: zodResolver(CompetitorAnalysis),
    defaultValues: {
      brand_name: item?.brand_name ?? '',
      communication_angle: item?.communication_angle ?? '',
      communication_idea: item?.communication_idea ?? '',
      communication_tone: item?.communication_tone ?? '',
      competitor_id: item?.competitor_id ?? -1,
      features_benefits: item?.features_benefits ?? '',
      key_touch_points: item?.key_touch_points ?? '',
      opportunities: item?.opportunities ?? '',
      product_name: item?.product_name ?? '',
    },
    mode: 'all',
  });

  const toggleIsEditing = () => {
    setIsEditing(!isEditing);
    if (setIsEditingCount) {
      const newCount = isEditing ? isEditingCount - 1 : isEditingCount + 1;
      setIsEditingCount(newCount);
    }
  };

  // Focus on brand_name field when editing is enabled
  useEffect(() => {
    if (isEditing && brandNameRef.current) {
      brandNameRef.current.focus();
    }
  }, [isEditing]);

  useEffect(() => {
    reset(item);
  }, [item, isEditing]);

  const handleCancelEdit = () => {
    reset();
    toggleIsEditing();
  };

  const onSubmit = (data: CompetitorAnalysisContentData) => {
    if (!user) return;

    toggleIsEditing();
    triggerGTMEvent({
      event: `Edit Competitor Analysis`,
      eventCategory: `Edit Competitor Analysis Click`,
      eventAction: 'Click',
      eventLabel: 'Competitor Analysis',
      userId: user.email,
      data: { data, index },
    });

    const ensureArray = (value: string | string[]): string[] => {
      if (Array.isArray(value)) {
        return value;
      }
      // Split string by comma and trim whitespace
      return value.split(',').map((item) => item.trim());
    };

    // Transform data
    const { is_edited, ...restData } = data;
    const transformedData: CompetitorAnalysisContentData = {
      ...restData,
      features_benefits: ensureArray(restData.features_benefits),
      key_touch_points: ensureArray(restData.key_touch_points),
      opportunities: ensureArray(restData.opportunities),
    };
    onUpdateChallenge?.(transformedData, index);
  };

  const handleDelete = () => {
    if (!item || !projectSlug) return;
    mutate(
      {
        brandName: item.brand_name,
        id: item.competitor_id ?? -1,
        projectSlug,
      },
      {
        onSuccess: () => refetch?.(),
      },
    );
  };

  return (
    <div className="flex w-full flex-col pb-35">
      <div className="mb-15 border-b-1 border-stroke-redx py-15 text-left">
        {!isFetching ? (
          <div className="inline-flex gap-7">
            <h1 className="text-16 font-bold leading-20 text-black-redx">
              Competitor {index + 1}
            </h1>
            {item?.is_edited && <EditedLabel />}
          </div>
        ) : (
          <h1 className="h-10 w-2/12 animate-pulse rounded-full bg-soft-purple-redx" />
        )}
      </div>
      <form className="pl-24 pr-12" onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-2 gap-24 pb-10 md:grid-cols-1">
          {fieldsToDisplay.map(({ name, label, colSpan }) => (
            <Container
              key={name}
              className={colSpan ? 'col-span-2 md:col-span-1' : ''}
            >
              <Label value={label} />
              {isEditing ? (
                <Controller
                  control={control}
                  name={name as keyof CompetitorAnalysisSchema}
                  render={({ field }) => (
                    <TextAreaInput
                      {...field}
                      ref={name === 'brand_name' ? brandNameRef : undefined}
                      autoComplete="off"
                      className="overflow-custom mt-1 block w-full resize-none !text-14"
                      containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                      error={
                        errors[name as keyof CompetitorAnalysisSchema]?.message
                      }
                      placeholder={`Input the ${label.toLowerCase()} here`}
                      rows={name === 'features_benefits' ? 3 : 2} // Custom rows for specific fields
                    />
                  )}
                />
              ) : (
                <Content
                  isFetching={isFetching}
                  value={
                    item?.[name as keyof CompetitorAnalysisContentData] || ''
                  }
                />
              )}
            </Container>
          ))}
        </div>
        <Actions
          isCanEdit={isCanEdit}
          isEditing={isEditing}
          isSubmitting={isSubmitting}
          onHandleCancelEdit={handleCancelEdit}
          onHandleDelete={handleDelete}
          toggleIsEditing={toggleIsEditing}
        />
      </form>
    </div>
  );
};

export default React.memo(Card, (prevProps, nextProps) => {
  return (
    isEqual(prevProps.item, nextProps.item) &&
    prevProps.item === nextProps.item &&
    prevProps.index === nextProps.index &&
    prevProps.isFetching === nextProps.isFetching &&
    prevProps.isCanEdit === nextProps.isCanEdit
  );
});
