import validator from 'validator';
import { z } from 'zod';

const websiteValidation = z
  .string()
  .optional()
  .refine(
    (value) =>
      !value || // Jika kosong, valid
      (/^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,63}(\/.*)?$/.test(value) &&
        validator.isURL(value, {
          require_protocol: false,
          allow_underscores: false,
          allow_trailing_dot: false,
        })),
    {
      message: 'Please enter a valid URL (e.g., example.com)',
    },
  );

export const ProductInformation = z.object({
  product_name: z.string().min(2, 'Product name is required'),
  brand_name: z.string().min(2, 'Brand name is required'),
  product_links: websiteValidation,
});

export type ProductInformationSchema = z.infer<typeof ProductInformation>;

export const MarketInformation = z.object({
  product_category: z
    .number({ invalid_type_error: 'Product Category must be a number' })
    .min(1, { message: 'Product Category is required' }),
  product_subcategory: z
    .number({ invalid_type_error: 'Product Subcategory must be a number' })
    .min(1, { message: 'Product Subcategory is required' }),
});

export type MarketInformationSchema = z.infer<typeof MarketInformation>;

export const TargetAudience = z
  .object({
    age_market_start: z
      .number({ message: 'Min. age is required' })
      .int({ message: 'Min. age must be an integer' })
      .refine((val) => val >= 0, { message: 'Min. age must be at least 0' })
      .refine((val) => val <= 100, {
        message: 'Min. age must be at most 100',
      }),
    age_market_end: z
      .number({ message: 'Max. age is required' })
      .int({ message: 'Max. age must be an integer' })
      .refine((val) => val >= 0, { message: 'Max. age must be at least 0' })
      .refine((val) => val <= 100, {
        message: 'Max. age must be at most 100',
      }),
    gender: z
      .string()
      .refine(
        (val) =>
          val.split(',').every((g) => ['Male', 'Female'].includes(g.trim())),
        {
          message: 'Please select gender from the list',
        },
      ),
  })
  .superRefine((data, ctx) => {
    if (data.age_market_end <= data.age_market_start) {
      ctx.addIssue({
        path: ['age_market_end'],
        message: 'Age end must be greater than age start',
        code: 'custom',
      });
    }
  });

export type TargetAudienceSchema = z.infer<typeof TargetAudience>;

export const Competitor = z.object({
  competitors: z.array(
    z.object({
      id: z.number().optional(),
      name: z.string().optional(),
      website: websiteValidation,
    }),
  ),
});

// Contoh tipe data TypeScript
export type CompetitorSchema = z.infer<typeof Competitor>;

export const ObjectiveSubmission = z.object({
  objective: z.string().min(2, 'Objective is required'),
  second_objective: z.string().min(2, 'Second Objective is required'),
  kpi: z.string().optional(),
});

export type ObjectiveSubmissionSchema = z.infer<typeof ObjectiveSubmission>;
