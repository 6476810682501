import type { LabelHTMLAttributes } from 'react';

import { cn } from '@/Utils/cn';

const InputLabel = ({
  value,
  disabled = false,
  optional = false,
  className = '',
  ...props
}: LabelHTMLAttributes<HTMLLabelElement> & {
  value?: string;
  disabled?: boolean;
  optional?: boolean;
}) => {
  return (
    <label
      {...props}
      className={cn(
        `block text-12 font-semibold leading-14 text-black-redx`,
        className,
        disabled && '!text-placeholder-redx',
      )}
      htmlFor={value}
    >
      {value} {optional && <span>(Optional)</span>}
    </label>
  );
};

export default InputLabel;
